import { Spinner } from "@chakra-ui/react";
import { auth } from "firebaseConfig/config";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { ReactComponent as Logo } from "assets/svg/tradetracker-logo.svg";
import { User } from "firebase/auth";

export const AuthRedirect = () => {
  const location: any = useLocation();
  const parsed = queryString.parse(location.search);
  const parsedEmail = queryString.parse(
    parsed?.continueUrl?.split("?")[1]
  )?.email;
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    // Cleanup function to unsubscribe from the listener
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // if (user) {
    // Check if user is available
    if (parsed.mode === "resetPassword") {
      navigate(
        `/reset-password?email=${parsedEmail}&oobCode=${parsed.oobCode}`,
        { state: parsed }
      );
    } else if (parsed.mode === "verifyEmail") {
      navigate(`/verify-email?email=${parsedEmail}&oobCode=${parsed.oobCode}`, {
        state: { from: location.pathname },
      });
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, parsed]);
  return (
    <div className="h-screen flex flex-col items-center pt-10">
      <Logo />
      <div className="flex justify-center items-center flex-col text-center h-full">
        <div>
          <Spinner />
        </div>
        {parsed.mode === "resetPassword" && (
          <div>
            <p>Redirecting...</p>
          </div>
        )}
        {parsed.mode === "verifyEmail" && (
          <h2>Redirecting to verify email...</h2>
        )}
      </div>
    </div>
  );
};
