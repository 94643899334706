import React, { useEffect } from "react";
import style from "../../../styles/dashboard.module.scss";
import { BiEdit } from "react-icons/bi";
import {
    useGetMyBusinessQuery,
    useUpdateBusinessMutation,
} from "store/business";
import { useAuth } from "store/auth";
import { AuthHeaderText, PrimaryButton, PrimaryInput } from "components/index";
import { useFormik } from "formik";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";

const InfoSection: React.FC = () => {
    const { user } = useAuth();
    const { data: businessInfo } = useGetMyBusinessQuery(user?.id || "");
    const [showEditModal, setShowEditModal] = React.useState(false);
    const {
        businessName = "",
        contactEmail = "",
        phone = "",
        website = "",
        instagramLink = "",
        xLink = "",
        tiktokLink = "",
    } = businessInfo?.data?.[0] || {};

    return (
        <>
            <div className={style.info_section}>
                <div className={style.info_section_header}>
                    <h2>Basic info</h2>
                    <button
                        onClick={() => setShowEditModal(true)}
                        className={style.info_edit_btn}
                    >
                        <BiEdit />
                        edit
                    </button>
                </div>
                <div className={style.info_list}>
                    <div className={style.info_list_item}>
                        <span>Business name</span>
                        <span>{businessName || "--"}</span>
                    </div>
                    <div className={style.info_list_item}>
                        <span>Email address</span>
                        <span>{contactEmail || "--"}</span>
                    </div>
                    <div className={style.info_list_item}>
                        <span>Phone numbers</span>
                        <span>{phone || "--"}</span>
                    </div>
                    <div className={style.info_list_item}>
                        <span>Website URL</span>
                        <span>{website || "--"}</span>
                    </div>
                    <div className={style.info_list_item}>
                        <span>Instagram profile</span>
                        <span>{instagramLink || "--"}</span>
                    </div>
                    <div className={style.info_list_item}>
                        <span>X profile (Twitter)</span>
                        <span>{xLink || "--"}</span>
                    </div>
                    <div className={style.info_list_item}>
                        <span>Tiktok profile</span>
                        <span>{tiktokLink || "--"}</span>
                    </div>
                </div>
            </div>
            {showEditModal ? (
                <EditModal
                    show={showEditModal}
                    setShow={setShowEditModal}
                    businessInfo={businessInfo?.data?.[0] || {}}
                    onClose={() => setShowEditModal(false)}
                />
            ) : null}
        </>
    );
};

export default InfoSection;

type TEditModal = {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    businessInfo: any;
    onClose: () => void;
};
function EditModal({ onClose, setShow, businessInfo }: TEditModal) {
    const initialValues = {
        businessName: "",
        contactEmail: "",
        phone: "",
        website: "",
        instagramLink: "",
        xLink: "",
        tiktokLink: "",
    };
    const { initNotification } = usePageNotificationProvider();
    const [updateBusiness] = useUpdateBusinessMutation();
    const {
        values,
        setValues,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useFormik({
        initialValues,
        validationSchema: null,
        onSubmit: (e) => initRequest(e),
    });

    const initRequest = (payload: any) => {
        Object.keys(payload).forEach((key) => {
            if (!Object.keys(initialValues).includes(key)) {
                delete payload[key];
            }
        });

        updateBusiness({ businessData: payload, id: businessInfo?.id })
            .unwrap()
            .then((res: any) => {
                initNotification({
                    scheme: "success",
                    message: res.message,
                });
                setShow(false);
            })
            .catch((err: any) =>
                initNotification({
                    scheme: "error",
                    message: err?.data?.message || err.error,
                })
            );
    };

    useEffect(() => {
        setValues({
            ...businessInfo,
        });
    }, [businessInfo, setValues]);

    return (
        <div
            onClick={onClose}
            className="fixed inset-0 bg-black/50 flex flex-col item-center justify-center overflow-hidden z-[4]"
        >
            <div className="h-full overflow-y-scroll py-20 pt-36">
                <div
                    onClick={(e) => e.stopPropagation()}
                    className="bg-white min-h-full md:min-h-96 w-full max-w-2xl md:px-8 py-10 px-4 rounded-lg m-auto"
                >
                    <AuthHeaderText
                        title="Edit your business basic information"
                        headingClassName="text-black text-3xl font-medium mb-5"
                    />
                    <br />
                    <form onSubmit={handleSubmit} className="">
                        <div className="mt-3 d-flex flex-column w-100 gap-4">
                            <div className="my-5">
                                <PrimaryInput
                                    type="text"
                                    label="Business name"
                                    name="businessName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.businessName}
                                    error={Boolean(
                                        errors?.businessName &&
                                            touched?.businessName
                                    )}
                                    bottomText={errors?.businessName || ""}
                                />
                            </div>

                            <div className="my-5">
                                <PrimaryInput
                                    type="text"
                                    label="Contact Email"
                                    name="contactEmail"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.contactEmail}
                                    error={Boolean(
                                        errors?.contactEmail &&
                                            touched?.contactEmail
                                    )}
                                    bottomText={errors?.contactEmail || ""}
                                />
                            </div>

                            <div className="my-5">
                                <PrimaryInput
                                    type="text"
                                    label="Phone number"
                                    name="phone"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.phone}
                                    error={Boolean(
                                        errors?.phone && touched?.phone
                                    )}
                                    bottomText={errors?.phone || ""}
                                />
                            </div>

                            <div className="my-5">
                                <PrimaryInput
                                    type="text"
                                    label="Website URL"
                                    name="website"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.website}
                                    error={Boolean(
                                        errors?.website && touched?.website
                                    )}
                                    bottomText={errors?.website || ""}
                                />
                            </div>

                            <div className="my-5">
                                <PrimaryInput
                                    type="url"
                                    label="Instagram profile"
                                    name="instagramLink"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.instagramLink}
                                    error={Boolean(
                                        errors?.instagramLink &&
                                            touched?.instagramLink
                                    )}
                                    bottomText={errors?.instagramLink || ""}
                                />
                            </div>

                            <div className="my-5">
                                <PrimaryInput
                                    type="url"
                                    label="X profile (Twitter)"
                                    name="xLink"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.xLink}
                                    error={Boolean(
                                        errors?.xLink && touched?.xLink
                                    )}
                                    bottomText={errors?.xLink || ""}
                                />
                            </div>

                            <div className="my-5">
                                <PrimaryInput
                                    type="url"
                                    label="Tiktok profile"
                                    name="tiktokLink"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.tiktokLink}
                                    error={Boolean(
                                        errors?.tiktokLink &&
                                            touched?.tiktokLink
                                    )}
                                    bottomText={errors?.tiktokLink || ""}
                                />
                            </div>
                        </div>
                        <div className="flex items-center gap-4 mt-10">
                            <PrimaryButton
                                type="submit"
                                className="btn-lg primary-btn mt-2 align-self-baseline"
                            >
                                Submit
                            </PrimaryButton>
                            <PrimaryButton
                                type="reset"
                                className="btn-lg secondary-btn mt-2 align-self-baseline"
                                onClick={onClose}
                            >
                                Cancel
                            </PrimaryButton>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
