import { HeaderComponent } from "pages/landingPage";
import React, { useState } from "react";
import threadVector from "assets/svg/threadVector.svg";
import style from "../styles/forum.module.scss";
import {
    PrimaryDropzone,
    PrimaryInput,
    PrimaryTextarea,
} from "components/inputs";

import { MdOutlineStarBorder } from "react-icons/md";

const ratings = ["Very bad", "Fair", "Average", "Very good", "Perfect"];

export const CreateThread = () => {
    const [name, setName] = useState("");
    const [category, setCategory] = useState("");
    const [experience, setExperience] = useState("");
    const [photos, setPhotos] = useState<File[]>([]);
    const [businessName, setBusinessName] = useState("");
    const [overallRating, setOverallRating] = useState(0);
    const [customerServiceRating, setCustomerServiceRating] = useState(0);
    const [productQualityRating, setProductQualityRating] = useState(0);
    const [valueForMoneyRating, setValueForMoneyRating] = useState(0);
    const [deliveryRating, setDeliveryRating] = useState(0);

    const handlePhotoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(e.target.files || []);
        setPhotos((prevPhotos) => [...prevPhotos, ...files]);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        console.log({
            businessName,
            category,
            overallRating,
            customerServiceRating,
            productQualityRating,
            valueForMoneyRating,
            deliveryRating,
            experience,
        });
    };

    const handleRating = (
        ratingSetter: React.Dispatch<React.SetStateAction<number>>,
        rating: number
    ) => {
        ratingSetter(rating);
    };

    return (
        <>
            <HeaderComponent role="general" />
            <div className="bg-white">
                <div
                    className={`container flex md:flex-row flex-col justify-between items-center ${style.create_thread}`}
                >
                    <div className="basis-2/4 w-full">
                        <form
                            className="w-full md:max-w-xxl shadow-md rounded px-8 pt-6 pb-8 mb-4"
                            onSubmit={handleSubmit}
                        >
                            <h2 className="text-4xl  mb-6">
                                Creating a Thread
                            </h2>
                            <div className="mb-4">
                                <label
                                    className="block text-gray-700 text-sm  mb-2"
                                    htmlFor="name"
                                >
                                    Name of business/product
                                </label>
                                <PrimaryInput
                                    id="name"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Name of business/product"
                                />
                            </div>

                            {/* <div className="mb-4">
              <label
                className="block text-gray-700 text-sm  mb-2"
                htmlFor="category"
              >
                Category
              </label>
              <PrimaryInput
                id="category"
                type="text"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                placeholder="Category"
              />
                          </div> */}

                            {/* <div className="mb-4">
              <label
                className="block text-gray-700 text-sm  mb-2"
                htmlFor="rating"
              >
                Select your rating
              </label>
              <div className={style.ratings_group}>
                <div className="p-1">
                  {[
                    {
                      label: "Overall rating",
                      value: overallRating,
                      setter: setOverallRating,
                    },
                    {
                      label: "Customer service",
                      value: customerServiceRating,
                      setter: setCustomerServiceRating,
                    },
                    {
                      label: "Product quality",
                      value: productQualityRating,
                      setter: setProductQualityRating,
                    },
                    {
                      label: "Value for money",
                      value: valueForMoneyRating,
                      setter: setValueForMoneyRating,
                    },
                    {
                      label: "Delivery",
                      value: deliveryRating,
                      setter: setDeliveryRating,
                    },
                  ].map((rating) => (
                    <div
                      className={`${style.rating} "flex justify-between items-center mb-1 "`}
                      key={rating.label}
                    >
                      <div className={style.rating_label}>{rating.label}</div>
                      <div className={`${style.star_group} `}>
                        {ratings.map((ratingLabel, index) => (
                          <div
                            className={style.star_box}
                            onClick={() =>
                              handleRating(rating.setter, index + 1)
                            }
                          >
                            <MdOutlineStarBorder
                              key={index}
                              color={
                                rating.value >= index + 1
                                  ? "yellow.400"
                                  : "gray.300"
                              }
                              cursor="pointer"
                              className={`${style.star} ${
                                rating.value >= index + 1 ? "active" : ""
                              }`}
                            />
                            <p>{ratingLabel}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
                          </div> */}

                            <div className="mb-4">
                                <label
                                    className="block text-gray-700 text-sm  mb-2"
                                    htmlFor="experience"
                                >
                                    Write about your experience here
                                </label>
                                <PrimaryTextarea
                                    id="experience"
                                    value={experience}
                                    onChange={(e) =>
                                        setExperience(e.target.value)
                                    }
                                    placeholder="Write about your experience here"
                                />
                            </div>

                            {/* <div className="mb-4">
                            <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="photos"
                            >
                                Add Photos
                            </label>
                            <PrimaryDropzone fileClass="" />
                        </div> */}

                            <div className="flex items-center justify-start gap-10">
                                <button
                                    type="submit"
                                    className="btn-sm primary-btn focus:outline-none focus:shadow-outline"
                                >
                                    Post Thread
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setName("");
                                        setCategory("");
                                        setExperience("");
                                        setPhotos([]);
                                    }}
                                    className="secondary-btn btn-sm "
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="py-[30px]">
                        <img
                            alt=""
                            src={threadVector}
                            style={{ width: "80%" }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
