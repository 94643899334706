const HowItWorksBusiness = () => {
    return (
        <div className="flex items-center justify-center md:pt-24 pt-10 flex-col pb-8">
            <h3 className="text-center text-4xl mb-10 font-semibold">
                How it works for business
            </h3>

            <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
                {/*  */}
                <div className="border-2 border-grey-100 rounded-xl p-7 flex flex-col gap-5 max-w-md min-h-72">
                    <p className="text-5xl font-medium text-grey-200">01</p>
                    <p className="font-semibold text-2xl">Sign up</p>
                    <ul className="flex flex-col gap-4">
                        <li>
                            Start your journey by creating an account on
                            tradetracka. Simply provide your email address,
                            create a password, and verify your account.
                        </li>
                    </ul>
                </div>

                <div className="border-2 border-grey-100 rounded-xl p-7 flex flex-col gap-5 max-w-md min-h-72">
                    <p className="text-5xl font-medium text-grey-200">02</p>
                    <p className="font-semibold text-2xl">Explore and Search</p>
                    <ul className="list-style-check flex flex-col gap-4">
                        <li>
                            Explore various categories and topics that interest
                            you on your dashboard.
                        </li>
                        <li>
                            Search for products or service reviews using our
                            search feature.
                        </li>
                    </ul>
                </div>

                <div className="border-2 border-grey-100 rounded-xl p-7 flex flex-col gap-5 max-w-md min-h-72">
                    <p className="text-5xl font-medium text-grey-200">03</p>
                    <p className="font-semibold text-2xl">Write Reviews</p>
                    <ul className="list-style-check flex flex-col gap-4">
                        <li>
                            View reviews and ratings for your selected product
                            or service
                        </li>
                        <li>
                            Contribute by writing your own reviews to help
                            others make informed decisions.
                        </li>
                    </ul>
                </div>

                <div className="border-2 border-grey-100 rounded-xl p-7 flex flex-col gap-5 max-w-md min-h-72">
                    <p className="text-5xl font-medium text-grey-200">04</p>
                    <p className="font-semibold text-2xl">Stay Informed</p>
                    <ul className="list-style-check flex flex-col gap-4">
                        <li>
                            Stay updated with the latest reviews and ratings on
                            Tradetracka
                        </li>
                        <li>
                            Like, comment, and share reviews to engage with the
                            community.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default HowItWorksBusiness;
