import { SearchComponent } from "./searchComponent"
import style from "../../styles/searchComponent.module.scss";
import { CloseButton } from '@chakra-ui/react'
import { FooterComponent } from "pages/landingPage";
import { PrimaryButton } from "components/index";

export const NoBusinessFound = () => {
    return (
        <>
            <div className="container">
                {/* <SearchComponent /> */}
                <NotFound />
            </div>
            <FooterComponent />
        </>
    )
}

export const NotFound = () => {
    return (
        <>

            <div className={style.errorDiv}>
                <div className={style.closeButton}>
                    <button className="flex border-none">
                        <CloseButton colorScheme="#001C89" size='sm' style={{ width: '16px', height: '16px', border: 'none', marginTop: '5px' }} />
                        <span className=""> close </span>
                    </button>
                </div>

                <h1>Can’t find the business you want to review?</h1>

                <PrimaryButton className="btn-lg primary-btn me-3 mt-3 mb-3">
                    Create a Thread
                </PrimaryButton>
            </div>
        </>
    )
}