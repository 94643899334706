/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { ReactComponent as Logo } from "assets/svg/tradetracker-logo.svg";
// import style from "../../styles/footer.module.scss";
import { FaLinkedin, FaXTwitter } from "react-icons/fa6";
import { FaInstagramSquare, FaFacebookSquare } from "react-icons/fa";
import { Link } from "react-router-dom";

export const FooterComponent = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div className="bg-grey-200 py-10">
            <div className="container">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <Link
                        className="col-span-2 md:col-span-1 mb-8 inline w-fit"
                        to={"/"}
                    >
                        <Logo className="w-32 h-auto object-cover" />
                    </Link>

                    <div className="flex justify-between font-medium">
                        <ul className="flex flex-col gap-10">
                            <li>
                                <Link to={"/support/how-it-works"}>
                                    How it works
                                </Link>
                            </li>
                            <li>
                                <Link to={"/tracker-for-business"}>
                                    Tracka for business
                                </Link>
                            </li>
                        </ul>
                        <ul className="flex flex-col gap-10">
                            <li>
                                <Link to={"/support"}>Support</Link>
                            </li>
                            <li>
                                <Link to={"/company"}>Company</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="md:col-span-1 col-span-2 place-items-end md:flex justify-end items-start">
                        <ul className="flex flex-col gap-4">
                            <li>
                                <Link
                                    to={"/support/contact-support"}
                                    className="font-semibold"
                                >
                                    Contact us
                                </Link>
                            </li>
                            <li>
                                <a href="tel:+2348065165064">+2348065165064</a>
                            </li>
                            <li>
                                <a href="mailto:info@tradetracka.io">
                                    info@tradetracka.io
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="flex mt-8 justify-between flex-col gap-4 md:flex-row md:gap-0 md:items-center">
                    <div className="">
                        <div className="flex gap-8 mb-6">
                            {/* <a
                                href="#"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaLinkedin className="text-3xl text-primary-500" />
                            </a>
                            <a
                                href="#"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaXTwitter className="text-[1.8rem] mt-[.05rem] text-grey-300 bg-primary-500 rounded" />
                            </a> */}
                            <a
                                href="https://www.instagram.com/tradetracka"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaInstagramSquare className="text-3xl text-primary-500" />
                            </a>
                            <a
                                href="https://www.facebook.com/tradetracka"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaFacebookSquare className="text-3xl text-primary-500" />
                            </a>
                        </div>
                        <p>© Trade Tracka {currentYear}. All right reserved</p>
                    </div>

                    <div className="flex items-center gap-6">
                        <Link to={"/privacy"}>Privacy</Link>
                        <Link to={"/terms-and-conditions"}>
                            Terms and conditions
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};
