export interface CommentData {
    id: number;
    userImage: string;
    username: string;
    comment: string;
    time: string;
    date: string;
    likeCount: number;
    dislikeCount: number;
    commentCount: number;
    shareCount: number;
}

export const comments: CommentData[] = Array.from(
    { length: 0 },
    (_, index) => ({
        id: index + 1,
        userImage: "https://via.placeholder.com/48",
        username: `User ${index + 1}`,
        comment: `This is comment number ${index + 1}.`,
        time: "10:00am",
        date: `May 05, 2024`,
        likeCount: Math.floor(Math.random() * 100),
        dislikeCount: Math.floor(Math.random() * 50),
        commentCount: Math.floor(Math.random() * 30),
        shareCount: Math.floor(Math.random() * 20),
    })
);
