import React, { useEffect } from "react";
import SectionTitle from "./SectionTitle";
import style from "../../../styles/dashboard.module.scss";
import { BiEdit } from "react-icons/bi";
import { useAuth } from "store/auth";
import {
    BusinessCategory,
    useGetAllBusinessCategoryQuery,
    useGetMyBusinessQuery,
    useUpdateBusinessMutation,
} from "store/business";
import { AuthHeaderText } from "components/custom";
import { PrimaryButton } from "components/index";
import { PrimaryInput, PrimaryMultiSelect } from "components/inputs";
import { useFormik } from "formik";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";

const ServicesSection: React.FC = () => {
    const { user } = useAuth();
    const [showEditModal, setShowEditModal] = React.useState(false);
    const { data: businessInfo } = useGetMyBusinessQuery(user?.id || "");
    const { businessCategory: services = [] } = businessInfo?.data?.[0] || {};

    return (
        <>
            <div className={style.services_section}>
                <div className={style.info_section_header}>
                    <SectionTitle title="Category" />
                    {/* <button
                        // onClick={() => setShowEditModal(true)}
                        className={style.edit_btn}
                    >
                        <BiEdit />
                        Edit
                    </button> */}
                </div>
                <div className={style.service_tags}>
                    {services?.map((service: any, index: number) => (
                        <span key={index} className={style.service_tag}>
                            {service.name}
                        </span>
                    ))}
                </div>
            </div>
            {showEditModal ? (
                <EditModal
                    show={showEditModal}
                    businessInfo={businessInfo?.data?.[0] || {}}
                    onClose={() => setShowEditModal(false)}
                />
            ) : null}
        </>
    );
};

export default ServicesSection;

type TEditModal = {
    show: boolean;
    businessInfo: any;
    onClose: () => void;
};
function EditModal({ onClose, businessInfo }: TEditModal) {
    const initialValues = {
        businessCategory: "2",
    };
    const { initNotification } = usePageNotificationProvider();
    const { data: categories } = useGetAllBusinessCategoryQuery();
    const businessCategory: BusinessCategory[] = categories?.data;
    const [updateBusiness] = useUpdateBusinessMutation();
    const { values, setValues, errors, touched, handleSubmit } = useFormik({
        initialValues,
        validationSchema: null,
        onSubmit: (e) => initRequest(e),
    });

    const initRequest = (payload: any) => {
        Object.keys(payload).forEach((key) => {
            if (!Object.keys(initialValues).includes(key)) {
                delete payload[key];
            }
        });

        payload.businessCategoryID = payload.businessCategory;
        payload.services = payload.businessCategory;

        updateBusiness({ businessData: payload, id: businessInfo?.id })
            .unwrap()
            .then((res: any) => {
                initNotification({
                    scheme: "success",
                    message: res.message,
                });
            })
            .catch((err: any) =>
                initNotification({
                    scheme: "error",
                    message: err?.data?.message || err.error,
                })
            );
    };

    useEffect(() => {
        setValues({
            ...businessInfo,
        });
    }, [businessInfo]);

    return (
        <div
            onClick={onClose}
            className="fixed inset-0 bg-black/50 flex flex-col item-center justify-center overflow-hidden z-[4]"
        >
            <div className="h-full overflow-y-scroll py-20 pt-36">
                <div
                    onClick={(e) => e.stopPropagation()}
                    className="bg-white min-h-full md:min-h-96 w-full max-w-2xl md:px-8 py-10 px-4 rounded-lg m-auto"
                >
                    <AuthHeaderText
                        title="Edit your business description"
                        headingClassName="text-black text-3xl font-medium mb-5"
                    />
                    <br />
                    <form onSubmit={handleSubmit} className="">
                        <div className="mt-3 d-flex flex-column w-100 gap-4">
                            <div className="my-5">
                                <PrimaryMultiSelect
                                    name="businessCategory"
                                    label="Category"
                                    error={Boolean(
                                        errors?.businessCategory &&
                                            touched?.businessCategory
                                    )}
                                    isMulti={true}
                                    options={businessCategory?.map((c) => ({
                                        label: c.name,
                                        value: c.id,
                                    }))}
                                    onMultiChange={(value) => {
                                        setValues((prev: any) => {
                                            return {
                                                ...prev,
                                                businessCategory: value.map(
                                                    (val) => val.value
                                                ),
                                            };
                                        });
                                    }}
                                    value={businessCategory
                                        ?.filter((c) =>
                                            values?.businessCategory?.includes(
                                                c.id as unknown as string
                                            )
                                        )
                                        .map((c) => ({
                                            label: c.name,
                                            value: c.id,
                                        }))}
                                />
                            </div>
                        </div>
                        <div className="flex items-center gap-4 mt-10">
                            <PrimaryButton
                                type="submit"
                                className="btn-lg primary-btn mt-2 align-self-baseline"
                            >
                                Submit
                            </PrimaryButton>
                            <PrimaryButton
                                type="reset"
                                className="btn-lg secondary-btn mt-2 align-self-baseline"
                                onClick={onClose}
                            >
                                Cancel
                            </PrimaryButton>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
