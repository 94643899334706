import { useNavigate } from "react-router";
import style from "../../styles/homePage.module.scss";
import { toast } from "react-toastify";

//!! ============== WE REFINE COMPONENT ?? //
export function Redefine() {
  const navigate = useNavigate();
  return (
    <>
      <div className={style.redefine}>
        <div className={style.redefineText}>
          <h1>We're here to redefine your shopping experience.</h1>
        </div>
        <div className={style.redefineLink}>
          <button
            className={style.whiteButton}
            onClick={() => navigate("/sign-up/individual")}
          >
            Get Started &gt;{" "}
          </button>
        </div>
      </div>
    </>
  );
}
