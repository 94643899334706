import React from "react";
import { DashboardFooter } from "./DashboardFooter";
import style from "../../styles/dashboard.module.scss";
import { DashboardHeader } from "./DashboardHeader";
import { DashboardMainContent } from "./DashboardMainContent";

export const BusinessDashboardPage = () => {
  return (
    <>
      <div className={style.dashboard}>
        <DashboardMainContent />
      </div>
    </>
  );
};
