import React from "react";
import style from "../../styles/dashboard.module.scss";

export const DashboardFooter = () => {
  return (
    <>
      <footer className={style.footer}>
        <p>
          Privacy policy | Terms and conditions | All rights reserved. Trade
          tracka 2024
        </p>
      </footer>
    </>
  );
};
