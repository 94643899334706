import {
  ChakraAlertDialog,
  CustomQuillTextarea,
  PrimaryButton,
  PrimaryInput,
  PrimarySelect,
  PrimaryTextarea,
} from "components";
import React, { FC, useState } from "react";
import { Image } from "@chakra-ui/react";
import { useModalProvider } from "providers/modalProvider";
import { useWindowWidth } from "utilities/windowWidth";
import { useFormik } from "formik";
import { AskTheCommunityValidationSchema } from "validations/business";
import { useGetAllOpenBusinessesQuery } from "store/business";
import { useLocation, useParams } from "react-router-dom";

interface AskTheCommunityModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const AskTheCommunityModal: FC<AskTheCommunityModalProps> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const id = useParams();
  const location = useLocation();
  const [value, setValue] = useState<string>("");
  const [reset, setReset] = useState(false);
  const windowWidth = useWindowWidth();
  const { initModal, getModalPayload } = useModalProvider();
  const businessDetails = getModalPayload("askTheCommunity");
  const { data: businesses } = useGetAllOpenBusinessesQuery({});

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: {
      business: location.pathname.split("/")[2] || "",
      question: "",
    },
    validationSchema: AskTheCommunityValidationSchema,
    onSubmit: (e) => console.log(e),
  });

  return (
    <>
      <ChakraAlertDialog
        useFooter={false}
        useCancelButton={false}
        onClose={onClose}
        useProceedButton={false}
        useHeader={true}
        headerProps={{
          className:
            windowWidth <= 768
              ? "px-0 pt-0 text-[#303030] font-medium mt-3"
              : "px-0 pt-0 text-[#303030] text-[24px] font-medium",
        }}
        useFloatingCancel={false}
        title={"Ask the community a question"}
        size={"2xl"}
        closeOnOverlayClick={true}
        modalContentProps={{ className: "rounded-md p-9" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <form
          onSubmit={handleSubmit}
          className="max-h-[500px] overflow-auto py-5"
          style={{
            maxHeight: windowWidth >= 768 ? "400px" : "600px",
            // marginTop: windowWidth >= 768 ? "22px" : "22px",
          }}
        >
          <div className="mb-4">
            {/* <PrimaryInput
              type="name"
              label="Enter business or product name"
              name="name"
              // placeholder="example@mail.com"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={Boolean(errors?.name && touched?.name)}
              bottomText={errors?.name || ""}
            /> */}
            <PrimarySelect
              label="Enter business or product name"
              name="business"
              // placeholder="example@mail.com"
              className="primary-select py-4"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.business}
              error={Boolean(errors?.business && touched?.business)}
              bottomText={errors?.business || ""}
            >
              <option value="">Select option</option>
              {businesses?.data?.map((business: any) => (
                <option key={business?.id} value={business?.id}>
                  {business?.businessName}
                </option>
              ))}
            </PrimarySelect>
          </div>
          <div>
            <CustomQuillTextarea
              placeholder="What would you like to ask?"
              onChange={(val) => {
                setFieldValue("question", val);
                setFieldTouched("question");
                setFieldError("question", val ? "" : "This field is required");
              }}
              reset={reset}
              label="Ask a question about this product or service"
              toolbar={null}
              wrapperClassName={"border border-[#C2C2C2] rounded-2xl"}
              editorClassName={"min-h-44"}
              // value={value}
              error={Boolean(errors.question && touched.question)}
              bottomText={errors.question || ""}
            />
          </div>
          <PrimaryButton className="btn-md primary-btn mt-5 !font-normal">
            Submit
          </PrimaryButton>
        </form>
      </ChakraAlertDialog>
    </>
  );
};
