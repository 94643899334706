import { FooterComponent, HeaderComponent } from "pages/landingPage";
import React from "react";
import { Comment } from "./Comment";

export const CommentSection = () => {
  const comments = [
    {
      author: "Karen Adaramola",
      time: "10:00am May 05, 2024",
      content:
        "@NicoleAdaramola I currently use Atatrodo special, @Atatrodo you can check them out here",
      likes: 25,
    },
  ];
  return (
    <>
      <HeaderComponent role="general" />

      <div className=" max-w-[1200px] my-[120px] mx-auto px-4 py-8">
        <a href="#" className="text-blue-600 mb-4 block">
          &lt; Back
        </a>

        <Comment
          author="Nicole Adaramola"
          time="10:00am May 05, 2024"
          content="I need a pepper spray o, please who can recommend one for me"
          likes={25}
          comments={10}
          shares={10}
          commentList={comments}
        />
      </div>

      <FooterComponent />
    </>
  );
};
