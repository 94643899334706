import React, { FC, useEffect, useState } from "react";
import { Link, NavLink /*useLocation*/, useNavigate } from "react-router-dom";
// import { useModalProvider } from "providers";
import { ReactComponent as Logo } from "assets/svg/tradetracker-logo.svg";
import { ReactComponent as MenuIcon } from "assets/svg/menu.svg";
import { ReactComponent as ProfileIcon } from "assets/svg/profile.svg";
import { ReactComponent as LogOutIcon } from "assets/svg/logout.svg";
import { ReactComponent as CloseIcon } from "assets/svg/close.svg";
import { Avatar, PrimaryButton } from "components";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { toast } from "react-toastify";
import { onAuthStateChanged, getIdToken } from "firebase/auth";
import { auth } from "firebaseConfig/config";
import { setCredential, useAuth, useLogOutMutation } from "store/auth";
import {
    ProfileInformationResponse,
    useGetProfileInformationWithTokenQuery,
} from "store/profile";
import { useDispatch } from "react-redux";
import { getNameInitials, mapAuthCodeToMessage } from "utilities/general";
import style from "../../styles/dashboard.module.scss";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { useGetMyBusinessQuery } from "store/business";

interface HeaderComponentProps {
    role?: "general" | "shopper" | "business";
}

export const HeaderComponent: FC<HeaderComponentProps> = ({
    role = "general",
}) => {
    // const location = useLocation();
    const navigate = useNavigate();
    const { user: storedUser, token: access_token } = useAuth();
    const { data: businessInfo } = useGetMyBusinessQuery(storedUser?.id || "");
    const dispatch = useDispatch();
    const [bg, setBg] = useState("");
    // const { initModal } = useModalProvider();
    const { initNotification } = usePageNotificationProvider();
    const [token, setToken] = useState<string | undefined | any>("");
    const [menuDropDown, setMenuDropDown] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [logOut] = useLogOutMutation();

    const listenScrollEvent = () => {
        if (window.scrollY > 34) {
            setBg("bg-header");
        } else {
            setBg("transparent");
        }
    };

    window.addEventListener("scroll", listenScrollEvent);

    // console.log(profileInfo);

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                await getIdToken(user)
                    .then((result) => {
                        setToken(result);
                        if (storedUser) {
                            dispatch(
                                setCredential({
                                    user: storedUser,
                                    access_token: result,
                                })
                            );
                        }
                    })
                    .catch((err) => {
                        if (err?.code) {
                            dispatch(
                                setCredential({
                                    user: storedUser,
                                    access_token,
                                    isLoading: false,
                                })
                            );
                            initNotification({
                                message: mapAuthCodeToMessage(err?.code),
                                scheme: "error",
                            });
                        }
                    });
            } else {
                // User is signed out
                // ...
            }
        });
    }, [access_token, dispatch, initNotification, storedUser]);

    const handleLogout = () => {
        auth.signOut();
        navigate("/login", { replace: true });
        // toast.success("You've been logged out");
        dispatch(
            setCredential({
                user: null,
                access_token: null,
            })
        );
        localStorage.clear();
        window.location.reload();
    };

    return (
        <>
            <header className={`top-site-header transition-all relative ${bg}`}>
                <div className="header-container">
                    <div
                        className={`flex items-center justify-between gap-20 h-full`}
                    >
                        <Link
                            to="/"
                            className="font-extrabold text-2xl text-gray-500"
                        >
                            <Logo />
                        </Link>
                        {/* User profile component */}
                        <nav
                            className={
                                role === ("general" || "shopper")
                                    ? `gap-4 lg:flex hidden flex-row items-center justify-between grow`
                                    : `gap-4 lg:flex hidden flex-row items-center justify-between`
                            }
                        >
                            {/* <SearchBar width="280px" height="28px" /> */}
                            {role === "general" && (
                                <>
                                    <div className="flex items-center gap-4">
                                        <NavLink
                                            to="/tracker-for-business"
                                            className="custom-nav-active-header-class flex gap-3 items-center text-base text-[#282828] py-1 mx-3 font-medium"
                                        >
                                            Tracka for Business
                                        </NavLink>
                                        <NavLink
                                            to={"/forum"}
                                            className="custom-nav-active-header-class flex gap-3 items-center text-base text-[#282828] py-1 mx-3 font-medium"
                                        >
                                            Forum
                                        </NavLink>
                                        <NavLink
                                            to="/company"
                                            className="custom-nav-active-header-class flex gap-3 items-center text-base text-[#282828] py-1 mx-3 font-medium"
                                        >
                                            Company
                                        </NavLink>
                                        <NavLink
                                            to="/support"
                                            className="custom-nav-active-header-class flex gap-3 items-center text-base text-[#282828] py-1 mx-3 font-medium"
                                        >
                                            Support
                                        </NavLink>
                                    </div>

                                    <div className="flex items-center gap-4">
                                        <NavLink
                                            to="/businesses"
                                            className="flex gap-3 items-center text-base text-[#282828] py-1 mx-3 font-medium"
                                        >
                                            Write a review
                                        </NavLink>

                                        {storedUser ? (
                                            <>
                                                <div
                                                    onClick={() =>
                                                        setMenuDropDown(
                                                            !menuDropDown
                                                        )
                                                    }
                                                    className="flex items-center gap-3 cursor-pointer hover:bg-primary-50 rounded-lg"
                                                >
                                                    <BiChevronDown className="text-2xl" />
                                                    <div className="bg-primary-500 flex items-center justify-center text-center rounded-full text-white h-10 w-10">
                                                        {/* <p>
                              {getNameInitials(
                                storedUser?.firstName +
                                  " " +
                                  storedUser?.lastName
                              )}
                            </p> */}

                                                        {storedUser?.photoURL ? (
                                                            <div className="w-10 h-10 flex flex-col text-center items-center justify-center rounded-full relative overflow-hidden">
                                                                <img
                                                                    src={
                                                                        storedUser?.photoURL
                                                                    }
                                                                    className="w-full h-full object-cover"
                                                                    alt="profile"
                                                                />
                                                            </div>
                                                        ) : (
                                                            <p className="text-base font-medium">
                                                                {getNameInitials(
                                                                    storedUser?.firstName +
                                                                        " " +
                                                                        storedUser?.lastName
                                                                )}
                                                            </p>
                                                        )}
                                                        {/* <Avatar size="sm" /> */}
                                                    </div>
                                                </div>
                                                {menuDropDown && (
                                                    <ul className="absolute -bottom-[250px] right-0 bg-white flex flex-col w-56 shadow-lg rounded-sm">
                                                        <li>
                                                            <Link
                                                                className="block px-6 py-4 hover:bg-primary-50"
                                                                to={
                                                                    "/shopper/account-settings"
                                                                }
                                                            >
                                                                Account Settings
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link
                                                                className="block px-6 py-4 hover:bg-primary-50"
                                                                to={"/forum"}
                                                            >
                                                                Forum
                                                                Discussions
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link
                                                                className="block px-6 py-4 hover:bg-primary-50"
                                                                to={
                                                                    "/shopper/review-history"
                                                                }
                                                            >
                                                                My History
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link
                                                                className="block px-6 py-4 hover:bg-primary-50"
                                                                to={
                                                                    "/shopper/profile"
                                                                }
                                                            >
                                                                My Profile
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link
                                                                className="block px-6 py-4 hover:bg-red-50 text-red-500"
                                                                to={"#"}
                                                                onClick={
                                                                    handleLogout
                                                                }
                                                            >
                                                                Log Out
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <PrimaryButton className="btn-lg secondary-btn me-3">
                                                    <Link to={"/login"}>
                                                        Log In
                                                    </Link>
                                                </PrimaryButton>
                                                <PrimaryButton className="btn-lg primary-btn me-3">
                                                    <Link
                                                        to={
                                                            "/sign-up/individual"
                                                        }
                                                    >
                                                        Sign Up
                                                    </Link>
                                                </PrimaryButton>
                                            </>
                                        )}
                                    </div>
                                </>
                            )}

                            {role === "shopper" && (
                                <div className="flex items-center grow justify-end gap-8">
                                    {storedUser ? (
                                        <>
                                            <div className="flex items-center grow justify-end gap-8">
                                                <Link
                                                    className="font-medium text-primary-500"
                                                    to={"/"}
                                                >
                                                    Return to landing page
                                                </Link>
                                                <Link to={"/businesses"}>
                                                    Write a review
                                                </Link>
                                                <div
                                                    onClick={() =>
                                                        setMenuDropDown(
                                                            !menuDropDown
                                                        )
                                                    }
                                                    className="flex items-center gap-3 cursor-pointer hover:bg-primary-50 rounded-lg"
                                                >
                                                    <BiChevronDown className="text-2xl" />
                                                    <div className="bg-primary-500 flex items-center justify-center text-center rounded-full text-white h-10 w-10">
                                                        {storedUser?.photoURL ? (
                                                            <div className="w-10 h-10 flex flex-col text-center items-center justify-center rounded-full relative overflow-hidden">
                                                                <img
                                                                    src={
                                                                        storedUser?.photoURL
                                                                    }
                                                                    className="w-full h-full object-cover"
                                                                    alt="profile"
                                                                />
                                                            </div>
                                                        ) : (
                                                            <p className="text-base font-medium">
                                                                {getNameInitials(
                                                                    storedUser?.firstName +
                                                                        " " +
                                                                        storedUser?.lastName
                                                                )}
                                                            </p>
                                                        )}
                                                        {/* <Avatar size="sm" /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            {menuDropDown && (
                                                <ul className="absolute -bottom-[250px] right-0 bg-white flex flex-col w-56 shadow-lg rounded-sm">
                                                    <li>
                                                        <Link
                                                            className="block px-6 py-4 hover:bg-primary-50"
                                                            to={
                                                                "/shopper/account-settings"
                                                            }
                                                        >
                                                            Account Settings
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            className="block px-6 py-4 hover:bg-primary-50"
                                                            to={"/forum"}
                                                        >
                                                            Forum Discussions
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            className="block px-6 py-4 hover:bg-primary-50"
                                                            to={
                                                                "/shopper/review-history"
                                                            }
                                                        >
                                                            My History
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            className="block px-6 py-4 hover:bg-primary-50"
                                                            to={
                                                                "/shopper/profile"
                                                            }
                                                        >
                                                            My Profile
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            className="block px-6 py-4 hover:bg-red-50 text-red-500"
                                                            to={"#"}
                                                            onClick={
                                                                handleLogout
                                                            }
                                                        >
                                                            Log Out
                                                        </Link>
                                                    </li>
                                                </ul>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <PrimaryButton className="btn-lg secondary-btn me-3">
                                                <Link to={"/login"}>
                                                    Log In
                                                </Link>
                                            </PrimaryButton>
                                            <PrimaryButton className="btn-lg primary-btn me-3">
                                                <Link
                                                    to={"/sign-up/individual"}
                                                >
                                                    Sign Up
                                                </Link>
                                            </PrimaryButton>
                                        </>
                                    )}
                                </div>
                            )}

                            {role === "business" && (
                                <div
                                    className={`flex items-center grow justify-end gap-8 ${style.nav_bar_cont} `}
                                >
                                    <div className={style.navbar_links}>
                                        <Link to="/business/dashboard">
                                            Dashboard
                                        </Link>
                                        {/* <Link to="/business/support">
                                            Support
                                        </Link> */}
                                        <Link to="/business/account-settings">
                                            Account
                                        </Link>
                                    </div>
                                    <div className="flex items-center justify-end gap-4">
                                        <Link
                                            to={`/businesses/${businessInfo?.data[0]?.id}`}
                                            className={style.navbar_button}
                                        >
                                            View Business Profile
                                        </Link>

                                        {storedUser ? (
                                            <>
                                                <div
                                                    onClick={() =>
                                                        setMenuDropDown(
                                                            !menuDropDown
                                                        )
                                                    }
                                                    className="flex items-center  gap-3 cursor-pointer hover:bg-primary-50 rounded-lg"
                                                >
                                                    <BiChevronDown className="text-2xl" />
                                                    {storedUser?.photoURL ? (
                                                        <div className="w-10 h-10 flex flex-col text-center items-center justify-center rounded-full relative overflow-hidden">
                                                            <img
                                                                src={
                                                                    storedUser?.photoURL
                                                                }
                                                                className="w-full h-full object-cover"
                                                                alt="profile"
                                                            />
                                                        </div>
                                                    ) : (
                                                        <p className="text-base font-medium">
                                                            {getNameInitials(
                                                                storedUser?.firstName +
                                                                    " " +
                                                                    storedUser?.lastName
                                                            )}
                                                        </p>
                                                    )}
                                                    {/* <Avatar size="sm" /> */}
                                                </div>
                                                {menuDropDown && (
                                                    <ul className="absolute top-full right-0 bg-white flex flex-col w-56 shadow-lg rounded-sm">
                                                        <li>
                                                            <Link
                                                                className="block px-6 py-4 hover:bg-red-50 text-red-500"
                                                                to={"#"}
                                                                onClick={
                                                                    handleLogout
                                                                }
                                                            >
                                                                Log Out
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                )}
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            )}
                        </nav>

                        <span
                            onClick={() => setShowMenu(!showMenu)}
                            className="ml-auto lg:hidden block cursor-pointer"
                        >
                            <MenuIcon />
                        </span>
                        <div
                            className={`lg:hidden block translate-x-full transition-all fixed h-screen pb-7 px-5 w-full !inset-0 bg-white shadow-md ${
                                showMenu ? "!translate-x-0" : "translate-x-full"
                            }`}
                        >
                            <div className="flex item-center justify-between py-4 pb-8">
                                <Logo className="" />
                                <CloseIcon
                                    onClick={() => setShowMenu(!showMenu)}
                                    className="text-6xl w-9 h-9 cursor-pointer"
                                />
                            </div>

                            <div
                                onClick={() => setShowMenu(!showMenu)}
                                className="flex flex-col gap-3 mb-8"
                            >
                                <Link
                                    to={"/login"}
                                    className="flex items-center gap-3 w-full py-4"
                                >
                                    <ProfileIcon />
                                    <p className="font-semibold">Log in</p>
                                </Link>

                                <Link
                                    to={"/sign-up/individual"}
                                    className="flex items-center gap-3 w-full py-4"
                                >
                                    <ProfileIcon />
                                    <p className="font-semibold">Sign Up</p>
                                </Link>

                                <Link
                                    to={"/tracker-for-business"}
                                    className="flex items-center gap-3 w-full py-4"
                                >
                                    <ProfileIcon />
                                    <p className="font-semibold">
                                        Tracka for business
                                    </p>
                                </Link>

                                <Link
                                    to={"/company"}
                                    className="flex items-center gap-3 w-full py-4"
                                >
                                    <ProfileIcon />
                                    <p className="font-semibold">Company</p>
                                </Link>

                                <Link
                                    to={"/support/contact-support"}
                                    className="flex items-center gap-3 w-full py-4"
                                >
                                    <ProfileIcon />
                                    <p className="font-semibold">Support</p>
                                </Link>

                                <Link
                                    to={"/"}
                                    className="flex items-center gap-3 w-full py-4"
                                >
                                    <ProfileIcon />
                                    <p className="font-semibold">
                                        Add business to Trade tracka
                                    </p>
                                </Link>
                            </div>

                            <PrimaryButton className="btn-lg primary-btn me-3 !flex items-center gap-3">
                                <Link to="/businesses">Write A Review</Link>
                                <BiChevronRight />
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

type TMobileMenuLinks = {
    showMenu: boolean;
    menuDropDown: boolean;
    role: string;
    profileInfo: ProfileInformationResponse | undefined;
    setMenuDropDown: React.Dispatch<React.SetStateAction<boolean>>;
    setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
    handleLogout: () => void;
};

function MobileMenuLinks(props: TMobileMenuLinks) {
    const {
        showMenu,
        menuDropDown,
        role,
        profileInfo,
        setMenuDropDown,
        setShowMenu,
        handleLogout,
    } = props;

    const { user: storedUser } = useAuth();

    return (
        <div
            className={`lg:hidden block translate-x-full transition-all fixed h-screen pb-7 px-5 w-full !inset-0 bg-white shadow-md ${
                showMenu ? "!translate-x-0" : "translate-x-full"
            }`}
        >
            <div className="flex item-center justify-between py-4 pb-8">
                <Logo className="" />
                <CloseIcon
                    onClick={() => setShowMenu(!showMenu)}
                    className="text-6xl w-9 h-9 cursor-pointer"
                />
            </div>

            <div
                // onClick={() => setShowMenu(!showMenu)}
                className="flex flex-col gap-3 mb-8"
            >
                {!auth.currentUser ? (
                    <>
                        <Link
                            to={"/login"}
                            className="flex items-center gap-3 w-full py-4"
                        >
                            <ProfileIcon />
                            <p className="font-semibold">Log in</p>
                        </Link>
                        <Link
                            to={"/sign-up/individual"}
                            className="flex items-center gap-3 w-full py-4"
                        >
                            <ProfileIcon />
                            <p className="font-semibold">Sign Up</p>
                        </Link>
                    </>
                ) : (
                    <div className="relative">
                        <Link
                            to={
                                role === "business"
                                    ? "/business/dashboard"
                                    : "#"
                            }
                            onClick={() => setMenuDropDown(!menuDropDown)}
                            className="flex items-center gap-3 w-full py-4"
                        >
                            {!storedUser?.photoURL ? (
                                <p className="text-base">
                                    {getNameInitials(
                                        storedUser?.firstName +
                                            " " +
                                            storedUser?.lastName
                                    )}
                                </p>
                            ) : (
                                <div className="w-10 h-10 flex flex-col text-center items-center justify-center rounded-full relative overflow-hidden">
                                    <img
                                        src={storedUser?.photoURL}
                                        className="w-full h-full object-cover"
                                        alt="profile"
                                    />
                                </div>
                            )}
                            <p className="font-semibold">Go To Dashboard</p>
                        </Link>
                        {menuDropDown && (
                            <ul
                                onClick={() => setShowMenu(!showMenu)}
                                className="bg-white flex flex-col w-full shadow-lg rounded-sm absolute py-4 left-0 right-0"
                            >
                                <li>
                                    <Link
                                        className="block px-6 py-4 hover:bg-primary-50"
                                        to={"/shopper/account-settings"}
                                    >
                                        Account Settings
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className="block px-6 py-4 hover:bg-primary-50"
                                        to={"/forum"}
                                    >
                                        Forum Discussions
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className="block px-6 py-4 hover:bg-primary-50"
                                        to={"/shopper/review-history"}
                                    >
                                        My History
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className="block px-6 py-4 hover:bg-primary-50"
                                        to={"/shopper/profile"}
                                    >
                                        My Profile
                                    </Link>
                                </li>
                            </ul>
                        )}
                    </div>
                )}

                <Link
                    onClick={() => setShowMenu(!showMenu)}
                    to={"/tracker-for-business"}
                    className="flex items-center gap-3 w-full py-4"
                >
                    <ProfileIcon />
                    <p className="font-semibold">Tracka for business</p>
                </Link>

                <Link
                    onClick={() => setShowMenu(!showMenu)}
                    to={"/company"}
                    className="flex items-center gap-3 w-full py-4"
                >
                    <ProfileIcon />
                    <p className="font-semibold">Company</p>
                </Link>

                <Link
                    onClick={() => setShowMenu(!showMenu)}
                    to={"/support/contact-support"}
                    className="flex items-center gap-3 w-full py-4"
                >
                    <ProfileIcon />
                    <p className="font-semibold">Support</p>
                </Link>

                <Link
                    to={"/"}
                    onClick={() => setShowMenu(!showMenu)}
                    className="flex items-center gap-3 w-full py-4"
                >
                    <ProfileIcon />
                    <p className="font-semibold">
                        Add business to Trade tracka
                    </p>
                </Link>

                <div
                    onClick={handleLogout}
                    className="flex hover:bg-red-200 text-red-500 cursor-pointer items-center gap-3 w-full py-4"
                >
                    <LogOutIcon />
                    <p className="font-semibold">Log Out</p>
                </div>
            </div>

            <PrimaryButton className="btn-lg primary-btn me-3 !flex items-center gap-3">
                <Link to="/businesses">Write A Review</Link>
                <BiChevronRight />
            </PrimaryButton>
        </div>
    );
}
