import React, { useEffect } from "react";
import "react-circular-progressbar/dist/styles.css";
import style from "../../styles/dashboard.module.scss";
import ReviewIcon from "../../../assets/svg/reviewSymbol.svg";
import BusinessInfoIcon from "../../../assets/svg/BusinessInfoSymbol.svg";
import AddPhotoIcon from "../../../assets/svg/addPhotoSymbol.svg";
import QuestionsIcon from "../../../assets/svg/QuestionsSymbol.svg";
// import CSimg from "../../../assets/svg/CSImg.svg";
// import productImg from "../../../assets/svg/productImg.svg";
// import deliveryImg from "../../../assets/svg/deliveryImg.svg";
// import valueImg from "../../../assets/svg/valueImg.png";
// import { BiArrowToRight, BiChevronRight } from "react-icons/bi";
import { BsStar, BsStarFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { formatDate } from "utilities/dateFormatter";
import { useAuth } from "store/auth";
import { useGetMyBusinessQuery } from "store/business";
import { ReactComponent as SignalIcon } from "assets/svg/signal-bar.svg";
import {
    IBusinessAverageRatings,
    IBusinessReview,
    IBusinessReviewRes,
    useGetBusinessReviewsQuery,
} from "store/review";
import {
    buildStyles,
    CircularProgressbarWithChildren,
} from "react-circular-progressbar";
// import { AverageRatingsComponent } from "pages/shopper";
import { ProgressBar } from "components/custom";
import moment from "moment";
import Rating from "react-rating-fix";
import { BiChevronRight } from "react-icons/bi";

const ModifiedRating = Rating as any;

export const DashboardMainContent = () => {
    const { user } = useAuth();
    const { data: businessInfo } = useGetMyBusinessQuery(user?.id || "");
    const { data: reviews } = useGetBusinessReviewsQuery({
        id: businessInfo?.data[0]?.id,
        query: { type: "business" },
    });

    const businessReviewsAndAverageRatings:
        | {
              averages: IBusinessAverageRatings;
              reviews: IBusinessReview[];
          }
        | undefined = reviews?.data;

    useEffect(() => {
        console.log(businessReviewsAndAverageRatings);
    }, [businessReviewsAndAverageRatings]);

    return (
        <>
            <main className={`container ${style.main_content}`}>
                <div className={style.business_header}>
                    <h1> {businessInfo?.data[0]?.businessName}</h1>
                    {/* <p>60 users viewed your profile this month</p> */}
                </div>
                <div className={style.business_actions}>
                    <Link to="/business/read-reviews">
                        <div className={`${style.feature_btn} transitions`}>
                            <img src={ReviewIcon} alt="Trade Tracka" />
                            <p>Read reviews</p>
                        </div>
                    </Link>
                    <Link to="/business/business-info">
                        <div className={`${style.feature_btn} transitions`}>
                            <img src={BusinessInfoIcon} alt="Trade Tracka" />
                            <p>Business info</p>
                        </div>
                    </Link>
                    <Link to="/business/add-photo">
                        <div className={`${style.feature_btn} transitions`}>
                            <img src={AddPhotoIcon} alt="Trade Tracka" />
                            <p>Add photos</p>
                        </div>
                    </Link>
                    {/* <Link to="">
                        <div className={`${style.feature_btn} transitions`}>
                            <img src={AddPhotoIcon} alt="Trade Tracka" />
                            <p>Threads</p>
                        </div>
                    </Link> */}
                    <Link to="/business/questions">
                        <div className={style.feature_btn}>
                            <img src={QuestionsIcon} alt="Trade Tracka" />
                            <p>Threads</p>
                        </div>
                    </Link>
                    <Link to="/business/edit-suggestions">
                        <div className={`${style.feature_btn} transitions`}>
                            <img src={BusinessInfoIcon} alt="Trade Tracka" />
                            <p>Read Suggestions</p>
                        </div>
                    </Link>
                </div>
                <div className="flex flex-col md:flex-row gap-10">
                    <div className="md:basis-3/4">
                        <div
                            className={`${style.average_ratings}  p-6 bg-white rounded-lg shadow-md `}
                        >
                            <div className="flex justify-between">
                                <h2 className="text-xl font-semibold text-blue-800">
                                    Average ratings
                                </h2>
                                {/* <button className="flex justify-between items-center px-4 py-2 border border-blue-800 text-blue-800 rounded-lg hover:bg-blue-600 hover:text-white transitions">
                                    see activities
                                    <BiChevronRight />
                                </button> */}
                            </div>
                            <BusinessRatingComponent
                                ratings={
                                    businessReviewsAndAverageRatings?.averages as IBusinessAverageRatings
                                }
                                reviews={
                                    businessReviewsAndAverageRatings?.reviews as IBusinessReview[]
                                }
                            />
                            {/* <AverageRatingsComponent
                                ratings={reviews?.averages}
                                reviews={reviews?.reviews}
                            /> */}
                        </div>

                        <div className={style.performance_analytics}>
                            <h2 className="text-xl font-semibold text-blue-800">
                                Performance and analytics
                            </h2>
                            <div className={style.analytics_details}>
                                {/* /* Add analytics details here  */}
                            </div>
                        </div>
                    </div>
                    <div className="md:basis-1/4">
                        <div className={style.profile_interactions}>
                            <h2 className="text-xl font-semibold text-blue-800 mb-8">
                                Recent profile interactions
                            </h2>
                            <div
                                className={`gap-4 flex flex-col ${style.interactions_list}`}
                            >
                                <p className="text-center text-gray-500 mt-4">
                                    No reviews
                                </p>

                                {[].map((_, idx) => (
                                    <div
                                        key={idx}
                                        className="flex gap-3 justify-between py-3 px-3 bg-grey-50 rounded-md cursor-pointer"
                                    >
                                        <SignalIcon className="w-8 h-8" />
                                        <div className="flex flex-col gap-2">
                                            <h5 className="font-semibold">
                                                You have a new review{" "}
                                            </h5>
                                            <div className="text-gray-400 flex items-center gap-3">
                                                <small>10:00PM</small>
                                                <small>10th Aug, 2024</small>
                                            </div>
                                        </div>
                                        <BiChevronRight className="self-center justify-self-center w-8 h-8" />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

function CircularProgressRating({
    value,
    text,
    rating,
}: {
    value?: number;
    text?: string;
    rating?: number | string;
}) {
    return (
        <>
            <div className="w-24 h-24 text-center">
                <CircularProgressbarWithChildren
                    value={value || 0}
                    styles={buildStyles({
                        pathColor: "#ffa800",
                    })}
                >
                    <b className="text-[#ffa800]">{rating}</b>
                    <small>{text}</small>
                </CircularProgressbarWithChildren>
            </div>
        </>
    );
}

function BusinessRatingComponent({
    reviews,
    ratings,
}: {
    reviews: IBusinessReview[];
    ratings: IBusinessAverageRatings;
}) {
    const getRatingPercentage = (rating: number) => {
        return (rating / 5) * 100;
    };

    const lastUpdated = reviews?.reduce(
        (previous: any, current) => {
            return new Date(previous.createdAt) > new Date(current.createdAt)
                ? previous
                : current;
        },
        reviews?.length > 0 ? reviews[0] : null
    );

    const getRemark = (rating: number) => {
        if (rating >= 0 && rating < 1) {
            return "Poor";
        } else if (rating >= 1 && rating < 2) {
            return "Average";
        } else if (rating >= 2 && rating < 3) {
            return "Good";
        } else if (rating >= 3 && rating < 4) {
            return "Very Good";
        } else if (rating >= 4 && rating <= 5) {
            return "Perfect";
        } else {
            return "No Rating";
        }
    };

    console.log(ratings);

    return (
        <>
            <div
                className={`${style.ratings_details} md:flex grid grid-cols-2 justify-between w-full`}
            >
                <div className={style.total_reviews}>
                    <h3 className="text-md font-medium text-gray-700">
                        Total Reviews
                    </h3>
                    <p className="text-3xl font-semibold mt-1 text-gray-800">
                        {reviews?.length || 0}
                    </p>
                    <p className="text-sm text-gray-500">
                        Last updated on{" "}
                        {formatDate(new Date().toISOString()).commaDateFormat}
                    </p>
                </div>

                <div
                    className={`${style.average_product_rating} border-l border-gray-300 pl-6`}
                >
                    <h3 className="text-md font-medium text-gray-700">
                        Average Product Rating
                    </h3>
                    <div className="flex items-center mt-1">
                        <span className="text-3xl font-semibold mr-2 text-gray-800">
                            {ratings?.averageRating.toFixed(1)}
                        </span>
                        <div className="flex items-center">
                            <ModifiedRating
                                readonly
                                initialRating={ratings?.averageRating}
                                emptySymbol={
                                    <BsStar className="text-gray-300" />
                                }
                                fullSymbol={
                                    <BsStarFill className="text-yellow-400" />
                                }
                                fractions={2}
                            />
                        </div>
                    </div>
                    <p className="text-sm text-gray-500">
                        Last updated on{" "}
                        {moment(lastUpdated?.createdAt).format("Do MMMM, YYYY")}
                    </p>
                </div>

                <div
                    className={`w-full md:w-4/5 max-w-sm mx-4 md:col-auto col-span-2`}
                >
                    <div className="border-l border-gray-300 pl-6 w-100 md:col-span-1 col-span-2 mt-11 md:mt-0">
                        {[
                            {
                                rating: "5 Stars",
                                percentage: ratings?.percentage5Star ?? 0,
                            },
                            {
                                rating: "4 Stars",
                                percentage: ratings?.percentage4Star ?? 0,
                            },
                            {
                                rating: "3 Stars",
                                percentage: ratings?.percentage3Star ?? 0,
                            },
                            {
                                rating: "2 Stars",
                                percentage: ratings?.percentage2Star ?? 0,
                            },
                            {
                                rating: "1 Star",
                                percentage: ratings?.percentage1Star ?? 0,
                            },
                        ].map((rate, idx) => (
                            <div
                                key={idx}
                                className="flex items-center w-full my-2"
                            >
                                <div className="text-sm font-medium text-gray-700 flex-nowrap min-w-16">
                                    {rate.rating}
                                </div>
                                <ProgressBar
                                    value={rate.percentage}
                                    height="6px"
                                    width={"100%"}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/*  */}
            <div className="mt-8 grid md:grid-cols-4 grid-cols-2 gap-4 items-start border-t border-gray-300 pt-4">
                {[
                    {
                        rating: ratings?.averageCustomerService.toFixed(1) ?? 0,
                        value: getRemark(ratings?.averageCustomerService),
                        category: "Customer service",
                    },
                    {
                        rating: ratings?.averageDeliveryRating.toFixed(1) ?? 0,
                        value: getRemark(ratings?.averageDeliveryRating),
                        category: "Delivery",
                    },
                    {
                        rating: ratings?.averageQuality.toFixed(1) ?? 0,
                        value: getRemark(ratings?.averageQuality),
                        category: "Product quality",
                    },
                    {
                        rating: ratings?.averageValueForMoney.toFixed(1) ?? 0,
                        value: getRemark(ratings?.averageValueForMoney),
                        category: "Value for money",
                    },
                ].map((rate, idx) => (
                    <div
                        key={idx}
                        className="text-center flex items-center justify-center gap-5 flex-col"
                    >
                        <CircularProgressRating
                            value={getRatingPercentage(Number(rate.rating))}
                            text={rate.value}
                            rating={rate.rating}
                        />
                        <div className="text-gray-600">{rate.category}</div>
                    </div>
                ))}
            </div>
        </>
    );
}
