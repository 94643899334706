import { FooterComponent, HeaderComponent } from "pages/landingPage";

export const TermsConditions = () => {
  return (
    <div className="bg-white min-h-screen">
      {/*========== HEADER =========== */}
      <HeaderComponent role="general" />
      <div className="container pt-28">
        <h1 className="text-2xl font-bold mb-10">TERMS AND CONDITIONS</h1>

        <div className="mb-6">
          <h3 className="font-bold text-lg">1. Introduction:</h3>
          <p>
            These Terms and Conditions are binding for all users of TradeTracka
            and govern the access and use of the platform. By using the services
            provided by TradeTracka, you agree to comply with these terms.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">2. Eligibility:</h3>
          <p>
            You must be at least 18 years old to use TradeTracka or have legal
            parental or guardian consent.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">
            3. Account and Registration Obligations:
          </h3>
          <p>
            All users must register using accurate and current information. You
            are responsible for all activities under your account and must keep
            your password secure.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">4. Prohibited Conduct:</h3>
          <p>
            Users are prohibited from:
            <br />
            <br />
            Posting false or misleading information.
            <br />
            Engaging in fraudulent activities.
            <br />
            Violating the privacy or intellectual property rights of others.
            <br />
            Transmitting harmful or disruptive code or software.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">5. Content Policy:</h3>
          <p>
            Users retain copyright over their content, but grant TradeTracka a
            royalty-free, perpetual, and irrevocable right to use, reproduce,
            modify, and publish the content contributed to the platform.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">6. Privacy Policy:</h3>
          <p>
            Our privacy policy describes how we handle the information you
            provide us. By agreeing to these Terms, you also agree to our
            privacy policy.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">7. Dispute Resolution:</h3>
          <p>
            Any disputes arising from these Terms will be resolved through final
            and binding arbitration, except where prohibited by law.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">8. Limitations of Liability:</h3>
          <p>
            TradeTracka and its affiliates will not be liable for any direct,
            indirect, incidental, punitive, or consequential damages that may
            result from the use of or inability to use the service.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">9. Indemnification:</h3>
          <p>
            You agree to indemnify, defend, and hold harmless TradeTracka and
            its affiliates from any claims, liabilities, damages, losses, and
            expenses arising from your use of the platform.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">10. Modification of Terms:</h3>
          <p>
            TradeTracka reserves the right to modify these terms at any time. We
            will notify users of significant changes and indicate the date of
            the latest revision.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">11. Termination:</h3>
          <p>
            TradeTracka reserves the right to terminate your access to the
            platform without cause or notice, which may result in the forfeiture
            and destruction of all information associated with your account.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">12. Governing Law:</h3>
          <p>
            These terms shall be governed by the laws of Nigeria. Any claim or
            dispute between you and TradeTracka that arises in whole or in part
            from the TradeTracka service shall be decided exclusively by a court
            of competent jurisdiction located in Nigeria.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">Contact Information:</h3>
          <p>
            For any questions or concerns about these Terms and Conditions,
            please contact us via{" "}
            <a href="mailto:info@tradetracka.io">info@tradetracka.io</a>
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">Effective Date:</h3>
          <p>These Terms and Conditions are effective as of 29th April, 2024</p>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
};
