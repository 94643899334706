import { AuthHeaderText } from "components/custom";
import { PrimaryButton } from "components/index";
import { PrimaryInput } from "components/inputs";
import { Link } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { ReactComponent as Logo } from "assets/svg/tradetracker-logo.svg";
import { useFormik } from "formik";
import { ForgotValidationSchema } from "validations/auth";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { ForgotPayload, useForgotMutation } from "store/auth";
import { resolveApiError } from "utilities/errorHandling";
import { auth } from "firebaseConfig/config";

export const ForgotPassword = () => {
    const { initNotification } = usePageNotificationProvider();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_,{ isLoading }] = useForgotMutation();
    const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
        useFormik({
            initialValues: {
                email: "",
            },
            validationSchema: ForgotValidationSchema,
            onSubmit: (e) => initRequest(e),
        });

    const initRequest = (payload?: ForgotPayload) => {
        if (payload?.email)
            sendPasswordResetEmail(auth, payload?.email, {
                url: `${process.env.REACT_APP_BASE_URL}/reset-password?email=${payload?.email}`,
                handleCodeInApp: true,
            })
                .then(() => {
                    initNotification({
                        message: "An email has been sent to your email address",
                        scheme: "info",
                    });
                })
                .catch((error) => {
                    initNotification({
                        message: resolveApiError(error),
                        scheme: "error",
                    });
                });
    };

    return (
        <div className="flex flex-col bg-[#F0F0F0] justify-center items-center gap-8 auth min-h-[100vh] py-10">
            <Link to="/" className="font-extrabold text-2xl text-gray-500">
                <Logo />
            </Link>
            <div className="auth-form-wrapper bg-white py-10 px-8 rounded-2xl max-w-[450px] w-[90%] mx-auto">
                <AuthHeaderText
                    title="Forgot password"
                    subTitle="Enter your email address and we will send you instructions for  to reset your password"
                    headingClassName="text-black text-3xl font-medium text-center mb-2"
                    subheadingClassName="text-[#919191] text-base font-normal text-center mb-10"
                />

                <form onSubmit={handleSubmit} className="">
                    <div className="mt-3 d-flex flex-column w-100 gap-4">
                        <div className="my-5">
                            <PrimaryInput
                                type="email"
                                label="Email Address"
                                name="email"
                                placeholder="example@mail.com"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                error={Boolean(errors?.email && touched?.email)}
                                bottomText={errors?.email || ""}
                            />
                        </div>
                    </div>

                    <PrimaryButton
                        type="submit"
                        className="btn-lg primary-btn w-full mt-8 align-self-baseline"
                        isLoading={isLoading}
                    >
                        Submit
                    </PrimaryButton>
                </form>
            </div>
        </div>
    );
};
