import { Heading, Text } from "@chakra-ui/react";
import React from "react";
import { useWindowWidth } from "utilities/windowWidth";

export type AuthHeaderTextProps = {
  title: string;
  subTitle?: string;
  headingClassName?: string;
  subheadingClassName?: string;
};

export const AuthHeaderText: React.FC<AuthHeaderTextProps> = ({
  title,
  subTitle,
  headingClassName,
  subheadingClassName,
}) => {
  const windowWidth = useWindowWidth();
  return (
    <div>
      <Heading
        as="h2"
        className={`fw-600 ${headingClassName} ${windowWidth <= 768 && "mb-0"}`}
        size="lg"
        fontFamily="Poppins"
      >
        {title}
      </Heading>
      <Text
        fontSize="md"
        className={`lh-base ${subheadingClassName} ${
          windowWidth <= 768 && "mb-0"
        }`}
      >
        {subTitle}
      </Text>
    </div>
  );
};
