import { Spinner, Stack, StackProps } from "@chakra-ui/react";

interface PrimaryLoaderProps extends StackProps {
  color?: string;
}
export const PrimaryLoader: React.FC<PrimaryLoaderProps> = ({
  color = "teal",
  children,
  ...rest
}) => {
  return (
    <Stack
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"center"}
      minHeight={window.innerHeight}
      position="fixed"
      width="100%"
      left="0"
      right="0"
      top="60px"
      zIndex={99}
      backgroundColor={"#f7f7f7"}
      {...rest}
    >
      {children ?? (
        <div>
          <Spinner color={color} />
        </div>
      )}
    </Stack>
  );
};
