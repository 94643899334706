import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "assets/svg/tradetracker-logo.svg";
import { ReactComponent as BusinessProfileIcon } from "assets/svg/business-profile-illustration.svg";
import { ReactComponent as IndividualProfileIcon } from "assets/svg/individual-profile-illustration.svg";
import { AuthHeaderText, PrimaryButton } from "components/index";
import { auth } from "firebaseConfig/config";
import { onAuthStateChanged } from "firebase/auth";
import { setCredential, useAuth, useCreateUserMutation } from "store/auth";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { useDispatch } from "react-redux";

export const ProfileType = () => {
  const [selected, setSelected] = useState<string | null>(null);
  const [createUser, { isLoading }] = useCreateUserMutation();
  const { initNotification } = usePageNotificationProvider();
  const { token } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const returnTo = localStorage.getItem("returnTo");

  const navigateTo =
    selected === "business"
      ? "/sign-up/business"
      : returnTo
      ? returnTo
      : "/businesses ";

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user?.displayName && user.email) {
        const firstName = user.displayName?.split(" ")[0];
        const lastName = user.displayName?.split(" ")[1];
        const email = user.email;

        createUser({
          email,
          firstName,
          lastName,
        })
          .unwrap()
          .then((res: any) => {
            initNotification({
              scheme: "success",
              message: "Choose your account type",
            });

            dispatch(
              setCredential({
                user: res.data,
                access_token: token,
              })
            );
          })
          .catch((err: any) => console.log(err));
      } else {
        console.log("NO USER");
      }
    });

    return;
  }, [dispatch, initNotification, token]);

  return (
    <div className="min-h-screen w-full bg-grey-100 flex items-center flex-col py-8 pt-12">
      <Link to="/" className="font-extrabold text-2xl text-gray-500">
        <Logo />
      </Link>
      <div className="bg-white py-16 mt-6 px-12 rounded-lg w-11/12 max-w-5xl">
        <AuthHeaderText
          title="Create an account"
          subTitle="Select the type of account you want to create"
          headingClassName="text-black text-3xl font-medium  text-center mb-2"
          subheadingClassName="text-[#919191] text-base font-normal text-center mb-10"
        />

        <div className="flex mt-10 items-center justify-center gap-16 md:flex-row flex-col">
          <div
            onClick={() => setSelected("business")}
            className={`flex items-center flex-col justify-center max-w-80 bg-grey-100 rounded-xl transition-all px-10 py-6 w-full border cursor-pointer hover:border-primary-500 ${
              selected === "business" ? "border-primary-500 shadow-lg" : ""
            }`}
          >
            <BusinessProfileIcon className="mb-8" />

            <h4 className="text-center font-medium text-2xl mb-3">
              Business Profile
            </h4>
            <p className="text-center text-balance">
              Open an account to list your business for free
            </p>
          </div>
          <div
            onClick={() => setSelected("individual")}
            className={`flex items-center flex-col justify-center max-w-80 bg-grey-100 rounded-xl transition-all px-10 py-6 w-full border cursor-pointer hover:border-primary-500 ${
              selected === "individual" ? "border-primary-500 shadow-lg" : ""
            }`}
          >
            <IndividualProfileIcon className="mb-8" />

            <h4 className="text-center font-medium text-2xl mb-3">
              Individual profile
            </h4>
            <p className="text-center text-balance">
              Open an account to read and write reviews.
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center mt-10">
          <PrimaryButton
            disabled={!selected}
            onClick={() => navigate(navigateTo)}
            className="primary-btn btn-lg !px-8"
          >
            Get started
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};
