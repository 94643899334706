import React from "react";
import { PrimaryButton } from "components";
import { Link } from "react-router-dom";
import { MdOutlineInsertComment } from "react-icons/md";
import { FooterComponent, HeaderComponent } from "pages/landingPage/components";
import { ReactComponent as DbStackIcon } from "../../assets/svg/db-stack-bg-ghostblue.svg";
import { ReactComponent as StarIcon } from "../../assets/svg/starRating.svg";
import { ReactComponent as MindTechIcon } from "../../assets/svg/mind-tech-bg-ghostblue.svg";
import maleWorker from "../../assets/images/smiling-male-worker-in-products-juice-factory.jpg";
import no1 from "../../assets/images/01.jpg";
import no2 from "../../assets/images/02.jpg";
import no3 from "../../assets/images/03.jpg";
import appleImage from "../../assets/images/Food.jpg";
import RedefineYourShopping from "./components/redefine-your-shopping";
import { toast } from "react-toastify";

export const TrackaForBusiness = () => {
    return (
        <div className="bg-white min-h-screen">
            {/*========== HEADER =========== */}
            <HeaderComponent role="general" />

            <section className="pt-24 container">
                {/*//******* Section One *************/}
                <div className="flex items-center justify-center gap-8 flex-col pt-24 text-center mb-32">
                    <h2 className="md:text-5xl text-3xl font-semibold text-grey-900 max-w-3xl text-balance">
                        Connect with new customers and grow your business
                    </h2>
                    <p className="text-lg max-w-lg">
                        We've streamlined the process to help you build trust,
                        enhance your reputation and grow your business.
                    </p>
                    <PrimaryButton className="btn-lg primary-btn !py-0">
                        <Link
                            onClick={() => toast.info("Coming soon!")}
                            to="#"
                            // to="/sign-up/individual"
                        >
                            Verify Your Business
                        </Link>
                    </PrimaryButton>
                </div>

                {/*//******* Section Two *************/}
                <div className="flex flex-col-reverse md:flex-row gap-6 pb-6 justify-between md:mb-32 mb-10">
                    <div className="">
                        <div className="bg-primary-50 text-primary-600 py-3 px-6 w-fit font-semibold text-lg rounded-md uppercase">
                            Why list your business
                        </div>
                        <h3 className="md:text-4xl text-3xl font-semibold text-grey-900 tracking-wide py-6">
                            Boost your business
                        </h3>

                        <div className="flex md:gap-2 gap-6 mb-8">
                            <div className="">
                                <DbStackIcon />
                                <h2 className="text-3xl my-2 font-semibold">
                                    Increase Visibility
                                </h2>
                                <p className="text-lg max-w-md">
                                    In a competitive digital landscape, standing
                                    out is crucial. Listing your business on
                                    Trade tracka puts your brand in front of a
                                    vast audience.
                                </p>
                            </div>

                            <div className="">
                                <MindTechIcon />
                                <h2 className="text-3xl my-2 font-semibold">
                                    Customer trust
                                </h2>
                                <p className="text-lg max-w-md">
                                    Gain the trust of potential customers by
                                    showing them that you're willing to be
                                    reviewed and rated. Transparency is a
                                    powerful magnet for consumers.
                                </p>
                            </div>
                        </div>
                        <PrimaryButton className="btn-lg primary-btn !py-0">
                            <Link
                                onClick={() => toast.info("Coming soon!")}
                                to="#"
                                // to="/sign-up/individual"
                            >
                                Create A Profile
                            </Link>
                        </PrimaryButton>
                    </div>
                    <div className="md:w-auto w-full md:h-full h-96">
                        <img
                            src={maleWorker}
                            className="rounded-xl h-full object-cover md:w-auto w-full md:object-center object-top"
                            alt="male worker"
                        />
                    </div>
                </div>

                {/*//******* Section Three *************/}
                <div className="pb-10">
                    <h3 className="md:text-4xl text-3xl font-semibold text-grey-900 tracking-wide">
                        How to list your business
                    </h3>
                    <p className="text-lg max-w-2xl">
                        We've simplified the process into three steps to help
                        you list your business effortlessly and strategically.
                    </p>

                    <div className="mt-16 gap-y-24 md:gap-y-16 flex flex-wrap justify-between">
                        <div className="max-w-md px-4 border-l border-grey-200">
                            <img
                                src={no1}
                                className="bg-grey-600 h-52 object-top object-cover w-full"
                                height={200}
                                alt=""
                            />
                            <div className="flex flex-col gap-6 mt-6">
                                <h4 className="text-2xl font-semibold text-grey-900">
                                    First, create a vibrant Profile
                                </h4>

                                <ul className="flex flex-col gap-6 list-disc list-inside list-style-check">
                                    <li>
                                        Sign up to create your business profile
                                        and verify your business to demonstrate
                                        your commitment to transparency.
                                    </li>
                                    <li>
                                        Fill your profile with essential
                                        information, such as your business
                                        story, services, and engaging photos.
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="max-w-md px-4 border-l border-grey-200 ml-auto md:ml-0">
                            <img
                                src={no2}
                                className="bg-grey-600 h-52 object-top object-cover w-full"
                                height={200}
                                alt=""
                            />
                            <div className="flex flex-col gap-6 mt-6">
                                <h4 className="text-2xl font-semibold text-grey-900">
                                    Then, boost visibility and trust
                                </h4>

                                <ul className="flex flex-col gap-6 list-disc list-inside list-style-check">
                                    <li>
                                        Share critical information like business
                                        hours, your business history, and links
                                        to your website to provide a
                                        comprehensive view.
                                    </li>
                                    <li>
                                        Engage directly with your customers
                                        through likes, comments, and timely
                                        responses to reviews.
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="max-w-md px-4 border-l border-grey-200">
                            <img
                                src={no3}
                                className="bg-grey-600 h-52 object-top object-cover w-full"
                                height={200}
                                alt=""
                            />
                            <div className="flex flex-col gap-6 mt-6">
                                <h4 className="text-2xl font-semibold text-grey-900">
                                    Finally, connect and grow
                                </h4>

                                <ul className="flex flex-col gap-6 list-disc list-inside list-style-check">
                                    <li>
                                        Include your contact information for
                                        easy communication.
                                    </li>
                                    <li>
                                        Receive messages or requests for calls,
                                        quotes, and more, showing customers that
                                        you value their opinion and feedbacks
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="my-10 mt-20 flex items-center justify-center">
                        <PrimaryButton className="btn-lg primary-btn !py-0">
                            <Link to="/sign-up/individual">
                                Add Your Business
                            </Link>
                        </PrimaryButton>
                    </div>
                </div>

                {/*//******* Section Four *************/}
                {/* remove */}

                {/*//******* Section Five *************/}
                <RedefineYourShopping />
            </section>

            <FooterComponent />
        </div>
    );
};

export default TrackaForBusiness;

// /*//******* Section Four *************/
// <div className="pb-10">
//     <h3 className="md:text-4xl text-3xl font-semibold text-grey-900 tracking-wide">
//         Advertise your business on Trade tracka
//     </h3>
//     <p className="text-lg">
//         Jump on this opportunity to drive more leads to your
//         business.
//     </p>

//     <hr className="my-6" />

//     <p className="font-semibold text-lg text-primary-500 uppercase">
//         Promoted businesses
//     </p>

//     <div className="w-full overflow-hidden overflow-x-auto">
//         <div className="my-6 w-fit flex items-center gap-4 flex-nowrap">
//             {/* CARD  */}
//             <div className="w-72">
//                 <div className="relative">
//                     <img
//                         src={appleImage}
//                         className="w-full rounded-md"
//                         alt=""
//                     />
//                     <div className="flex bottom-3 left-3 items-center gap-2 font-normal absolute text-white">
//                         <MdOutlineInsertComment className="text-white text-2xl" />
//                         <p>(20)</p>
//                     </div>
//                 </div>

//                 <div className="flex mt-4 justify-between items-center">
//                     <p>Candice Rice</p>
//                     <p className="flex">
//                         <StarIcon />
//                         <span>5.0</span>
//                     </p>
//                 </div>
//             </div>
//             {/* CARD  */}
//             <div className="w-72">
//                 <div className="relative">
//                     <img
//                         src={appleImage}
//                         className="w-full rounded-md"
//                         alt=""
//                     />
//                     <div className="flex bottom-3 left-3 items-center gap-2 font-normal absolute text-white">
//                         <MdOutlineInsertComment className="text-white text-2xl" />
//                         <p>(20)</p>
//                     </div>
//                 </div>

//                 <div className="flex mt-4 justify-between items-center">
//                     <p>Candice Rice</p>
//                     <p className="flex">
//                         <StarIcon />
//                         <span>5.0</span>
//                     </p>
//                 </div>
//             </div>
//             {/* CARD  */}
//             <div className="w-72">
//                 <div className="relative">
//                     <img
//                         src={appleImage}
//                         className="w-full rounded-md"
//                         alt=""
//                     />
//                     <div className="flex bottom-3 left-3 items-center gap-2 font-normal absolute text-white">
//                         <MdOutlineInsertComment className="text-white text-2xl" />
//                         <p>(20)</p>
//                     </div>
//                 </div>

//                 <div className="flex mt-4 justify-between items-center">
//                     <p>Candice Rice</p>
//                     <p className="flex">
//                         <StarIcon />
//                         <span>5.0</span>
//                     </p>
//                 </div>
//             </div>
//             {/* CARD  */}
//             <div className="w-72">
//                 <div className="relative">
//                     <img
//                         src={appleImage}
//                         className="w-full rounded-md"
//                         alt=""
//                     />
//                     <div className="flex bottom-3 left-3 items-center gap-2 font-normal absolute text-white">
//                         <MdOutlineInsertComment className="text-white text-2xl" />
//                         <p>(20)</p>
//                     </div>
//                 </div>

//                 <div className="flex mt-4 justify-between items-center">
//                     <p>Candice Rice</p>
//                     <p className="flex">
//                         <StarIcon />
//                         <span>5.0</span>
//                     </p>
//                 </div>
//             </div>
//             {/* CARD  */}
//             <div className="w-72">
//                 <div className="relative">
//                     <img
//                         src={appleImage}
//                         className="w-full rounded-md"
//                         alt=""
//                     />
//                     <div className="flex bottom-3 left-3 items-center gap-2 font-normal absolute text-white">
//                         <MdOutlineInsertComment className="text-white text-2xl" />
//                         <p>(20)</p>
//                     </div>
//                 </div>

//                 <div className="flex mt-4 justify-between items-center">
//                     <p>Candice Rice</p>
//                     <p className="flex">
//                         <StarIcon />
//                         <span>5.0</span>
//                     </p>
//                 </div>
//             </div>
//         </div>
//     </div>
//     <hr className="my-6" />

//     <div className="my-10 flex items-center justify-center gap-4">
//         <PrimaryButton className="btn-lg primary-btn !py-0">
//             <Link
//                 onClick={() => toast.info("Coming soon!")}
//                 to="#"
//                 // to="/sign-up/individual"
//             >
//                 Promote Your Business
//             </Link>
//         </PrimaryButton>
//         <PrimaryButton className="btn-lg secondary-btn !py-0">
//             <Link
//                 onClick={() => toast.info("Coming soon!")}
//                 to="#"
//                 // to="/sign-up/individual"
//             >
//                 Learn More
//             </Link>
//         </PrimaryButton>
//     </div>
// </div>;
