import React, { useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { FaRegComment } from "react-icons/fa6";
import { FiThumbsDown, FiThumbsUp } from "react-icons/fi";
import { GoShareAndroid } from "react-icons/go";

interface CommentCardProps {
    userImage: string;
    username: string;
    comment: string;
    time: string;
    date: string;
    likeCount: number;
    dislikeCount: number;
    commentCount: number;
    shareCount: number;
}

export const CommentCard: React.FC<CommentCardProps> = ({
    userImage,
    username,
    comment,
    time,
    date,
    likeCount,
    dislikeCount,
    commentCount,
    shareCount,
}) => {
    const [isCommentOpen, setIsCommentOpen] = useState(false);

    return (
        <>
            <div className="bg-white p-5 py-8 my-5">
                <div className="">
                    <div className="flex gap-2 text-2xl font-semibold mb-5">
                        <h2>Q:</h2>
                        <h2>
                            How do I use the cream in the customer’s package i
                            got from Tastebud cafe
                        </h2>
                    </div>

                    <div className="flex gap-4 items-center mb-5">
                        {/* <img
                                    src=""
                                    alt=""
                                    className="w-6 h-6 p-0 font-normal text-center m-0 bg-primary-500 text-white rounded-full mb-0 flex items-center justify-center"
                                /> */}
                        <div className="w-6 h-6 text-xs p-0 font-normal text-center m-0 bg-primary-500 text-white rounded-full mb-0 flex items-center justify-center">
                            SJ
                        </div>
                        <p className="font-normal">
                            Sabrina Jones Ask the Community about
                            <span className="text-primary-500">
                                {" "}
                                Atarodo sprays
                            </span>
                        </p>
                        <span className="w-2 h-2 rounded-full bg-gray-300 block" />
                        <p>12/06/2024</p>
                    </div>

                    <div className="flex gap-6 items-center py-4">
                        <div className="">
                            <FiThumbsUp className="text-2xl cursor-pointer text-grey-600 -mb-2" />
                            <span className="ml-5 text-sm">{likeCount}</span>
                        </div>
                        <div>
                            <FiThumbsDown className="text-2xl cursor-pointer text-grey-600 -mb-2" />
                            <span className="ml-5 text-sm">{dislikeCount}</span>
                        </div>
                        <div>
                            <FaRegComment className="text-2xl cursor-pointer text-grey-600 -mb-2" />
                            <span className="ml-5 text-sm">{commentCount}</span>
                        </div>
                        <div>
                            <GoShareAndroid className="text-2xl cursor-pointer text-grey-600 -mb-2" />
                            <span className="ml-5 text-sm">{shareCount}</span>
                        </div>
                    </div>
                </div>

                <div className="border-2 rounded-2xl p-4 py-6 mb-4">
                    <div className="flex justify-between items-center">
                        <div
                            className={`${
                                isCommentOpen ? "opacity-0" : "opacity-100"
                            } flex gap-2 text-2xl font-semibold mb-5 items-center transition-all duration-500 ease-in-out`}
                        >
                            <h2>A:</h2>
                            <div className="">
                                <img
                                    src=""
                                    alt=""
                                    className="w-10 h-10 p-0 font-normal text-center m-0 bg-primary-500 text-white rounded-full mb-0 flex items-center justify-center"
                                />
                            </div>
                            <span className="w-2 h-2 rounded-full bg-gray-300 block" />
                            <p className="font-bold text-lg">
                                This question has 6 Replies
                            </p>
                        </div>

                        <div
                            onClick={() => setIsCommentOpen(!isCommentOpen)}
                            className=""
                        >
                            <BiChevronDown className="text-4xl cursor-pointer" />
                        </div>
                    </div>
                    <div
                        className={`${
                            isCommentOpen ? "h-auto pt-4" : "h-0"
                        } overflow-hidden transition-all duration-500 ease-in-out`}
                    >
                        {[1, 2, 3, 4, 5, 6].map((_, idx) => (
                            <CommentList />
                        ))}
                    </div>
                </div>

                <div className="">
                    <input
                        type="text"
                        className="w-full bg-grey-100 rounded-2xl p-2 py-3"
                        placeholder="Write a reply..."
                    />
                </div>
            </div>
        </>
    );
};

export default function CommentList() {
    return (
        <div className="pb-4 pt-6 border-b-2">
            <div className="flex gap-4 items-center mb-5">
                {/* <img
                    src=""
                    alt=""
                    className="w-6 h-6 p-0 font-normal text-center m-0 bg-primary-500 text-white rounded-full mb-0 flex items-center justify-center"
                /> */}
                <div className="w-6 h-6 text-xs p-0 font-normal text-center m-0 bg-primary-500 text-white rounded-full mb-0 flex items-center justify-center">
                    KA
                </div>
                <p className="font-normal">katrina Andres</p>
                <span className="w-2 h-2 rounded-full bg-gray-300 block" />
                <p>12/06/2024</p>
            </div>

            <div className="">
                <p>
                    Mix with water and add sugar to demystify the content, what
                    am i saying bayi any way uou get what i mean. Mix with water
                    and add sugar to demystify the content, what am i saying
                    bayi any way uou get what i mean. Mix with water and add
                    sugar to demystify the content, what am i saying bayi any
                    way uou get what i mean
                </p>
            </div>

            <div className="flex gap-6 items-center py-4">
                <div className="">
                    <FiThumbsUp className="text-2xl cursor-pointer text-grey-600 -mb-2" />
                    <span className="ml-5 text-sm">32</span>
                </div>
                <div>
                    <FiThumbsDown className="text-2xl cursor-pointer text-grey-600 -mb-2" />
                    <span className="ml-5 text-sm">23</span>
                </div>
            </div>
        </div>
    );
}
