import { PrimaryButton } from "components/index";
import { FooterComponent, HeaderComponent } from "pages/landingPage";
import { BiChevronDown } from "react-icons/bi";

export const AskCommunity = () => {
    return (
        <>
            <HeaderComponent role="general" />
            <main className="py-[80px]">
                <section className="mb-10 flex items-center justify-center flex-col gap-8 py-12">
                    <h2 className="text-5xl tracking-wide font-semibold text-gray-600">
                        Ask The Community
                    </h2>
                    <p className="text-xl max-w-xl text-center m-auto">
                        Ask the community questions about products and
                        businesses
                    </p>
                    <PrimaryButton className="btn-lg primary-btn">
                        Ask A Question
                    </PrimaryButton>
                </section>

                <section className="container">
                    <div className="bg-white p-5 py-8">
                        <div className="">
                            <div className="flex gap-2 text-2xl font-semibold mb-5">
                                <h2>Q:</h2>
                                <h2>
                                    How do I use the cream in the customer’s
                                    package i got from Tastebud cafe
                                </h2>
                            </div>

                            <div className="flex gap-4 items-center mb-5">
                                {/* <img
                                    src=""
                                    alt=""
                                    className="w-6 h-6 p-0 font-normal text-center m-0 bg-primary-500 text-white rounded-full mb-0 flex items-center justify-center"
                                /> */}
                                <div className="w-6 h-6 text-xs p-0 font-normal text-center m-0 bg-primary-500 text-white rounded-full mb-0 flex items-center justify-center">
                                    SJ
                                </div>
                                <p className="font-normal">
                                    Sabrina Jones Ask the Community about
                                    <span className="text-primary-500">
                                        {" "}
                                        Atarodo sprays
                                    </span>
                                </p>
                                <span className="w-2 h-2 rounded-full bg-gray-300 block" />
                                <p>12/06/2024</p>
                            </div>
                        </div>

                        <div className="border-2 rounded-2xl p-4 py-6 mb-4">
                            <div className="flex justify-between items-center">
                                <div className="flex gap-2 text-2xl font-semibold mb-5 items-center">
                                    <h2>A:</h2>
                                    <div className="">
                                        <img
                                            src=""
                                            alt=""
                                            className="w-10 h-10 p-0 font-normal text-center m-0 bg-primary-500 text-white rounded-full mb-0 flex items-center justify-center"
                                        />
                                    </div>
                                    <span className="w-2 h-2 rounded-full bg-gray-300 block" />
                                    <p className="font-bold text-lg">
                                        This question has 6 Replies
                                    </p>
                                </div>

                                <div className="">
                                    <BiChevronDown className="text-4xl cursor-pointer" />
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <input
                                type="text"
                                className="w-full bg-grey-100 rounded-2xl p-2 py-3"
                                placeholder="Write a reply..."
                            />
                        </div>
                    </div>
                </section>
            </main>
            <FooterComponent />
        </>
    );
};
