import React, { useEffect } from "react";
import SectionTitle from "./SectionTitle";
import style from "../../../styles/dashboard.module.scss";
import { BiEdit } from "react-icons/bi";
import { useAuth } from "store/auth";
import {
    BusinessCategory,
    IGetMyBusiness,
    useGetAllBusinessCategoryQuery,
    useGetMyBusinessQuery,
    useUpdateBusinessMutation,
} from "store/business";
import { AuthHeaderText } from "components/custom";
import { PrimaryButton } from "components/index";
import { PrimaryMultiSelect } from "components/inputs";
import { useFormik } from "formik";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { SchedulerComponentForm } from "components/custom/scheduler";
import { useModalProvider } from "providers/modalProvider";

interface HoursSectionProps {
    title: string;
}

const OperationsHourSection: React.FC<HoursSectionProps> = ({ title }) => {
    const { user } = useAuth();
    const { data: businessInfo } = useGetMyBusinessQuery(user?.id || "");
    const { initModal, getModalPayload } = useModalProvider();
    const [showEditModal, setShowEditModal] = React.useState(false);
    const { businessHours = [] }: IGetMyBusiness =
        businessInfo?.data?.[0] || {};

    return (
        <>
            <div className={style.hours_section}>
                <div className={style.info_section_header}>
                    <SectionTitle title="Operation Hours" />
                    <button
                        onClick={() =>
                            initModal((prev) => ({
                                ...prev,
                                businessHours: {
                                    payload: {
                                        businessInfo: businessInfo.data,
                                    },
                                },
                            }))
                        }
                        className={style.edit_btn}
                    >
                        <BiEdit />
                        Edit
                    </button>
                </div>
                <p className="my-4">
                    What do you do exceptionally well? What special features do
                    you have to offer? Tell your customers everything
                </p>

                <div className={style.hours_list}>
                    {businessHours.map((hour, index) => (
                        <div key={index} className={style.hours_list_item}>
                            <span>{hour.dayOfWeek}</span>
                            {hour.closed ? (
                                <span>Closed</span>
                            ) : (
                                <span>
                                    {hour.openingTime} - {hour.closingTime}
                                </span>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default OperationsHourSection;
