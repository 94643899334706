import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import { useState } from "react";
import style from "../../styles/homePage.module.scss";
import { PrimaryButton } from "components";
import { Link, useNavigate } from "react-router-dom";
import { MdTune } from "react-icons/md";
import { HomePageReviewCard } from "./homePageReviewCard";
import { IBusinessReview, useGetBusinessReviewsQuery } from "store/review";

export function ReviewsComponent() {
    const navigate = useNavigate();
    const [value, setValue] = useState("1");
    const { data: reviews, refetch } = useGetBusinessReviewsQuery({
        // type: "",
        // id: "",
        query: { page: 1, limit: 8 },
    });
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const [showFullText, setShowFullText] = useState(false);
    const longText =
        "Their AI-powered reviews are a lifesaver. No more fake reviews, just honest opinions. Saved me time and money. Highly recommend!";

    const toggleShowFullText = () => {
        setShowFullText(!showFullText);
    };

    const truncatedText = longText.slice(0, 101);
    const lastVisibleWordIndex = truncatedText.lastIndexOf(" ");
    const visibleText = showFullText
        ? longText
        : truncatedText.slice(0, lastVisibleWordIndex) + "...";

    console.log(reviews);

    return (
        <>
            <div className="container">
                <div className={style.reviews}>
                    <div className={style.something}>
                        <h1>Never miss out on the latest reviews</h1>
                        <p>
                            Read our latest verified reviews and see why
                            customers trust us for their online shopping.
                        </p>
                    </div>

                    <div>
                        <Tabs size="lg" variant="line" defaultIndex={0}>
                            <TabList className={style.tabList}>
                                <Tab
                                    _selected={{
                                        borderBottom: "4px solid #001C89",
                                        fontWeight: "600",
                                        fontSize: "18px",
                                        lineHeight: "27px",
                                        fontFamily: "Poppins",
                                    }}
                                >
                                    All
                                </Tab>
                            </TabList>

                            <div className={style.mobileView}>
                                <p>Recent Updates</p>
                                <button>
                                    {" "}
                                    <MdTune
                                        style={{
                                            marginTop: "5px",
                                            marginRight: "0px",
                                            marginBottom: "0px",
                                        }}
                                    />{" "}
                                    <span>Filter</span>
                                </button>
                            </div>

                            <TabPanels>
                                {/* <>For the All Tab</> */}
                                <TabPanel className="px-0">
                                    {!reviews?.data?.reviews?.length && (
                                        <p className="text-center mt-5">
                                            No reviews yet
                                        </p>
                                    )}

                                    <div className={style.cardsSingle}>
                                        {reviews?.data?.reviews.length
                                            ? [...reviews?.data?.reviews]
                                                  ?.sort(
                                                      (
                                                          a: {
                                                              createdAt:
                                                                  | string
                                                                  | number
                                                                  | Date;
                                                          },
                                                          b: {
                                                              createdAt:
                                                                  | string
                                                                  | number
                                                                  | Date;
                                                          }
                                                      ) =>
                                                          new Date(
                                                              b.createdAt
                                                          ).getTime() -
                                                          new Date(
                                                              a.createdAt
                                                          ).getTime()
                                                  )
                                                  ?.map(
                                                      (
                                                          item: IBusinessReview
                                                      ) => (
                                                          <HomePageReviewCard
                                                              review={item}
                                                              refetch={refetch}
                                                              key={item.id}
                                                          />
                                                      )
                                                  )
                                            : null}
                                    </div>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </div>
                    <div className={style.writeReviewButton}>
                        <PrimaryButton className="btn-lg primary-btn me-3">
                            <Link
                                to={"/businesses"}
                                className="text-lg leading-none"
                            >
                                Write A Review
                            </Link>
                        </PrimaryButton>
                        <PrimaryButton className="btn-lg secondary-btn">
                            <Link
                                to={"/businesses"}
                                className="text-lg leading-none"
                            >
                                Show more
                            </Link>
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </>
    );
}
