import { FooterComponent, HeaderComponent } from "pages/landingPage";

export const PrivacyPolicy = () => {
  return (
    <div className="bg-white min-h-screen">
      {/*========== HEADER =========== */}
      <HeaderComponent role="general" />
      <div className="container pt-28">
        <h1 className="text-2xl font-bold mb-4">PRIVACY POLICY</h1>
        <p className="text-lg mb-10">
          This Privacy Policy outlines how TradeTracka collects, processes, and
          protects your personal data when you use our website or mobile
          applications, ensuring transparency and safeguarding your privacy
          rights.
        </p>

        <div className="mb-6">
          <h3 className="font-bold text-lg">1. Who We Are</h3>
          <p>
            TradeTracka is a dynamic platform dedicated to fostering trust in
            the Nigerian online market through reliable customer reviews and
            robust business analytics.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">
            2. The Data We Collect About You
          </h3>
          <p>
            Personal data: We collect personal information such as name, email
            address, contact details, financial data when you register, place an
            order, or participate in surveys.
            <br />
            Non-personal data: Automatically collected data include IP
            addresses, device types, and browsing details through cookies.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">
            3. Cookies and Other Identifiers
          </h3>
          <p>
            We use cookies to distinguish you from other users, enhance site
            navigation, and personalize your experience.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">
            4. How We Use Your Personal Data
          </h3>
          <p>
            Registering and managing your account.
            <br />
            Processing transactions and providing customer support.
            <br />
            Sending marketing communications and promotional offers, with your
            consent.
            <br />
            Analyzing website usage to improve our platform.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">5. Legal Basis for Processing</h3>
          <p>
            Consent: When you agree to our processing of your personal data.{" "}
            <br />
            Contractual necessity: For the fulfillment of our services. <br />
            Legal obligation: When necessary to comply with the law.
            <br />
            Legitimate interests: For improving our services and protecting
            against fraud. <br />
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">
            6. How We Share Your Personal Data
          </h3>
          <p>
            We may share your data with third-party service providers for
            processing orders, delivering packages, and providing analytics. We
            ensure they adhere to our privacy standards.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">7. International Transfers</h3>
          <p>
            Data may be transferred internationally, with protective measures in
            place to secure your data as per local laws.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">8. Data Retention</h3>
          <p>
            We retain personal data as long as necessary for legal or business
            processes.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">9. Data Security</h3>
          <p>
            We implement security measures to protect your data from
            unauthorized access or breaches.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">10. Your Legal Rights</h3>
          <p>
            You have the right to access, correct, or delete your personal data,
            restrict processing, and withdraw consent.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">11. Data Controllers & Contact</h3>
          <p>
            Contact our Data Privacy Officer via email at
            <a href="mailto:info@tradetrack.io"> info@tradetrack.io</a> for any
            queries or concerns regarding your personal data.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="font-bold text-lg">12. Changes to This Notice</h3>
          <p>
            We reserve the right to modify this Privacy Notice. Any changes will
            be communicated through our website.
            <br />
            This detailed notice reflects our commitment to your privacy and
            informs you of your rights and our obligations.
          </p>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
};
