import { PrimaryButton } from "components/index";
import { CiLocationOn, CiSearch } from "react-icons/ci";
import style from "../../styles/searchComponent.module.scss";
import { FC, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { convertObjectToURLParams } from "utilities/general";
import queryString from "query-string";
import { useSearchParams } from "react-router-dom";

interface SearchComponentProps {
  businesses?: any[];
  onSearch?: (searchValue: string) => void;
  heroSearch?: boolean;
}

export const SearchComponent: FC<SearchComponentProps> = ({
  businesses,
  onSearch,
  heroSearch = false,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { q } = queryString.parse(location.search) as {
    q: string;
    location: string;
  };

  const url = new URL(window.location.href);

  const [searchText, setSearchText] = useState(q || "");
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSearch = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    let search = queryString.stringify({ q: searchText });
    // history.replaceState(null, "", search);
    // search = window.location.search = search;
    heroSearch
      ? navigate(`/businesses?${convertObjectToURLParams({ q: searchText })}`)
      : setSearchParams(`?${search}`);
  };

  console.log(q, searchText, location, url, searchParams);
  // margin-top: 30px;
  // height: 59px;
  // border-radius: 8px;
  // padding-left: 8px;
  // background-color: #f0f0f0;
  // border: 1px solid #c2c2c2;
  // display: flex;
  // justify-content: space-between;
  return (
    <>
      <div>
        <form
          onSubmit={handleSearch}
          className={`${style.searchArea} items-center relative mb-16 sm:px-0 px-[7px] sm:py-0 py-2`}
        >
          <div className="flex justify-between sm:flex-row flex-col w-[80%]">
            <div
              className={`${style.inputDiv1} relative sm:h-[59px] h-[36px] w-full sm:py-2 py-0`}
            >
              <CiSearch className="sm:text-2xl text-xl text-[#606060] absolute left-3 top-1/2 -translate-y-1/2" />
              <input
                type="search"
                className="h-full bg-transparent pl-10"
                placeholder="Search for businesses"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  if (e.target.value === "") {
                    onSearch!(e.target.value);
                    setSearchParams(``);
                  }
                }}
              />
            </div>

            {/* <div
              className={`${style.inputDiv2} relative sm:h-[59px] h-[36px] sm:py-2 py-0`}
            >
              <CiLocationOn className="sm:text-2xl text-xl text-[#606060] absolute left-3 top-1/2 -translate-y-1/2" />
              <input
                type="search"
                className="h-full bg-transparent pl-10 sm:border-l sm:border-t-0 border-l-0 border-t border-[#DADADA]"
                placeholder="Location e.g. Oniru, Lagos"
              />
            </div> */}
          </div>

          <PrimaryButton
            type="submit"
            className="sm:h-full h-[40px] sm:w-auto w-[40px] sm:px-8 px-1 primary-btn flex gap-2 sm:absolute static right-0 top-0"
          >
            <CiSearch className="sm:text-2xl text-xl" />{" "}
            <span className="sm:inline-block hidden">Search</span>
          </PrimaryButton>
        </form>

        <div className={style.mobileSearchArea}>
          {/* <div className="block w-4/5"> */}
          <div className={style.firstInput}>
            <input
              type="search"
              className="h-full"
              placeholder="Search for businesses"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                if (e.target.value === "") {
                  onSearch!(e.target.value);
                  setSearchParams(``);
                }
              }}
            />
          </div>

          <div className={style.secondInput}>
            <div className="flex w-full h-full">
              <input
                type="search"
                className="h-full"
                placeholder="Location e.g. Oniru, Lagos"
              />
            </div>
          </div>
          {/* </div> */}
          <div className="">
            <PrimaryButton className="h-full primary-btn flex gap-2">
              <CiSearch className="h-[20px] w-[20px]" />
            </PrimaryButton>
          </div>
        </div>
      </div>
    </>
  );
};
