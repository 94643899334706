import { ChakraAlertDialog } from "components";
import React, { FC } from "react";
import { Image } from "@chakra-ui/react";
import { useModalProvider } from "providers/modalProvider";
import { useWindowWidth } from "utilities/windowWidth";

interface BusinessPhotosPreviewModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const BusinessPhotosPreviewModal: FC<
  BusinessPhotosPreviewModalProps
> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const windowWidth = useWindowWidth();
  const { initModal, getModalPayload } = useModalProvider();
  const businessPhotos = getModalPayload("businessPhotos");
  const imgCounter =
    businessPhotos?.businessReviewImages?.length > 1 ||
    businessPhotos?.businessImages?.length > 1
      ? "photos"
      : "photo";

  const images =
    businessPhotos?.businessReviewImages || businessPhotos?.businessImages;

  console.log(
    businessPhotos?.businessReviewImages,
    businessPhotos?.businessImages,
    images
  );

  function extractFileNameFromUrl(url: string | URL) {
    if (url) {
      const urlObj = new URL(url);
      const pathname = urlObj.pathname;
      const pathSegments = pathname.split("/");
      const filenameWithParams = pathSegments[pathSegments.length - 1];
      const filenameSegments = filenameWithParams.split("?");
      const filename = filenameSegments[0];
      return filename;
    }
    return "";
  }

  return (
    <>
      <ChakraAlertDialog
        useFooter={false}
        useCancelButton={false}
        onClose={() => {
          initModal((prev) => ({ ...prev, businessPhotos: false }));
        }}
        useProceedButton={false}
        useHeader={true}
        headerProps={{
          className:
            windowWidth <= 768
              ? "px-0 pt-0 text-gray-600 font-medium mt-3"
              : "px-0 pt-0 text-gray-600 font-medium",
        }}
        useFloatingCancel={false}
        title={"Photos"}
        size={windowWidth >= 768 ? "4xl" : "full"}
        closeOnOverlayClick={true}
        modalContentProps={{ className: "rounded-md p-4" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <p className="w-full mb-4">
          {images?.length ?? "No"} {imgCounter}
        </p>
        <div
          className="max-h-[500px] overflow-auto"
          style={{
            maxHeight: windowWidth >= 768 ? "400px" : "600px",
            marginTop: windowWidth >= 768 ? "0" : "40px",
          }}
        >
          <div className="flex flex-col items-center gap-3">
            <div
              className={`flex flex-wrap ${
                images?.length > 2 ? "justify-between" : ""
              } w-full gap-3`}
            >
              {images?.length > 0 &&
                images?.map((media: any, i: number) => (
                  <div
                    key={i}
                    style={{
                      height: "166px",
                      maxWidth: "100%",
                      width: "23%",
                      borderRadius: "8px",
                      overflow: "hidden",
                      border: "1px solid #8080801f",
                    }}
                  >
                    {extractFileNameFromUrl(
                      media?.image || media?.imageUrl
                    ).includes("mp4") ||
                    extractFileNameFromUrl(
                      media?.image || media?.imageUrl
                    ).includes("mpeg") ? (
                      <>
                        {/* <video
                          src={media}
                          width={"100%"}
                          // maxWidth={"100%"}
                          height={"166px"}
                          controls
                        ></video> */}
                        <iframe
                          title={extractFileNameFromUrl(
                            media?.image || media?.imageUrl
                          )}
                          src={media?.image || media?.imageUrl}
                          width={"100%"}
                          // maxWidth={"100%"}
                          height={"100%"}
                          allowFullScreen
                        />
                      </>
                    ) : (
                      <Image
                        src={media?.image || media?.imageUrl}
                        alt={"Image" + (i + 1)}
                        width={"100%"}
                        // maxWidth={"100%"}
                        height={"100%"}
                        objectFit={"cover"}
                        objectPosition={"top"}
                      />
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
