import { useState } from "react";
import { BiSearch } from "react-icons/bi";

const keywords = [
    "React",
    "Tailwind CSS",
    "JavaScript",
    "Frontend Development",
    "Backend Development",
    "Fullstack",
    "CSS",
    "HTML",
    "Node.js",
    "Express",
];

export const SearchBar = () => {
    const [query, setQuery] = useState("");
    const [filteredKeywords, setFilteredKeywords] = useState<string[]>([]);

    const handleSearch = () => {
        const results = keywords.filter((keyword) =>
            keyword.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredKeywords(results);
    };

    return (
        <div className="searchBar">
            <div className="p-4">
                <div className="flex items-center border border-gray-300 rounded-md overflow-hidden hover:ring-4 focus:ring-4 active:ring-4 pr-1">
                    <input
                        type="text"
                        className="w-full outline-none border-none h-full block py-4 px-2"
                        placeholder="Search keywords..."
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                    />
                    <button
                        onClick={handleSearch}
                        className="h-100 p-2 border-none btn-sm primary-btn text-white rounded-md m-0 flex justify-center items-center gap-2"
                    >
                        <span>
                            <BiSearch className="text-base" />
                        </span>
                        Search
                    </button>
                </div>
            </div>

            {query && filteredKeywords.length > 0 && (
                <ul className="mt-2 bg-white border border-gray-300 rounded-md">
                    {filteredKeywords.map((keyword, index) => (
                        <li
                            key={index}
                            className="p-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                                setQuery(keyword);
                                setFilteredKeywords([]);
                            }}
                        >
                            {keyword}
                        </li>
                    ))}
                </ul>
            )}
            {query && filteredKeywords.length === 0 && (
                <div className="mt-2 p-2 text-gray-500">No results found</div>
            )}
        </div>
    );
};
