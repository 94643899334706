import style from "../../styles/searchComponent.module.scss";
import { FiShare } from "react-icons/fi";
import { CiBookmarkPlus } from "react-icons/ci";
import { HiOutlinePlusSmall, HiOutlineStar, HiStar } from "react-icons/hi2";
import React, { useEffect, useState } from "react";
import { MdOutlineInsertComment } from "react-icons/md";
import { ReactComponent as StarIcon } from "assets/svg/starRating.svg";
import appleImage from "assets/svg/food.svg";
import { FooterComponent, HeaderComponent } from "pages/landingPage/components";
import { OverviewTab } from "../components/overviewTab";
import { ReviewTab } from "../components/reviewTab";
import WhiteDot from "assets/svg/whiteDot.svg";
import Marked from "assets/svg/marked.svg";
import { SlPicture } from "react-icons/sl";
import { PrimaryButton, PrimaryLoader } from "components/index";
import {
    useGetBusinessByIdQuery,
    useGetMyBusinessQuery,
    useGetOpenBusinessByIdQuery,
} from "store/business";
import {
    redirect,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import Rating from "react-rating-fix";
import { useModalProvider, usePageNotificationProvider } from "providers/index";
import {
    IBusinessAverageRatings,
    useGetBusinessReviewsQuery,
} from "store/review";
import { RiEditBoxLine } from "react-icons/ri";
import { useAuth } from "store/auth";

const ModifiedRating = Rating as any;

export function BusinessPage() {
    const navigate = useNavigate();
    const { initModal } = useModalProvider();
    const { initNotification } = usePageNotificationProvider();
    const { user: storedUser, token: access_token } = useAuth();
    const { data: businessInfo } = useGetMyBusinessQuery(storedUser?.id || "");
    const { id } = useParams();
    const { data: business, isLoading } = useGetOpenBusinessByIdQuery(id);
    const { data: reviews } = useGetBusinessReviewsQuery({
        id,
        query: { type: "business" },
    });
    const [currentTab, setCurrentTab] = useState(1);
    const [isSameBusiness, setIsSameBusiness] = useState(false);

    console.log(reviews, business);

    const averageRatings = reviews?.data.averages as IBusinessAverageRatings;
    const businessReviewsAndAverageRatings = reviews?.data;

    const biz = business?.data;

    useEffect(() => {
        setIsSameBusiness(String(id) == String(businessInfo?.data[0]?.id));
    }, [id, biz, businessInfo?.data]);

    const [highlight, setHighlight] = useState(false);
    const handleSetHighlight = () => {
        setHighlight(true);
        setTimeout(() => {
            setHighlight(false);
        }, 3000);
    };

    async function checkImage(url: RequestInfo | URL) {
        const res = await fetch(url);
        const buff = await res.blob();

        return buff.type.startsWith("image/");
    }

    if (isLoading) {
        return <PrimaryLoader height={"90vh"} />;
    }

    const placeholder =
        "https://bbcincorp.com/assets/images/hk/home/background-form-banner.png";

    console.log(biz?.businessBanner);

    return (
        <div className="pt-[70px] md:pt-[80px]">
            {/*========== HEADER =========== */}
            <HeaderComponent role="general" />
            {/*========== END OF HEADER =========== */}
            <div
                style={{
                    color: "white",
                    background: `linear-gradient(180deg, rgba(57, 57, 57, 0) 0%, #070707 95.31%), url(${
                        biz?.businessBanner.includes("http")
                            ? biz?.businessBanner
                            : placeholder
                    }) no-repeat`,
                    backgroundSize: "cover",
                }}
                className={style.backgroundImages}
            >
                <div className="container h-full">
                    <div
                        className={`${style.bckWords} h-full lg:justify-between justify-start py-5 pt-14 sm:py-14`}
                    >
                        <div className={style.nameAndDetails}>
                            <h1>{biz?.businessName}</h1>

                            {averageRatings?.averageRating ? (
                                <div className="text-gray-600 flex items-center gap-2 !ml-0 sm:mt-6 mt-3">
                                    <ModifiedRating
                                        // placeholderRating={3.5}
                                        readonly
                                        initialRating={
                                            averageRatings?.averageRating
                                        }
                                        emptySymbol={
                                            <HiOutlineStar className="h-6 w-6 me-2 text-[#FFC554]" />
                                        }
                                        fullSymbol={
                                            <HiStar className="h-6 w-6 me-2 text-[#FFA800]" />
                                        }
                                        fractions={2}
                                        // start={0}
                                        // stop={5}
                                        // step={5}
                                    />
                                    <p className="text-white text-base font-medium">
                                        {averageRatings?.averageRating.toFixed(
                                            1
                                        )}{" "}
                                        ({averageRatings?.reviewsCount} Reviews)
                                    </p>
                                </div>
                            ) : null}

                            <div className={style.verificationBCK}>
                                {biz?.claimed ? (
                                    <>
                                        <img alt="" src={Marked} />{" "}
                                        <p> Claimed</p>
                                    </>
                                ) : (
                                    <p>Not Claimed</p>
                                )}
                                {biz?.businessCategory?.map(
                                    (category: any, index: number) => (
                                        <>
                                            <img
                                                alt=""
                                                src={WhiteDot}
                                                className={style.dotImageBCK}
                                            />{" "}
                                            <p key={index}>{category?.name} </p>
                                        </>
                                    )
                                )}
                            </div>

                            {biz?.businessHours?.length ? (
                                <div className={style.businessTimeBCK}>
                                    <p className={style.blue}> Open </p>
                                    <p>
                                        {biz?.businessHours![0]?.openingTime} -{" "}
                                        {biz?.businessHours![0]?.closingTime}
                                    </p>
                                    <a
                                        href="#hours"
                                        className="btn-sm rounded-md text-primary-500 !bg-white !font-normal"
                                        onClick={handleSetHighlight}
                                    >
                                        View Hours
                                    </a>
                                </div>
                            ) : null}
                        </div>
                        <PrimaryButton
                            onClick={() =>
                                initModal((prev) => ({
                                    ...prev,
                                    businessPhotos: {
                                        payload: {
                                            businessImages: biz?.businessImages,
                                        },
                                    },
                                }))
                            }
                            className="btn-md !bg-[#ffffffcc] text-primary-500 !font-normal self-end"
                        >
                            <SlPicture className="me-2 h-3 w-3" />{" "}
                            <span>See All Photos </span>
                        </PrimaryButton>
                        {/* <div className={style.allPhotos}>
              <button>
                {" "}
                <SlPicture /> <span>See All 100 Photos </span>
              </button>
            </div> */}
                    </div>
                </div>
            </div>
            <div
                style={{
                    boxShadow:
                        "0px 2px 4px 0px #00000014, 0px 0px 6px 0px #00000005",
                }}
                className="py-4"
            >
                <div className="container">
                    <div className="flex justify-between items-center">
                        <h2 className=" text-4xl font-medium text-[#4d4d4d] md:block hidden ">
                            {biz?.businessName}
                        </h2>
                        <div
                            className={`${style.buttonsBusiness} md:flex-row flex-row-reverse`}
                        >
                            {/* <PrimaryButton className="btn-sm secondary-btn !font-normal">
                <IoMdPhotos className="h-4 w-4 me-2" />
                Add photos
              </PrimaryButton> */}

                            <PrimaryButton
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        window.location.href
                                    );
                                    initNotification({
                                        message: "Link copied",
                                        scheme: "success",
                                    });
                                }}
                                className="btn-sm secondary-btn !font-normal"
                            >
                                <FiShare className="h-4 w-4" />
                                <span className="md:inline-block hidden ms-2">
                                    Share
                                </span>
                            </PrimaryButton>

                            <PrimaryButton
                                onClick={() => {
                                    localStorage.setItem(
                                        "suggestingBusinessId",
                                        id || ""
                                    );
                                    initModal((prev) => ({
                                        ...prev,
                                        suggestEdit: true,
                                    }));
                                }}
                                className="btn-sm secondary-btn !font-normal"
                            >
                                <RiEditBoxLine className="h-4 w-4" />
                                <span className="md:inline-block hidden ms-2">
                                    Suggest an edit
                                </span>
                            </PrimaryButton>

                            {/* <PrimaryButton className="btn-sm secondary-btn !font-normal">
                <CiBookmarkPlus className="h-4 w-4 me-2" />
                Save
              </PrimaryButton>

              <PrimaryButton className="btn-sm secondary-btn !font-normal">
                <HiOutlinePlusSmall className="h-4 w-4 me-2" />
                Follow
              </PrimaryButton> */}

                            <PrimaryButton
                                onClick={() => {
                                    navigate(
                                        `/review?business=${id}&name=${biz?.businessName}`
                                    );
                                }}
                                className="btn-sm primary-btn !font-normal"
                            >
                                Write Review
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container pt-8 lg:hidden block">
                <div className="flex justify-between items-center gap-4">
                    <button
                        onClick={() => setCurrentTab(1)}
                        className={`${
                            currentTab === 1
                                ? "border-b text-[#001C89] border-primary-500"
                                : "text-[#C2C2C2]"
                        } pb-3 w-1/2 border-0`}
                    >
                        Overview
                    </button>
                    <button
                        onClick={() => setCurrentTab(2)}
                        className={`${
                            currentTab === 2
                                ? "border-b text-[#001C89] border-primary-500"
                                : "text-[#C2C2C2]"
                        } pb-3 w-1/2 border-0`}
                    >
                        Reviews
                    </button>
                </div>
            </div>
            <div className="pt-9 pb-24">
                {currentTab === 1 && (
                    <OverviewTab
                        business={biz}
                        isSameBusiness={isSameBusiness}
                        reviewsAndRatings={businessReviewsAndAverageRatings}
                        highlight={highlight}
                    />
                )}
                {currentTab === 2 && (
                    <ReviewTab
                        business={biz}
                        reviewsAndRatings={businessReviewsAndAverageRatings}
                        highlight={highlight}
                    />
                )}
            </div>

            <FooterComponent />
        </div>
    );
}
