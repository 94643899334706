import {
    Card,
    CardHeader,
    CardBody,
    Divider,
    CardFooter,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AiOutlineHeart, AiOutlineDislike } from "react-icons/ai";
import { BiLike } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import style from "../../styles/homePage.module.scss";
import Wallpaper from "assets/svg/wallpaper.svg";
import {
    IBusinessReview,
    useLikeBusinessReviewMutation,
    useUnlikeBusinessReviewMutation,
} from "store/review";
import { getNameInitials } from "utilities/general";
import { FaRegComment } from "react-icons/fa6";
import { usePageNotificationProvider } from "providers/index";
import { useGetCommentsByReviewIdQuery } from "store/comments";
import Rating from "react-rating-fix";
import { HiOutlineStar, HiStar } from "react-icons/hi2";
import { HashLink } from "react-router-hash-link";

const ModifiedRating = Rating as any;

type Props = {
    review: IBusinessReview;
    refetch: () => void;
};

export const HomePageReviewCard = ({ review, refetch }: Props) => {
    const [showFullText, setShowFullText] = useState(false);
    const [placeholderImg, setPlaceholderImg] = useState("");
    const [likeBusinessReview] = useLikeBusinessReviewMutation();
    const [unlikeBusinessReview] = useUnlikeBusinessReviewMutation();
    const { data: reviewComments, refetch: refetchReviewComments } =
        useGetCommentsByReviewIdQuery({
            id: review?.id,
        });
    const { initNotification } = usePageNotificationProvider();

    const longText = review?.comment;

    const toggleShowFullText = () => {
        setShowFullText(!showFullText);
    };

    const truncatedText = longText.slice(0, 230);
    const lastVisibleWordIndex = truncatedText.lastIndexOf(" ");
    const visibleText = showFullText
        ? longText
        : truncatedText + (longText.length > 230 ? "..." : "");

    const handleToggleLikeBusinessReview = (type: string) => {
        type === "like"
            ? likeBusinessReview(review?.id)
                  .unwrap()
                  .then((res: any) => {
                      if (res.error) return;
                      initNotification({
                          scheme: "success",
                          message: res.message,
                      });
                      refetch();
                      return;
                  })
                  .catch((err: any) =>
                      initNotification({
                          scheme: "error",
                          message: err?.data?.status.includes("4")
                              ? "You need to login to smake this action"
                              : err?.data?.message,
                      })
                  )
            : unlikeBusinessReview(review?.id)
                  .unwrap()
                  .then((res: any) => {
                      if (res.error) return;
                      initNotification({
                          scheme: "success",
                          message: res.message,
                      });
                      refetch();
                      return;
                  })
                  .catch((err: any) =>
                      initNotification({
                          scheme: "error",
                          message: err?.data?.status.includes("4")
                              ? "You need to login to smake this action"
                              : err?.data?.message,
                      })
                  );
    };

    const handleImgError = (e: { currentTarget: { src: string } }) => {
        e.currentTarget.src =
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUgAAACaCAMAAAD8SyGRAAAAJ1BMVEX19/va5/Py9frh6/Xo7/fc6PPv8/ni7PXr8fju8vnl7vbq8Pjh7PWZVkH/AAADWklEQVR4nO3d4XLiMAxG0SohQLP7/s+7kC6dQmxLdjxVIu75zXQ84rNiOwn9+AAAAAAAAAAAAAAAAAAAAAAAAOhq8B7A8Z1P4zSJTNM4n73HclzDfKvhDyO1bDLKysgkr3Zdl/Fu9h7X0VzSdby1S++RHcuUq+MN09uuVEcR79EdR+Iyw+xucCrXUeTiPcJjGLQ6ily9x3gIysRmchud9ToSSQtDIImkhaWOImy7NZmt4Ssu3BrTzGZu62x1ZKOoMSwiv3x6j3TnTIufOxZAZeZCnrxHunMUshMK2Yn5YkOPVFgLydZGYS2k9zh3j51NJ39shWSvrbIVkhap4jyyE07Ie+GeTS96IemQJuohOU9SGWWfoPoyeo/vOIptkgZZoZDJiZsMNWbmdSfn9KN9LCDrJR5KuzCtm1yfUjmdKGO76zxON+PlShW/DWd2JD0si0POEbd63NViGbjR93WDjckmP9YyLKk3eLpdzb39ds87Pi7ezV52KN7DOazXIwjaZKPVppkz7iaJMzHaZIt1HXkGvEXykJZ1ebXMI4+0yVq5uwYcdlfK1JE2WSl7G4s2WaX0UDiHkxXygaRN1lDeUqBNWimP8dAmrcp15PjCqtghF5zymqh15PjCROmQX7jgqGwvFtImVXqHXHB8oTC/6UqbLDN1yAVtssQcSNblZfZAcnxRUhFI4fiioCaQQpvMqgskbTKrMpC0yYzaQAptMq06kEKbTGkIJG0ypSWQHF+sNQVSDKe8w2kcxzd6caktkKIeXzxeP36XK7z5l+LWSmEbjJ+L4297IQtt8unreYvXdTYEsnDK+9J33+HC1NwhF5k2ubp+xV+/t16yH6x/NPzkNv7eXlZqXZ76cqKv3zd1yMW6TaZDHvzZgq2BlHX7yzWL0JN7eyDXFcp9LPTk7hDI1wrlPxd4h7P1kv2o5GD7i3HviHcJ5N3/k4lBeVojapvsFMjFOJ9m9XuJusHpFkizmBucnoG0Cjm5fz+QMddAHoEMucHxCKQEXAN12dQ0CDe5nQIZboPjFUiJ9p/U3AIpsdZAjoGMtcHxDGSkDY5rICXQ5PYNZJzJ7R3IMBuc9K+G/6oQk9s/kEE2ODsIZIgfU/z0ruHCuwod7CKQAQq5hw4pEQq5j0DKP3LpHeFfdn3jAAAAAElFTkSuQmCC";
    };
    return (
        <HashLink
            to={`/businesses/${review?.businessID}#${
                review?.id + review?.business?.businessName
            }`}
            className={style.cardOne}
        >
            <Card>
                <CardHeader className="pb-0">
                    <div className={style.cardHeader}>
                        <div className={style.cardHeaderImage}>
                            <h2>
                                {getNameInitials(
                                    review?.user?.firstName || "xx"
                                )}
                            </h2>
                        </div>
                        <div className={style.cardHeaderName}>
                            <h3>{review?.user.firstName}</h3>
                            <p>Wrote a review</p>
                        </div>
                    </div>
                </CardHeader>

                <CardBody>
                    <div className={style.cardBodyImage}>
                        <img
                            alt=""
                            src={
                                review?.business.businessBanner ||
                                review?.business.businessLogo
                            }
                            className="w-full h-full object-cover"
                            onError={handleImgError}
                        />
                    </div>
                    <div className={style.cardBodyText}>
                        <div className={style.companyAndRating}>
                            <div className={style.companyName}>
                                <h1>{review?.business.businessName}</h1>
                            </div>
                            <div className="text-gray-600 [&_span]:mt-0 flex justify-between gap-2 !ml-0">
                                <ModifiedRating
                                    // placeholderRating={3.5}
                                    readonly
                                    initialRating={review?.rating}
                                    emptySymbol={
                                        <HiOutlineStar className="h-5 w-5 text-[#FFC554]" />
                                    }
                                    fullSymbol={
                                        <HiStar className="h-5 w-5 text-[#FFA800]" />
                                    }
                                    fractions={2}
                                    start={0}
                                    stop={5}
                                    step={5}
                                />
                                <p className="text-[#919191] text-base font-semibold">
                                    {review?.rating}
                                </p>
                            </div>
                        </div>
                        {/* <p>{visibleText}</p> */}
                        <div
                            className="mt-1 mb-4 text-[#606060]"
                            dangerouslySetInnerHTML={{ __html: visibleText }}
                        />
                        {longText.length > 230 && (
                            <span onClick={toggleShowFullText}>
                                {showFullText ? "Read Less" : "Read More"}
                            </span>
                        )}
                    </div>
                </CardBody>
                <Divider className={style.divider} />
                <CardFooter className={`${style.reviewButton}`}>
                    {/* <Link to={""}>
                        <AiOutlineHeart
                            style={{
                                fontSize: "25px",
                            }}
                        />
                    </Link> */}

                    <button
                        onClick={() => handleToggleLikeBusinessReview("like")}
                        type="button"
                        className="flex items-center space-x-1 text-gray-600 border-0 overflow-visible !font-normal"
                    >
                        <sup>
                            <BiLike
                                style={{
                                    fontSize: "25px",
                                }}
                            />
                        </sup>
                        <sub>{review?.LikeCount}</sub>
                    </button>
                    <button
                        onClick={() => handleToggleLikeBusinessReview("unlike")}
                        type="button"
                        className="flex items-center space-x-1 text-gray-600 border-0 overflow-visible !font-normal"
                    >
                        <sup>
                            <AiOutlineDislike
                                style={{
                                    fontSize: "25px",
                                }}
                            />
                        </sup>
                        <sub>{review?.UnlikeCount}</sub>
                    </button>
                    <span className="flex items-center space-x-1 text-gray-600 border-0 overflow-visible !font-normal">
                        <sup>
                            <FaRegComment className="h-5 w-5" />
                        </sup>
                        <sub>{reviewComments?.data?.length}</sub>
                    </span>
                </CardFooter>
            </Card>
        </HashLink>
    );
};
