import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../utilities/axiosQuery/axiosBaseQuery";
import { baseUrl } from "../../utilities/requests";
import { IResponse } from "../auth/interface";
import { PaginatedPayload } from "store/interface";
import {
    GetBusinessReviewsPayload,
    IBusinessReviewRes,
    ReviewFormData,
} from "./interface";
import { IReviewRes } from "pages/auth/business/business-Interface";
import { convertObjectToURLParams } from "utilities/general";

export const reviewApi = createApi({
    reducerPath: "reviewApi",
    baseQuery: axiosBaseQuery({ baseUrl: `${baseUrl}/api/` }),

    endpoints: (builder) => ({
        postBusinessReview: builder.mutation<IResponse, ReviewFormData>({
            query: (credentials) => ({
                url: `business-reviews/${credentials.business}/reviews`,
                method: "POST",
                body: credentials,
            }),
        }),

        editBusinessReview: builder.mutation<
            IResponse,
            { review: ReviewFormData; reviewid: string | number }
        >({
            query: ({ review, reviewid }) => ({
                url: `business-reviews/${reviewid}`,
                method: "PUT",
                body: review,
            }),
        }),

        deleteBusinessReview: builder.mutation<IResponse, string>({
            query: (id) => ({
                url: `business-reviews/${id}`,
                method: "DELETE",
            }),
        }),

        getBusinessReviews: builder.query<
            IBusinessReviewRes,
            GetBusinessReviewsPayload
        >({
            query: ({ id, query }) => ({
                url: `business-reviews/${id}?${convertObjectToURLParams(
                    query
                )}`,
                method: "GET",
                headers: {
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                },
            }),
        }),

        getReviews: builder.query<
            IReviewRes,
            { type: "product" | "user"; id: string }
        >({
            query: ({ type, id }) => ({
                url: `reviews/${type}/${id}`,
                method: "GET",
                headers: {
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                },
            }),
        }),

        likeBusinessReview: builder.mutation<IResponse, number>({
            query: (reviewId) => ({
                url: `business-reviews/like-business-review/${reviewId}`,
                method: "POST",
                body: reviewId,
            }),
        }),

        unlikeBusinessReview: builder.mutation<IResponse, number>({
            query: (reviewId) => ({
                url: `business-reviews/unlike-business-review/${reviewId}`,
                method: "POST",
                body: reviewId,
            }),
        }),

        uploadBusinessReviewPhoto: builder.mutation<IResponse, FormData>({
            query: (files) => ({
                url: `business-reviews/upload`,
                method: "POST",
                headers: { "Content-Type": "multipart/form-data" },
                body: files,
            }),
        }),

        //! PRODUCTS
        getAllProducts: builder.query<IReviewRes, string>({
            query: (id) => ({
                url: `all-products?businessID=${id}`,
                method: "GET",
                headers: {
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                },
            }),
        }),
    }), // end --
});

export const {
    usePostBusinessReviewMutation,
    useEditBusinessReviewMutation,
    useDeleteBusinessReviewMutation,
    useGetBusinessReviewsQuery,
    useGetReviewsQuery,
    useGetAllProductsQuery,
    useLikeBusinessReviewMutation,
    useUnlikeBusinessReviewMutation,
    useUploadBusinessReviewPhotoMutation,
} = reviewApi;
