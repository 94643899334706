import { useState } from "react";
import style from "./accordion.module.scss";
import { BiChevronDown } from "react-icons/bi";

// ????? ===================== REUSABLE ACCORDION COMPONENT =================== ?????? //
// ????? ===================== REUSABLE ACCORDION COMPONENT =================== ?????? //
type AccordionComponentProps = {
    items: {
        title: string;
        content: JSX.Element;
    }[];
};

export const AccordionComponent = ({ items }: AccordionComponentProps) => {
    const [activeIndex, setActiveIndex] = useState<null | number>(null);

    const handleClick = (index: number) => {
        if (index === activeIndex) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    return (
        <div id="faq" className={style.accordion}>
            {items.map((item, index) => (
                <div className={style.accordionItem} key={index}>
                    <button
                        className={style.accordionButton}
                        onClick={() => handleClick(index)}
                    >
                        <p>{item.title}</p>
                        <p className={style.accordionIcon}>
                            <BiChevronDown />
                        </p>
                    </button>
                    {activeIndex === index && (
                        <div className={style.accordionContent}>
                            {item.content}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};
