import { ModalViewStateProps } from "providers";
import { LogoutAlertDialog } from "components";
import { SessionExpiryDialog } from "./sessionExpiryDialog";

export interface AuthDialogsProps {
  modal?: ModalViewStateProps;
  onInitModal?: React.Dispatch<
    React.SetStateAction<ModalViewStateProps | undefined>
  >;
}
export const AuthDialogs: React.FC<AuthDialogsProps> = ({
  modal,
  onInitModal = () => {},
}) => {
  return (
    <>
      <LogoutAlertDialog
        isOpen={Boolean(modal?.logout)}
        onClose={() => onInitModal((prev) => ({ ...prev, logout: false }))}
      />
      <SessionExpiryDialog
        isOpen={Boolean(modal?.sessionExpired)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, sessionExpired: false }))
        }
      />
    </>
  );
};
