import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../utilities/axiosQuery/axiosBaseQuery";
import { baseUrl } from "../../utilities/requests";
import {
  RegisterResponse,
  ForgotPayload,
  IResponse,
  LoginPayload,
  RegisterPayload,
  ResendEmailPayload,
  ResetPayload,
  VerifyEmailPayload,
  FirebaseUserPayload,
  CreateProfilePayload,
} from "./interface";
import { ProfileInformationResponse } from "store/profile";

// const url = "https://identitytoolkit.googleapis.com/v1/accounts:";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: axiosBaseQuery({ baseUrl: `${baseUrl}` }),
  endpoints: (builder) => ({
    login: builder.mutation<any, LoginPayload>({
      query: (credentials) => ({
        url: `signInWithPassword?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
        method: "POST",
        body: credentials,
      }),
    }),
    register: builder.mutation<RegisterResponse, RegisterPayload>({
      query: (credentials) => ({
        url: `signUp?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
        method: "POST",
        body: credentials,
      }),
    }),
    createUser: builder.mutation<
      ProfileInformationResponse,
      CreateProfilePayload
    >({
      query: (credentials) => ({
        url: "/auth/create-user",
        method: "POST",
        body: credentials,
      }),
    }),
    getFirebaseUser: builder.query<IResponse, FirebaseUserPayload>({
      query: (credentials) => ({
        url: `lookup?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
        method: "GET",
        body: credentials,
      }),
    }),
    forgot: builder.mutation<IResponse, ForgotPayload>({
      query: (payload) => ({
        url: `forgot-password/${payload.email}`,
        method: "POST",
        body: payload,
      }),
    }),
    reset: builder.mutation<IResponse, ResetPayload>({
      query: ({ newPassword, oobCode, email }) => ({
        url: `reset-password/${email}`,
        method: "POST",
        body: { newPassword, oobCode },
      }),
    }),
    sendVerificationEmail: builder.mutation<IResponse, ResendEmailPayload>({
      query: (payload) => ({
        url: "send-email-verification",
        method: "POST",
        body: payload,
      }),
    }),
    resendVerificationEmail: builder.mutation<IResponse, ResendEmailPayload>({
      query: (payload) => ({
        url: `resend-verification?email=${payload.email}`,
        method: "POST",
        body: payload,
      }),
    }),
    verifyEmailAddress: builder.mutation<IResponse, VerifyEmailPayload>({
      query: (payload) => ({
        url: `verify-email?email=${payload.email}&code=${payload.code}`,
        method: "POST",
        body: payload,
      }),
    }),
    refreshToken: builder.mutation<IResponse, void>({
      query: (credentials) => ({
        url: "token/refresh",
        method: "GET",
        body: credentials,
      }),
    }),
    logOut: builder.mutation<IResponse, void>({
      query: (credentials) => ({
        url: "token/destroy",
        method: "GET",
        body: credentials,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useCreateUserMutation,
  useGetFirebaseUserQuery,
  useForgotMutation,
  useResetMutation,
  useSendVerificationEmailMutation,
  useResendVerificationEmailMutation,
  useVerifyEmailAddressMutation,
  useLogOutMutation,
} = authApi;
