import React, { useEffect } from "react";
import SectionTitle from "./SectionTitle";
import style from "../../../styles/dashboard.module.scss";
import { BiEdit } from "react-icons/bi";
import { useAuth } from "store/auth";
import {
  useGetMyBusinessQuery,
  useUpdateBusinessMutation,
} from "store/business";
import { AuthHeaderText } from "components/custom";
import { PrimaryButton } from "components/index";
import { PrimaryInput } from "components/inputs";
import { useFormik } from "formik";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";

export const BusinessDescription = () => {
  const { user } = useAuth();
  const [showEditModal, setShowEditModal] = React.useState(false);
  const { data: businessInfo, refetch } = useGetMyBusinessQuery(user?.id || "");
  const { about = "--" } = businessInfo?.data?.[0] || {};

  return (
    <>
      <div className={style.location_section}>
        <div className={style.info_section_header}>
          <SectionTitle title="Business Description (About Business)" />
          <button
            onClick={() => setShowEditModal(true)}
            className={style.edit_btn}
          >
            <BiEdit />
            Edit
          </button>
        </div>
        <div className={style.business_d_cont}>
          <p>{about}</p>
        </div>
      </div>
      {showEditModal ? (
        <EditModal
          show={showEditModal}
          businessInfo={businessInfo?.data?.[0] || {}}
          onClose={() => setShowEditModal(false)}
          refetch={refetch}
        />
      ) : null}
    </>
  );
};

type TEditModal = {
  show: boolean;
  businessInfo: any;
  onClose: () => void;
  refetch: any;
};
function EditModal({ onClose, businessInfo, refetch }: TEditModal) {
  const initialValues = {
    about: "",
  };
  const { initNotification } = usePageNotificationProvider();
  const [updateBusiness] = useUpdateBusinessMutation();
  const {
    values,
    setValues,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: null,
    onSubmit: (e) => initRequest(e),
  });

  const initRequest = (payload: any) => {
    Object.keys(payload).forEach((key) => {
      if (!Object.keys(initialValues).includes(key)) {
        delete payload[key];
      }
    });

    updateBusiness({ businessData: payload, id: businessInfo?.id })
      .unwrap()
      .then((res: any) => {
        initNotification({
          scheme: "success",
          message: res.message,
        });
        refetch();
      })
      .catch((err: any) =>
        initNotification({
          scheme: "error",
          message: err?.data?.message || err.error,
        })
      );
  };

  useEffect(() => {
    setValues({
      ...businessInfo,
    });
  }, [businessInfo, setValues]);

  return (
    <div
      onClick={onClose}
      className="fixed inset-0 bg-black/50 flex flex-col item-center justify-center overflow-hidden z-[4]"
    >
      <div className="h-full overflow-y-scroll py-20 pt-36">
        <div
          onClick={(e) => e.stopPropagation()}
          className="bg-white min-h-full md:min-h-96 w-full max-w-2xl md:px-8 py-10 px-4 rounded-lg m-auto"
        >
          <AuthHeaderText
            title="Edit your business description"
            headingClassName="text-black text-3xl font-medium mb-5"
          />
          <br />
          <form onSubmit={handleSubmit} className="">
            <div className="mt-3 d-flex flex-column w-100 gap-4">
              <div className="my-5">
                <PrimaryInput
                  type="text"
                  label="Business Description"
                  name="about"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.about}
                  error={Boolean(errors?.about && touched?.about)}
                  bottomText={errors?.about || ""}
                />
              </div>
            </div>
            <div className="flex items-center gap-4 mt-10">
              <PrimaryButton
                type="submit"
                className="btn-lg primary-btn mt-2 align-self-baseline"
              >
                Submit
              </PrimaryButton>
              <PrimaryButton
                type="reset"
                className="btn-lg secondary-btn mt-2 align-self-baseline"
                onClick={onClose}
              >
                Cancel
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
