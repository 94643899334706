import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import React from "react";
import { useLogout } from "store/auth/hooks";
import { ReactComponent as LogoutIcon } from "assets/svg/logout.svg";
import { MdLogout } from "react-icons/md";

interface LogoutAlertDialogProps {
  isOpen?: boolean;
  onClose?: () => void;
}
export const LogoutAlertDialog: React.FC<LogoutAlertDialogProps> = ({
  isOpen = false,
  onClose = () => {},
}) => {
  const logout = useLogout();
  const cancelRef = React.useRef<any>();

  const initLogout = () => {
    logout();
    onClose();
    setTimeout(() => {
      window.location.replace("/login");
    }, 100);
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent className="text-center d-flex align-items-center justify-content-center p-4 br-12">
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
              borderTopRadius={5}
              borderBottom={0}
              paddingTop={0}
              justifyContent={"center"}
            >
              <div className="w-[45px] h-[45px] bg-red-100 rounded-full flex items-center justify-center">
                {/* <LogoutIcon className="w-20-px h-auto" /> */}
                <MdLogout className="text-red-600" />
              </div>
            </AlertDialogHeader>

            <AlertDialogBody className="text-grey-500 pt-0">
              <h4 className="text-[#383838] font-semibold text-2xl mb-3">
                Log Out
              </h4>
              <p className="mb-0 text-[#7e7e7e]" style={{ lineHeight: "20px" }}>
                You are about to log out from your current session you will need
                to sign in again to access your dashboard.
              </p>
            </AlertDialogBody>

            <AlertDialogFooter className="pb-0 px-0 w-100 justify-between">
              <Button
                ref={cancelRef}
                onClick={onClose}
                className="w-full secondary-btn btn-md"
              >
                Cancel
              </Button>
              <Button
                borderWidth={3}
                onClick={initLogout}
                ml={3}
                className="w-full primary-error-btn btn-md"
              >
                Continue
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
