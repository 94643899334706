import { AccordionComponent } from "components/accordion/accordion";

const businessFaq = [
    {
        title: "How are reviews verified on TradeTracka?",
        content: (
            <div>
                We use advanced AI technology to verify reviews to ensure
                authenticity and reliability.
            </div>
        ),
    },

    {
        title: "How can businesses manage their profiles?",
        content: (
            <div>
                Businesses can manage their profiles by logging into their
                accounts where they can update their information, respond to
                reviews, and access analytics.
            </div>
        ),
    },

    {
        title: "How does TradeTracka handle disputes between consumers and businesses?",
        content: (
            <div>
                We provide a platform for both parties to communicate and
                resolve disputes. If necessary, our support team can assist in
                mediating the situation.
            </div>
        ),
    },
];

const FaqBusiness = () => {
    return (
        <div className="flex items-center justify-center md:pt-24 pt-10 flex-col pb-20 w-full">
            <h3 className="text-center text-4xl mb-10 font-semibold">
                Frequently asked questions business
            </h3>

            <div className="w-full max-w-4xl">
                <AccordionComponent items={businessFaq} />
            </div>
        </div>
    );
};

export default FaqBusiness;
