import { ModalViewStateProps } from "providers";
import { LogoutAlertDialog } from "components";
import { SessionExpiryDialog } from "./sessionExpiryDialog";
import {
  AskTheCommunityModal,
  BusinessHoursPreviewModal,
  BusinessPhotosPreviewModal,
  SuggestEditModal,
} from "pages";

export interface DashboardDialogsProps {
  modal?: ModalViewStateProps;
  onInitModal?: React.Dispatch<
    React.SetStateAction<ModalViewStateProps | undefined>
  >;
}
export const DashboardDialogs: React.FC<DashboardDialogsProps> = ({
  modal,
  onInitModal = () => {},
}) => {
  return (
    <>
      <BusinessPhotosPreviewModal
        isOpen={Boolean(modal?.businessPhotos)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, businessPhotos: false }))
        }
      />

      <BusinessHoursPreviewModal
        isOpen={Boolean(modal?.businessHours)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, businessHours: false }))
        }
      />

      <SuggestEditModal
        isOpen={Boolean(modal?.suggestEdit)}
        onClose={() => onInitModal((prev) => ({ ...prev, suggestEdit: false }))}
      />

      <AskTheCommunityModal
        isOpen={Boolean(modal?.askTheCommunity)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, askTheCommunity: false }))
        }
      />
    </>
  );
};
