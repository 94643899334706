import React from "react";
import { PrimaryButton } from "components/index";
import { Link } from "react-router-dom";

const RedefineYourShopping = () => {
    return (
        <>
            {/*//******* Section Five *************/}
            <div className="pb-10">
                <div className="flex flex-col md:flex-row md:items-center justify-around bg-primary-500 py-8 px-5 rounded-3xl min-h-96">
                    <p className="md:text-5xl text-3xl text-white max-w-2xl font-medium md:leading-relaxed leading-normal">
                        We're here to redefine
                        <br />
                        your shopping
                        <br />
                        experience.
                    </p>

                    <PrimaryButton className="btn-lg secondary-btn !py-0 md:w-auto w-fit">
                        <Link to="/sign-up/individual">Add Your Business</Link>
                    </PrimaryButton>
                </div>
            </div>
        </>
    );
};

export default RedefineYourShopping;
