import { AuthHeaderText, CustomPasswordInput } from "components/custom";
import { PrimaryButton } from "components/index";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "assets/svg/tradetracker-logo.svg";
import { useFormik } from "formik";
import { ResetPasswordValidationSchema } from "validations/auth";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { useResetMutation } from "store/auth";
import queryString from "query-string";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { auth } from "firebaseConfig/config";
import { mapAuthCodeToMessage } from "utilities/general";

export const ResetPassword = () => {
  const navigate = useNavigate();
  const { oobCode } = queryString.parse(window.location.search) as {
    email: string;
    oobCode: string;
  };
  const { initNotification } = usePageNotificationProvider();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { isLoading }] = useResetMutation();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        newPassword: "",
        confirmPassword: "",
      },
      validationSchema: ResetPasswordValidationSchema,
      onSubmit: (e) => initRequest(e),
    });

  const initRequest = (payload: {
    newPassword: string;
    confirmPassword: string;
  }) => {
    if (payload.newPassword !== "" || payload.confirmPassword !== "") {
      if (payload.newPassword === payload.confirmPassword) {
        // Verify the password reset code is valid.
        verifyPasswordResetCode(auth, oobCode)
          .then(() => {
            confirmPasswordReset(auth, oobCode, payload.newPassword)
              .then(() => {
                initNotification({
                  message: "Password reset successfully",
                  scheme: "success",
                });
                navigate("/login");
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error.code);
            initNotification({
              message: mapAuthCodeToMessage(error.code),
              scheme: "error",
            });
          });
      } else {
        initNotification({
          message: "Password not the same",
          scheme: "error",
        });
      }
    } else {
      return initNotification({
        message: "One or more inputs empty.",
        scheme: "error",
      });
    }
    // request({
    //     oobCode: oobCode,
    //     newPassword: values?.newPassword,
    //     email: email,
    // })
    //     .unwrap()
    //     .then((res: any) => {
    //         if (res.error) {
    //             console.log(res);

    //             initNotification({
    //                 message: res.message,
    //                 scheme: "error",
    //             });
    //             return;
    //         }
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //         initNotification({
    //             message: resolveApiError(error),
    //             scheme: "error",
    //         });
    //     });
  };

  return (
    <div className="flex flex-col bg-[#F0F0F0] justify-center items-center gap-8 auth min-h-[100vh] py-10">
      <Link to="/" className="font-extrabold text-2xl text-gray-500">
        <Logo />
      </Link>
      <div className="auth-form-wrapper bg-white py-10 px-8 rounded-2xl max-w-[450px] w-[90%] mx-auto">
        <AuthHeaderText
          title="Reset password"
          subTitle="Enter your email address and we will send you instructions for  to reset your password"
          headingClassName="text-black text-3xl font-medium text-center mb-2"
          subheadingClassName="text-[#919191] text-base font-normal text-center mb-10"
        />

        <form onSubmit={handleSubmit} className="">
          <div className="mt-3 d-flex flex-column w-100 gap-4">
            <div className="my-5">
              <CustomPasswordInput
                label="Your password"
                name="newPassword"
                placeholder="********"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.newPassword}
                error={Boolean(touched.newPassword && errors?.newPassword)}
                bottomText={errors?.newPassword}
              />
            </div>
            <div className="my-5">
              <CustomPasswordInput
                label="Confirm password"
                name="confirmPassword"
                placeholder="********"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmPassword}
                error={Boolean(
                  touched.confirmPassword && errors?.confirmPassword
                )}
                bottomText={errors?.confirmPassword}
              />
            </div>
          </div>

          <PrimaryButton
            type="submit"
            className="btn-lg primary-btn w-full mt-8 align-self-baseline"
            isLoading={isLoading}
          >
            Reset
          </PrimaryButton>
        </form>
      </div>
    </div>
  );
};
