import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/slice";
import { authApi } from "./auth/api";
import businessFalseReducer from "./business-false/slice";
import { profileApi } from "./profile";
import { campaignsApi } from "./campaigns";
import { notificationsApi } from "./notifications";
import { commentApi } from "./comments";
import { notificationSettingsApi } from "./notificationSettings";
import { activityApi } from "./activityLogs";
import { businessApi } from "./business";
import { reviewApi } from "./review";


export const store = configureStore({
    reducer: {
        [authApi.reducerPath]: authApi.reducer,
        [profileApi.reducerPath]: profileApi.reducer,
        [campaignsApi.reducerPath]: campaignsApi.reducer,
        [notificationsApi.reducerPath]: notificationsApi.reducer,
        [commentApi.reducerPath]: commentApi.reducer,
        [notificationSettingsApi.reducerPath]: notificationSettingsApi.reducer,
        [activityApi.reducerPath]: activityApi.reducer,
        [businessApi.reducerPath]: businessApi.reducer,
        [reviewApi.reducerPath]: reviewApi.reducer,
        auth: authReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }).concat(
            authApi.middleware,
            profileApi.middleware,
            campaignsApi.middleware,
            notificationsApi.middleware,
            commentApi.middleware,
            notificationSettingsApi.middleware,
            activityApi.middleware,
            businessApi.middleware,
            reviewApi.middleware,
        ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
