import React from "react";
import { useNavigate } from "react-router-dom";
import style from "../../styles/dashboard.module.scss";
import { DashboardHeader } from "./DashboardHeader";
import { DashboardFooter } from "./DashboardFooter";
import { BiChevronLeft } from "react-icons/bi";

interface Question {
    id: number;
    question: string;
    answer?: string;
    author: string;
    date: string;
}

const questions: Question[] = [
    // Sample question data, replace with your data fetching logic
    // {
    //     id: 1,
    //     question:
    //         "How do I use the cream in the customer’s package I got from Tastebud Cafe?",
    //     answer: "Mix with water and add sugar to demystify the content.",
    //     author: "Sabrina Jones",
    //     date: "12/06/2024",
    // },
];
export const QuestionsPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className={` container ${style.questions_page}`}>
                <span
                    className={`${style.back_btn} flex items-center mb-3`}
                    onClick={() => navigate(-1)}
                >
                    <BiChevronLeft /> back
                </span>

                <h1 className={style.question_title}>Threads</h1>
                <p className={style.question_subtitle}>
                    Threads from community
                </p>
                <div className={style.questions_container}>
                    {questions.length === 0 ? (
                        <div className={style.no_questions}>
                            You have no threads
                        </div>
                    ) : (
                        questions.map((q) => (
                            <div key={q.id} className={style.question_card}>
                                <div className={style.question_header}>
                                    <p className={style.question_text}>
                                        Q: {q.question}
                                    </p>
                                    <p className={style.question_author}>
                                        {q.author} • {q.date}
                                    </p>
                                </div>
                                {q.answer ? (
                                    <div className={style.answer_section}>
                                        <p className={style.answer_text}>
                                            A: {q.answer}
                                        </p>
                                        <p className={style.answer_author}>
                                            {q.author} • {q.date}
                                        </p>
                                    </div>
                                ) : (
                                    <div className={style.no_answer}>
                                        The Community has not replied yet
                                    </div>
                                )}
                                <div className={style.questions_action_buttons}>
                                    <button className={style.answer_button}>
                                        Answer Question
                                    </button>
                                    <button
                                        className={style.mark_accurate_button}
                                    >
                                        Mark Answer As Accurate
                                    </button>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
            <DashboardFooter />
        </>
    );
};
