import React, { useEffect, useState } from "react";
// import { ReactComponent as MailIcon } from "assets/svg/mail-icon.svg";
import queryString from "query-string";
import { usePageNotificationProvider } from "providers";
import { ReactComponent as CloseIcon } from "assets/svg/close.svg";
import verifiedIcon from "assets/png/verified-blue-icon.png";

import {
  AuthHeaderText,
  CustomPasswordInput,
  PrimaryButton,
  PrimaryCheckBox,
  PrimaryInput,
} from "components/index";
import { auth } from "firebaseConfig/config";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  sendEmailVerification,
  applyActionCode,
  checkActionCode,
  onAuthStateChanged,
  getIdToken,
  UserCredential,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useDispatch } from "react-redux";
import {
  LoginPayload,
  setCredential,
  useAuth,
  useLoginMutation,
} from "store/auth";
import { useFormik } from "formik";
import { LoginValidationSchema } from "validations/auth";
import { Spinner } from "@chakra-ui/react";
import { mapAuthCodeToMessage } from "utilities/general";
import { useGetProfileInformationQuery } from "store/profile";

export const VerifyEmailPage = () => {
  const { user: storedUser } = useAuth();
  const { email } = queryString.parse(window.location.search) as {
    email: string;
  };

  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const { initNotification } = usePageNotificationProvider();
  // const [request, { isLoading }] = useResendVerificationEmailMutation();
  const { data: profileInfo } = useGetProfileInformationQuery();
  // const profile = profileInfo?.data;

  useEffect(() => {
    const checkEmailVerification = async () => {
      const user = auth.currentUser;
      if (user) {
        await user.reload(); // Refresh user data to get latest email verification status
        setIsEmailVerified(user.emailVerified);

        if (user.emailVerified) {
          onAuthStateChanged(auth, async (user) => {
            if (user) {
              const token = await getIdToken(user);
              dispatch(
                setCredential({
                  user: profileInfo?.data,
                  access_token: token,
                })
              );
            }
          });
        }
      }
    };

    const interval = setInterval(checkEmailVerification, 3000); // Check every 3 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [dispatch, auth]);

  const initResendVerificationEmail = async () => {
    setIsLoading(true);
    const user = auth.currentUser;

    if (user) {
      try {
        await sendEmailVerification(user);
        setIsLoading(false);
        initNotification({
          message:
            "Verification email sent successfully. Please check your inbox.",
          scheme: "success",
        });
      } catch (err) {
        setIsLoading(false);
        initNotification({
          message: "Error sending verification email: " + err,
          scheme: "error",
        });
      }
    } else {
      setIsLoading(false);
      initNotification({
        message: "No user is currently signed in.",
        scheme: "warning",
      });
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const oobCode = query.get("oobCode");

    if (oobCode) {
      checkActionCode(auth, oobCode)
        .then(() => {
          return applyActionCode(auth, oobCode);
        })
        .then(() => {
          setMessage("Email verified successfully!");
          initNotification({
            message: "Email verified successfully!",
            scheme: "success",
          });
        })
        .catch((error) => {
          console.error("Error verifying email: ", error);
          setMessage("Error verifying email.");
        });
    }
  }, [location, auth]);

  setTimeout(() => {
    setLoading(false);
  }, 3000);

  if (loading) {
    return (
      <div className=" min-h-[100vh] flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  //   setTimeout(() => {
  if (!auth.currentUser) {
    return <LoginPopUp />;
  }
  //}, 3000);

  return (
    <div
      style={{ minHeight: "100vh", paddingTop: "8%" }}
      className="flex flex-col items-center bg-grey-25 gap-4"
    >
      {isEmailVerified && <VerifiedPopUp />}
      <div className="border p-5 bg-grey-25 rounded-3 mt-5 flex flex-col items-center gap-4 text-center">
        {/* <MailIcon /> */}

        <h3 className="text-[#000000] font-semibold text-2xl">Verify email</h3>

        <p
          className=" text-[#6C6C6C] fs-14 lh-base"
          style={{ maxWidth: "400px" }}
        >
          We've sent a link to{" "}
          <span className="font font-semibold">{email}</span>
          . If you don’t see it, check your spam folder. <br /> <br /> If the
          email address you entered is wrong, kindly restart the registration
          process.
        </p>

        {/* <PrimaryButton
                    type="submit"
                    className="btn-lg primary-btn w-full mt-8 align-self-baseline"
                    isLoading={isLoading}
                    onClick={() => {
                        auth.currentUser?.reload();
                    }}
                >
                    Verified
                </PrimaryButton> */}

        <p className="mt-0 text-[#6C6C6C] fs-14 lh-base">
          <button
            className="text-primary-500 border-0 bg-transparent p-0 fw-500 underline"
            onClick={initResendVerificationEmail}
          >
            {isLoading ? "Sending..." : "Resend Mail"}
          </button>
        </p>
      </div>
    </div>
  );
};

const VerifiedPopUp = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/account-type");
    }, 2000);
  }, [navigate]);

  return (
    <div className="fixed bg-white inset-0 z-10 flex flex-col items-center">
      <Link
        to={"/account-type"}
        className="flex items-center justify-end w-full py-4 px-6"
      >
        <CloseIcon className="text-3xl w-8 h-8" />
      </Link>
      <div className="p-6 flex items-center flex-col justify-center gap-8 h-full max-h-96 w-full max-w-96 shadow my-auto">
        <img src={verifiedIcon} alt="verifiedIcon" />
        <p className="text-3xl font-semibold text-primary-500 text-center">
          Verification successful
        </p>
        <div className="text-center mt-4">
          <Spinner />
        </div>
      </div>
    </div>
  );
};

export default VerifiedPopUp;

const LoginPopUp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { isLoading }] = useLoginMutation();
  const { initNotification } = usePageNotificationProvider();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: LoginValidationSchema,
      onSubmit: (e) => initRequest(e),
    });
  const isUserEmailVerified = (res: UserCredential) => {
    if (res.user.emailVerified) {
      return true;
    }
    sendEmailVerification(res.user, {
      url: `${process.env.REACT_APP_BASE_URL}/verify-email?email=${res.user.email}`,
      handleCodeInApp: true,
    });
    navigate(`/verify-email?email=${res.user.email}`, {
      state: { from: location.pathname },
    });
    return false;
  };

  const initRequest = async (payload?: LoginPayload) => {
    if (payload?.email && payload?.password) {
      await signInWithEmailAndPassword(auth, payload?.email, payload?.password)
        .then(async (res) => {
          const user = await res.user;
          const verifiedUser = await isUserEmailVerified(res);
          const token = await res.user.getIdToken();
          if (verifiedUser) {
            dispatch(
              setCredential({
                user: user,
                access_token: token,
              })
            );

            navigate(`/account-type`);
          }
        })
        .catch((err) => {
          initNotification({
            message: mapAuthCodeToMessage(err.code),
            scheme: "error",
          });
        });
    }
    // request(payload ?? values)
    //     .unwrap()
    //     .then((res: any) => {
    //         if (res.error) {
    //             console.log(res);

    //             initNotification({
    //                 message: res.message,
    //                 scheme: "error",
    //             });
    //             return;
    //         }

    //         initNotification({
    //             message: "You have successfully logged in.",
    //         });
    //         dispatch(
    //             setCredential({
    //                 user: res.data.user,
    //                 access_token: res.data.token,
    //             })
    //         );
    //         if (location.state) {
    //             navigate(`${location.state.from}`);
    //         } else {
    //             navigate(`/home`);
    //         }
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //         initNotification({
    //             message: resolveApiError(error),
    //             scheme: "error",
    //         });
    //     });
  };
  return (
    <div className="flex flex-col bg-[#F0F0F0] justify-center items-center gap-8 auth min-h-[100vh] py-10">
      {/* <Link to="/" className="font-extrabold text-2xl text-gray-500">
                <Logo />
            </Link> */}
      <div className="auth-form-wrapper bg-white py-10 px-8 rounded-2xl max-w-[450px] w-[90%] mx-auto">
        <AuthHeaderText
          title="Welcome back"
          subTitle="Enter your details to continue"
          headingClassName="text-black text-3xl font-medium text-center mb-2"
          subheadingClassName="text-[#919191] text-base font-normal text-center mb-10"
        />
        <form onSubmit={handleSubmit} className="">
          <div className="mt-3 d-flex flex-column w-100 gap-4">
            <div className="my-5">
              <PrimaryInput
                type="email"
                label="Email Address"
                name="email"
                placeholder="example@mail.com"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={Boolean(errors?.email && touched?.email)}
                bottomText={errors?.email || ""}
              />
            </div>
            <div className="">
              <CustomPasswordInput
                label="Your password"
                name="password"
                placeholder="********"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={Boolean(touched.password && errors?.password)}
                bottomText={errors?.password}
              />
            </div>
          </div>
          <div className="flex items-center justify-between mt-4">
            <div>
              <PrimaryCheckBox size={"lg"} inlineText="Remember me" />
            </div>
            <Link to="/forgot-password" className="text-[#001C89]">
              Forgot password
            </Link>
          </div>
          <PrimaryButton
            type="submit"
            className="btn-lg primary-btn w-full mt-8 align-self-baseline"
            isLoading={isLoading}
          >
            Log In
          </PrimaryButton>
        </form>
        {/* <h4 className="divider">Or continue with</h4>
        <div className="flex items-center justify-center gap-5">
          <SocialAuthButton provider="google" />
          <SocialAuthButton provider="apple" />
          <SocialAuthButton provider="facebook" />
        </div> */}
      </div>
    </div>
  );
};
