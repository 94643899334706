/* eslint-disable react-hooks/exhaustive-deps */

import { useFormik } from "formik";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { useDispatch } from "react-redux";
import { useRegisterMutation } from "store/auth";
import { AuthHeaderText } from "components/custom";
import { PrimaryButton } from "components/index";
import {
    PrimaryInput,
    PrimaryMultiSelect,
    PrimaryPhone,
    PrimaryTextarea,
} from "components/inputs";
import { BusinessCategory, IBusinessUserInterface } from "./business-Interface";
import { reactLocalStorage } from "reactjs-localstorage";
import { useEffect } from "react";
import { useGetAllBusinessCategoryQuery } from "store/business";
import { array, object, string } from "yup";
import { SchedulerComponentForm } from "components/custom/scheduler";

interface Props {
    nextPage: (num: number) => void;
}

export const BusinessPrimaryInfoSchema = () => {
    return object({
        // category: array().required("Select at least 1 category"),
        address: string()
            .required("Business address is required")
            .matches(
                /^(?!\d+$)(?!.*@).*$/,
                "Address cannot be just numbers or an email"
            ),
        description: string().required("Description is required"),
        businessHours: array().required("Business Hours is required"),
    });
};

export const BusinessPrimaryInfo = ({ nextPage }: Props) => {
    const dispatch = useDispatch();
    const [request, { isLoading }] = useRegisterMutation();
    const { initNotification } = usePageNotificationProvider();
    const previousValues: IBusinessUserInterface = JSON.parse(
        reactLocalStorage.get("@business_info_payload") || "{}"
    );

    const { data: categories, loading } = useGetAllBusinessCategoryQuery();
    const businessCategory: BusinessCategory[] = categories?.data;

    const {
        values,
        setValues,
        setFieldValue,
        setFieldError,
        setFieldTouched,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useFormik({
        initialValues: {
            businessName: "",
            category: "",
            address: "",
            altPhoneNumber: "",
            description: "",
            businessHours: "",
        },
        validationSchema: BusinessPrimaryInfoSchema,
        onSubmit: (e) => initRequest(e),
    });

    useEffect(() => {
        console.log(Boolean(touched?.businessHours));

        if (previousValues) {
            setValues((prev: any) => {
                return {
                    ...prev,
                    businessName: previousValues?.business?.businessName,
                    category: previousValues?.business
                        ?.businessCategoryID as any,
                    address: previousValues?.business?.address,
                    description: previousValues?.business?.about,
                    altPhoneNumber: previousValues?.business?.phone,
                    businessHours: previousValues?.business?.businessHours,
                };
            });
        }
    }, []);

    const initRequest = (payload?: any) => {
        const updatedValues: IBusinessUserInterface = {
            ...previousValues,
            business: {
                ...previousValues?.business,
                businessName: payload.businessName,
                businessCategoryID: payload.category as any,
                address: payload.address,
                about: payload.description,
                businessHours: payload.businessHours,
            },
        };

        reactLocalStorage.set(
            "@business_info_payload",
            JSON.stringify(updatedValues)
        );

        nextPage && nextPage(2);
    };

    console.log(values);

    return (
        <>
            <AuthHeaderText
                title="Business profile"
                subTitle="Tell customers about your business."
                headingClassName="text-black text-3xl font-medium mb-5"
            />
            <form onSubmit={handleSubmit} className="">
                <div className="mt-3 d-flex flex-column w-100 gap-4">
                    <div className="my-5">
                        <PrimaryInput
                            type="text"
                            label="Business name"
                            name="businessName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.businessName}
                            error={Boolean(
                                errors?.businessName && touched?.businessName
                            )}
                            bottomText={errors?.businessName || ""}
                        />
                    </div>

                    <div className="my-5">
                        <PrimaryMultiSelect
                            name="category"
                            label="Category"
                            error={Boolean(
                                errors?.category && touched?.category
                            )}
                            isMulti={true}
                            options={businessCategory?.map((c) => ({
                                label: c.name,
                                value: c.id,
                            }))}
                            onMultiChange={(value) => {
                                setValues((prev: any) => {
                                    return {
                                        ...prev,
                                        category: value.map((val) => val.value),
                                    };
                                });
                            }}
                            value={businessCategory
                                ?.filter((c) =>
                                    values?.category?.includes(
                                        c.id as unknown as string
                                    )
                                )
                                .map((c) => ({
                                    label: c.name,
                                    value: c.id,
                                }))}
                        />
                    </div>

                    <div className="my-5">
                        <PrimaryInput
                            type="text"
                            label="Business address"
                            name="address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address}
                            error={Boolean(errors?.address && touched?.address)}
                            bottomText={errors?.address || ""}
                        />
                    </div>

                    <div className="my-5">
                        <PrimaryPhone
                            type="tel"
                            label="Alternative Phone number"
                            name="altPhoneNumber"
                            onBlur={handleBlur}
                            setValue={(e) =>
                                handleChange({ altPhoneNumber: e })
                            }
                            invalid={String(Boolean(errors?.altPhoneNumber))}
                            onChange={(v) =>
                                setValues((prev) => {
                                    return {
                                        ...prev,
                                        altPhoneNumber: v as string,
                                    };
                                })
                            }
                            value={values.altPhoneNumber}
                            error={Boolean(
                                errors?.altPhoneNumber &&
                                    touched?.altPhoneNumber
                            )}
                            bottomText={errors?.altPhoneNumber || ""}
                        />
                    </div>

                    <div className="my-5">
                        <PrimaryTextarea
                            label="Description"
                            name="description"
                            placeholder="Tell us about your business..."
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}
                            error={Boolean(
                                errors?.description && touched?.description
                            )}
                            bottomText={errors?.description || ""}
                            rows={2}
                        />
                    </div>

                    <div className="my-5">
                        <label className="font-medium">Business hours</label>
                        <SchedulerComponentForm
                            setScheduleDays={(val) => {
                                setFieldValue("businessHours", val);
                                if (val.length) {
                                    setFieldTouched("businessHours", true);
                                }
                            }}
                            scheduleDays={[]}
                        />
                        {touched.businessHours && errors.businessHours ? (
                            <p className="text-[#E53E3E] mt-2 text-sm">
                                {errors.businessHours}
                            </p>
                        ) : null}
                    </div>

                    {/* <div className="my-5">
                <PrimarySelect
                  options={[
                    { text: "Select" },
                    { text: "8:00am - 4:00pm" },
                    { text: "8:30am - 5:00pm" },
                    { text: "9:00am - 5:00pm" },
                    { text: "9:00am - 6:00pm" },
                    { text: "10:00am - 6:00pm" },
                    { text: "10:00am - 7:00pm" },
                    { text: "7:00am - 3:00pm" },
                    { text: "7:30am - 4:30pm" },
                    { text: "24 hours" },
                  ]}
                  label="Business hours"
                  name="businessHours"
                  className="primary-select py-4"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.businessHours}
                  error={Boolean(errors?.businessHours && touched?.businessHours)}
                  bottomText={errors?.businessHours}
                />
              </div> */}
                </div>
                <PrimaryButton
                    type="submit"
                    className="btn-lg primary-btn mt-2 align-self-baseline"
                    isLoading={isLoading}
                >
                    Continue
                </PrimaryButton>
            </form>
        </>
    );
};
