import { ChakraAlertDialog } from "components";
import React, { FC } from "react";
import { useLogout } from "store/auth";
import { ReactComponent as WarningIcon } from "assets/svg/modal-warning-icon.svg";

interface SessionExpiryDialogProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const SessionExpiryDialog: FC<SessionExpiryDialogProps> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const logout = useLogout();
  const initLogout = () => {
    logout();
    window.location.replace("/login");
    onClose();
  };
  return (
    <>
      <ChakraAlertDialog
        // isOpen={isOpen}
        useFooter={true}
        useCancelButton={false}
        useProceedButton={true}
        useHeader={false}
        proceedButtonDefaultChild={"Log In"}
        proceedButtonProps={{
          className: "btn-md primary-btn ms-0",
          onClick: initLogout,
        }}
        modalFooterProps={{
          className: "justify-content-center pb-0",
        }}
        title={"Session Expired"}
        size={"md"}
        closeOnOverlayClick={false}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <div className="">
          <div className="text-center d-flex flex-column align-items-center">
            <WarningIcon className="mb-3" />
            <h5 className="fw-600 text-grey-900 mb-3">Session Expired</h5>
            <p className="fw-400 text-grey-500 mb-2">
              Your session has expired. Please log in again to access your
              dashboard.
            </p>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
