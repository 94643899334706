import { FooterComponent, HeaderComponent } from "pages/landingPage";
import React from "react";
import style from "./../../styles/dashboard.module.scss";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { auth } from "firebaseConfig/config";
import { setCredential } from "store/auth";
import { toast } from "react-toastify";

export const BusinessAccountSettings = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = () => {
        auth.signOut();
        navigate("/login", { replace: true });
        // toast.success("You've been logged out");
        dispatch(
            setCredential({
                user: null,
                access_token: null,
            })
        );
        localStorage.clear();
        window.location.reload();
    };

    return (
        <>
            <div className={style.account_settings_container}>
                <h1 className={style.title}>Account Settings</h1>
                <div className={style.settings_list}>
                    <div
                        onClick={() => navigate("/business/change-password")}
                        className={style.settings_item}
                    >
                        <span>Change password</span>
                        <span className={style.arrow}>&#9662;</span>
                    </div>
                    <div
                        onClick={() => toast("Coming soon!")}
                        className={style.settings_item}
                    >
                        <span>Notification settings</span>
                        <span className={style.arrow}>&#9662;</span>
                    </div>
                    <div
                        onClick={handleLogout}
                        className={`${style.settings_item} ${style.logout} hover:bg-red-200`}
                    >
                        <span>Log out</span>
                        <span className={style.arrow}>&#9656;</span>
                    </div>
                </div>
            </div>
        </>
    );
};
