/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  ButtonProps,
  // ButtonGroup,
  // ButtonGroupProps,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
// import { IconType } from "react-icons";
// import { Icon } from "@chakra-ui/react";
// import { useWindowWidth } from "utilities";
// import { PrimaryButtonProps } from "components";
import { ReactComponent as GoogleLogo } from "assets/svg/google-logo.svg";
import { ReactComponent as AppleLogo } from "assets/svg/apple-logo.svg";
import { ReactComponent as FacebookLogo } from "assets/svg/facebook-icon.svg";
import { auth } from "firebaseConfig/config";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setCredential, useAuth } from "store/auth";
import {
  getIdToken,
  getRedirectResult,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";
import {
  useGetProfileInformationWithTokenQuery,
  useGetProfileInformationQuery,
  useGetProfileInformationAltMutation,
  ProfileInformationResponse,
} from "store/profile";
import { usePageNotificationProvider } from "providers/index";
import { resolveApiError } from "utilities/index";

export interface SocialAuthButtonProps extends ButtonProps {
  value?: string;
  provider?: "google" | "apple" | "facebook";
  className?: string;
  // setToken: (token: string) => void;
}
export const SocialAuthButton: React.FC<SocialAuthButtonProps> = ({
  value,
  children,
  provider = "google",
  className,
  // setToken,
  ...rest
}) => {
  const [clicked, setClicked] = useState<any>(false);
  const [isAppleLoading] = useState(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const { initNotification } = usePageNotificationProvider();
  // const { data: profileInfo, refetch } =
  //   useGetProfileInformationWithTokenQuery(token);
  const [getProfile, { isLoading: profileLoading }] =
    useGetProfileInformationAltMutation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();

  const roles = {
    member: "shopper",
    business: "business",
  };

  const googleProvider = new GoogleAuthProvider();

  const handleGoogleAuth = async () => {
    setIsGoogleLoading(true);
    try {
      const res = await signInWithPopup(auth, googleProvider);
      setIsGoogleLoading(false);
      const token = await res.user.getIdToken();

      getProfile(token)
        .unwrap()
        .then(async (res: ProfileInformationResponse) => {
          console.log(res);
          if (res?.error || (res?.status !== 200 && res?.status !== 201)) {
            initNotification({
              message:
                res?.message || "An error occured. Please try again later",
              scheme: "error",
            });
            return;
          }

          dispatch(
            setCredential({
              user: res?.data,
              access_token: token,
            })
          );
          initNotification({
            message: "You have successfully logged in.",
          });

          if (location.state) {
            navigate(`${location.state.from}`);
          } else {
            if (res?.data?.role) {
              if (res?.data?.role === "member") {
                navigate(`/businesses`);
              } else if (res?.data?.role === "businessOwner") {
                navigate(`/business/dashboard`);
              }
            }
            return;
          }
        })
        .catch((error) => {
          console.log(error);
          if (
            error?.data?.status === 401 ||
            error?.data?.message === "User not found"
          ) {
            dispatch(
              setCredential({
                user: user,
                access_token: token,
              })
            );
            navigate("/account-type");
            return;
          }
          initNotification({
            message: resolveApiError(error),
            scheme: "error",
          });
        });
    } catch (error: any) {
      setIsGoogleLoading(false);
      // toast.error(this.mapAuthCodeToMessage(error.code));
    }
  };

  useEffect(() => {
    setIsGoogleLoading(true);

    getRedirectResult(auth)
      .then((response) => {
        if (!response) return;
        return;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsGoogleLoading(false));

    // onAuthStateChanged(auth, async (user) => {
    //   if (user) {
    //     const token = await getIdToken(user);
    //     setToken(token);
    //   } else {
    //   }
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return (
    <Button
      type="button"
      isLoading={provider === "google" ? isGoogleLoading : isAppleLoading}
      loadingText=""
      className={`border border-[#C7C7C7] bg-transparent rounded-lg h-[48px] w-[48px] fw-normal ${className}`}
      isDisabled={
        provider !== "google"
          ? true
          : rest.disabled || isAppleLoading || isGoogleLoading
      }
      onClick={() => {
        setClicked(true);
        if (provider === "google") {
          handleGoogleAuth();
        } else {
          return;
        }
      }}
      {...rest}
    >
      {value}
      {children}
      {provider === "google" && (
        <>
          <GoogleLogo />
          {/* <span className="text-[#6C6C6C] text-base">Continue with Google</span> */}
        </>
      )}
      {provider === "apple" && (
        <>
          <AppleLogo />
          {/* <span className="text-[#6C6C6C] text-base">Continue with Apple</span> */}
        </>
      )}
      {provider === "facebook" && (
        <>
          <FacebookLogo />
          {/* <span className="text-[#6C6C6C] text-base">Continue with Apple</span> */}
        </>
      )}
    </Button>
  );
};
