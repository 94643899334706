import { Button, Input, Select, Stack, Textarea } from '@chakra-ui/react'
import { FormControl, FormLabel, FormErrorMessage, FormHelperText } from '@chakra-ui/react'
import { MdArrowDropDown } from 'react-icons/md';

export const ContactSupport = () => {
    return (
        <div className="mt-24 gap-5 mb-28 pb-20">
            <div className=''>
                <h1 className="border-3 font-medium text-5xl leading-10 text-center">Contact Support</h1>
                <p className="mt-2 leading-6 font-normal text-center">We’re here for you, tell us how we can help.</p>

                <div className='max-w-xl m-auto mt-5'>
                    <Stack spacing={3}>
                        <FormControl>
                            <FormLabel>Your name</FormLabel>
                            <Input type='' size='lg' />
                            <br />

                            <FormLabel>Your email</FormLabel>
                            <Input type='email' />
                            <br />

                            <FormLabel>Issue type</FormLabel>
                            <Select icon={<MdArrowDropDown />} placeholder='Complaint' />
                            <br />

                            <FormLabel>Message</FormLabel>
                            <Textarea placeholder='Tell us how we can help you.' rows={15} />

                            <Button size='md' className='mt-3 btn-lg primary-btn me-3'>
                                Send
                            </Button>

                        </FormControl>

                    </Stack>
                </div>
            </div>
        </div>
    )
}

export default ContactSupport;