import { useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { RootState } from ".";

export const useAppDispatch = () => useDispatch();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const useStateWithCallback = (initialValue: any) => {
    const [value, setValue] = useState(initialValue);

    const setValueAndCallback = (newValue: any, callback: any) => {
        setValue((prevValue: any) => {
            if (callback) {
                callback(prevValue, newValue);
            }
            return newValue;
        });
    };

    return [value, setValueAndCallback];
};

export { useStateWithCallback };
