import React from 'react'


type CommentProps = {
  author: string;
  time: string;
  content: string;
  likes: number;
  comments: number;
  shares: number;
  commentList: any
};

export const Comment: React.FC<CommentProps> = ({
  author,
  time,
  content,
  likes,
  comments,
  shares,
  commentList,
}) => {
  return (
    <>
      <div className=" bg-white shadow p-6 mb-[30px] rounded-lg">
        <div className="flex items-start mb-4">
          <div className="bg-gray-200 rounded-full h-12 w-12 flex items-center justify-center text-gray-500 text-lg font-semibold">
            {author.charAt(0)}
          </div>
          <div className="ml-4">
            <div className="text-gray-800 font-semibold">{author}</div>
            <div className="text-gray-600 text-sm">
              Started a thread in{" "}
              <span className="text-gray-800">Ask the community</span>
            </div>
            <div className="text-gray-600 text-sm">{time}</div>
          </div>
        </div>
        <p className="text-gray-800 mb-4">{content}</p>
        <div className="flex items-center text-gray-500">
          <span className="mr-4">👍 {likes}</span>
          <span className="mr-4">💬 {comments}</span>
          <span>🔁 {shares}</span>
        </div>
      </div>
      <div className="mt-6 ms-[80px]">
        {commentList.map((comment: any, index: any) => (
          <div key={index} className="bg-gray-100 p-4 rounded-lg mb-4">
            <div className="flex items-start mb-2">
              <div className="bg-gray-300 rounded-full h-10 w-10 flex items-center justify-center text-gray-600 text-lg font-semibold">
                {comment.author.charAt(0)}
              </div>
              <div className="ml-3">
                <div className="text-gray-700 font-semibold">
                  {comment.author}
                </div>
                <div className="text-gray-500 text-sm">{comment.time}</div>
              </div>
            </div>
            <p className="text-gray-700 mb-2">{comment.content}</p>
            <div className="flex items-center text-gray-500">
              <span className="mr-4">👍 {comment.likes}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};