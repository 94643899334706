import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "assets/svg/tradetracker-logo.svg";
import ReactDOM from "react-dom";
import { ReactComponent as ChevronLeft } from "assets/svg/chevron-left-black.svg";
import { ReactComponent as EditIcon } from "assets/svg/edit.svg";
// import noProfile from "assets/svg/profilePicturePlaceHolder.svg";
import { PrimaryButton } from "components/index";
import { getNameInitials } from "utilities/general";
import {
  ICredentials,
  useGetProfileInformationWithTokenQuery,
  usePostUploadPhotoMutation,
  useUpdateProfileMutation,
} from "store/profile";
import { Dispatch, useEffect, useState } from "react";
import { getIdToken, onAuthStateChanged } from "firebase/auth";
import { auth } from "firebaseConfig/config";
import { IoClose } from "react-icons/io5";
import { BsSend } from "react-icons/bs";
import { BiCamera } from "react-icons/bi";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { useDispatch } from "react-redux";
import { setCredential, useAuth } from "store/auth";

export const MyProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, token: access_token } = useAuth();
  const [token, setToken] = useState<string | undefined | any>("");
  const { data: profileInfo, refetch } =
    useGetProfileInformationWithTokenQuery(token);
  const [editMode, setEditMode] = useState(false);
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [image, setImage] = useState<File | null>(null);
  const [preview, setPreview] = useState<string>("");

  const [uploadProfilePicture, { isLoading: upLoading }] =
    usePostUploadPhotoMutation();
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();
  const { initNotification } = usePageNotificationProvider();

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImage(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  const changeProfilePicture = async () => {
    const form = new FormData();
    if (image) form.append("file", image);
    form.append("type", "file");
    await uploadProfilePicture(form)
      .unwrap()
      .then((res) => {
        setPreview("");
        refetch();
        handleUpdate({ photoURL: res.data });
      });
  };

  const handleUpdate = async (payload: ICredentials) => {
    try {
      await updateProfile({
        credentials: payload,
        userID: profileInfo?.data._id || profileInfo?.data.id || "",
      })
        .unwrap()
        .then((res: { data: any; message: any }) => {
          refetch();
          setEditMode(false);
          dispatch(
            setCredential({
              user: res.data,
              access_token,
            })
          );
          initNotification({
            message: res.message || "Profile updated successfully",
            scheme: "success",
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await getIdToken(user);
        setToken(token);
        if (profileInfo?.data) {
          refetch();
          setLastName(profileInfo?.data.lastName);
          setFirstName(profileInfo?.data.firstName);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, profileInfo]);

  return (
    <>
      {preview && (
        <PreviewOverlay
          submit={changeProfilePicture}
          closeModal={setPreview}
          preview={preview}
          loading={upLoading || isLoading}
        />
      )}
      <div className="bg-grey-50 min-h-screen">
        <div className="container">
          <span
            onClick={() => navigate(-1)}
            className="cursor-pointer flex items-center gap-2"
          >
            <ChevronLeft className="text-4xl h-3 w-3" />
            <p>Back</p>
          </span>
        </div>

        <br />

        <div className="container mt-12">
          <div className="bg-white p-8 rounded-lg text-center flex flex-col justify-center items-center gap-4">
            <h3 className="mb-10 text-xl md:text-3xl font-medium">
              My Profile
            </h3>

            <div className="w-24 h-24 bg-primary-500 text-white flex flex-col text-center items-center justify-center rounded-full relative overflow-hidden">
              {user?.photoURL ? (
                <img
                  src={user?.photoURL}
                  className="w-24 h-24 object-cover"
                  alt="profile"
                />
              ) : (
                <p className="text-4xl font-medium">
                  {getNameInitials(user?.firstName + " " + user?.lastName)}
                </p>
              )}
              <input
                type="file"
                name="profilePicture"
                id="profilePicture"
                accept="image/*"
                onChange={handleImageChange}
                hidden
              />
              <label
                className={`absolute inset-0 w-full h-full cursor-pointer opacity-0 hover:opacity-100 ${
                  editMode && "opacity-100"
                } bg-black/40 flex items-center justify-center`}
                htmlFor="profilePicture"
              >
                <BiCamera className="text-3xl" />
              </label>
            </div>

            <div className="flex items-center justify-center gap-1">
              {editMode ? (
                <>
                  <div className="">
                    <input
                      type="text"
                      placeholder="First Name"
                      className={`text-xl min-w-1 py-1 font-medium text-center outline-none inline border border-primary`}
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                      autoFocus={true}
                    />
                    <input
                      type="text"
                      placeholder="Last Name"
                      className={`text-xl min-w-1 py-1 font-medium text-center outline-none inline border border-primary`}
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                    />
                  </div>
                </>
              ) : (
                <h3 className="text-xl min-w-1 font-medium text-center">
                  {firstName + " " + lastName}
                </h3>
              )}
            </div>

            <p>{profileInfo?.data.email}</p>
            {editMode ? (
              <div className="flex items-center gap-3">
                <PrimaryButton
                  onClick={() => handleUpdate({ lastName, firstName })}
                  className="btn-md primary-btn flex items-center gap-4"
                  isLoading={isLoading}
                >
                  <BsSend />
                  <p>Submit</p>
                </PrimaryButton>
                <PrimaryButton
                  onClick={() => {
                    setEditMode(false);
                    setLastName(profileInfo?.data.lastName || "");
                    setFirstName(profileInfo?.data.firstName || "");
                  }}
                  className="btn-md secondary-btn flex items-center gap-4"
                >
                  <IoClose />
                  <p>Cancel</p>
                </PrimaryButton>
              </div>
            ) : (
              <PrimaryButton
                onClick={() => setEditMode(true)}
                className="btn-lg primary-btn flex items-center gap-4"
              >
                <EditIcon />
                <p>Edit</p>
              </PrimaryButton>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

function PreviewOverlay({
  preview,
  submit,
  closeModal,
  loading,
}: {
  preview: string;
  submit: () => void;
  closeModal: Dispatch<React.SetStateAction<string>>;
  loading: boolean;
}) {
  return (
    <div className="fixed z-20 bg-black/25 inset-0 flex items-center justify-center">
      <div className="bg-white min-w-80 max-w-xl w-[calc(100dvw_/2)] h-full max-h-[30rem] object-cover flex justify-between item-center flex-col">
        <img
          src={preview}
          alt="Preview"
          className="w-[calc(100%_/1.3)] h-[calc(100%_/1.5)] object-contain m-auto"
        />
        <div className="flex items-center gap-2 pb-4 justify-center">
          <PrimaryButton
            isLoading={loading}
            isDisabled={loading}
            onClick={submit}
            className="btn-md primary-btn"
          >
            Save
          </PrimaryButton>
          <button
            onClick={() => closeModal("")}
            className="btn-md secondary-btn"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
