import React from "react";
import InfoSection from "./components/InfoSection";
import ServicesSection from "./components/ServicesSection";
import LocationSection from "./components/LocationSection";
import OperationsHourSection from "./components/OperationsHourSection";
import style from "./../../styles/dashboard.module.scss";
import { BiChevronLeft } from "react-icons/bi";
import { BusinessDescription } from "./components/BusinessDescription";
// import AppointmentSection from "./AppointmentSection";
import { useNavigate } from "react-router";

export const BusinessInfo = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className={`${style.page_container} scroll-pt-16`}>
                <div className="container relative grid md:grid-cols-3 mt-5">
                    <aside
                        className={`${style.sidebar} min-w-full md:min-w-96`}
                    >
                        <span
                            className={`${style.back_btn} flex items-center mb-3`}
                            onClick={() => navigate(-1)}
                        >
                            <BiChevronLeft /> back
                        </span>
                        <div className={style.sidebar_cont}>
                            <a
                                href="#InfoSection"
                                className={`${style.sidebar_item} ${style.sidebar_item_active} block`}
                            >
                                Basic Info
                            </a>
                            <a
                                href="#BusinessDescription"
                                className={`${style.sidebar_item} ${style.sidebar_item_active} block`}
                            >
                                Business description
                            </a>
                            <a
                                href="#ServicesSection"
                                className={`${style.sidebar_item} ${style.sidebar_item_active} block`}
                            >
                                Services
                            </a>
                            <a
                                href="#LocationSection"
                                className={`${style.sidebar_item} ${style.sidebar_item_active} block`}
                            >
                                Location
                            </a>
                            <a
                                href="#OperationsHourSection"
                                className={`${style.sidebar_item} ${style.sidebar_item_active} block`}
                            >
                                Operation Hours
                            </a>
                            {/* <a
                                href="#AppointmentSection"
                                className={`${style.sidebar_item} ${style.sidebar_item_active} block`}
                            >
                                Appointment schedule
                            </a> */}
                            <a
                                href="#Rating"
                                className={`${style.sidebar_item} ${style.sidebar_item_active} block`}
                            >
                                Rating
                            </a>
                        </div>
                    </aside>

                    <div className={`!w-full md:col-span-2 ${style.content}`}>
                        <span className={style.page_title}>
                            <h2>Business Info</h2>
                        </span>
                        <div className="scroll-pt-12" id="InfoSection">
                            <InfoSection />
                        </div>
                        <div className="scroll-pt-12" id="BusinessDescription">
                            <BusinessDescription />
                        </div>
                        <div className="scroll-pt-12" id="ServicesSection">
                            <ServicesSection />
                        </div>
                        <div className="scroll-pt-12" id="LocationSection">
                            <LocationSection />
                        </div>
                        <div
                            className="scroll-pt-12"
                            id="OperationsHourSection"
                        >
                            <OperationsHourSection title="Operation Hours" />
                        </div>
                        {/* <div className="scroll-pt-12" id="AppointmentSection">
                            <AppointmentSection title="Appointment schedule" />
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
};
