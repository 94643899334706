import { ChakraAlertDialog, PrimaryButton } from "components";
import React, { FC, useEffect } from "react";
import { Image } from "@chakra-ui/react";
import { useModalProvider } from "providers/modalProvider";
import { useWindowWidth } from "utilities/windowWidth";
import { SchedulerComponentForm } from "components/custom/scheduler";
import {
    useGetMyBusinessQuery,
    useUpdateBusinessMutation,
} from "store/business";
import { useAuth } from "store/auth";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { useFormik } from "formik";

interface BusinessHoursPreviewModalProps {
    isOpen?: boolean;
    onClose?: () => void;
}

export const BusinessHoursPreviewModal: FC<BusinessHoursPreviewModalProps> = ({
    //   isOpen = false,
    onClose = () => {},
    ...rest
}) => {
    const { user } = useAuth();
    const { data: business } = useGetMyBusinessQuery(user?.id || "");
    const businessInfo = business?.data?.[0];

    const windowWidth = useWindowWidth();
    const { initModal, getModalPayload } = useModalProvider();
    const initialValues = {
        businessHours: businessInfo?.businessHours || [],
    };
    const businessInformation = getModalPayload("businessHours");

    const { initNotification } = usePageNotificationProvider();
    const [updateBusiness] = useUpdateBusinessMutation();

    const {
        values,
        setValues,
        setFieldValue,
        setFieldError,
        setFieldTouched,
        errors,
        touched,
        handleSubmit,
    } = useFormik({
        initialValues,
        validationSchema: null,
        onSubmit: (e) => initRequest(e),
    });

    const initRequest = (payload: any) => {
        Object.keys(payload).forEach((key) => {
            if (!Object.keys(initialValues).includes(key)) {
                delete payload[key];
            }
        });

        console.log(payload);
        console.log(businessInformation);

        updateBusiness({ businessData: payload, id: businessInfo?.id })
            .unwrap()
            .then((res: any) => {
                initNotification({
                    scheme: "success",
                    message: res.message,
                });
                onClose();
            })
            .catch((err: any) =>
                initNotification({
                    scheme: "error",
                    message: err?.data?.message || err.error,
                })
            );
    };

    useEffect(() => {
        console.log(businessInfo);
        setValues({
            ...businessInfo,
        });
    }, [businessInfo]);

    return (
        <>
            <ChakraAlertDialog
                useFooter={false}
                useCancelButton={false}
                onClose={() => {
                    initModal((prev) => ({ ...prev, businessHours: false }));
                }}
                useProceedButton={false}
                useHeader={true}
                headerProps={{
                    className:
                        windowWidth <= 768
                            ? "px-0 pt-0 text-gray-600 font-medium mt-3"
                            : "px-0 pt-0 text-gray-600 font-medium",
                }}
                useFloatingCancel={false}
                title={"Edit Business Hours"}
                size={windowWidth >= 768 ? "3xl" : "full"}
                closeOnOverlayClick={true}
                modalContentProps={{ className: "rounded-md p-4" }}
                modalBodyProps={{ className: "p-0" }}
                {...rest}
            >
                <form onSubmit={handleSubmit} className="">
                    <div className="mt-3 d-flex flex-column w-100 gap-4">
                        <div className="my-5">
                            <div className="my-5">
                                <label className="font-medium">
                                    Business hours
                                </label>
                                <SchedulerComponentForm
                                    setScheduleDays={(val) => {
                                        setFieldValue("businessHours", val);
                                        if (val.length) {
                                            setFieldTouched(
                                                "businessHours",
                                                true
                                            );
                                        }
                                    }}
                                    scheduleDays={businessInfo?.businessHours}
                                />
                                {touched.businessHours &&
                                errors.businessHours ? (
                                    <p className="text-[#E53E3E] mt-2 text-sm">
                                        {errors.businessHours.length?.toString()}
                                    </p>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center gap-4 mt-10">
                        <PrimaryButton
                            type="submit"
                            className="btn-lg primary-btn mt-2 align-self-baseline"
                        >
                            Submit
                        </PrimaryButton>
                        <PrimaryButton
                            type="reset"
                            className="btn-lg secondary-btn mt-2 align-self-baseline"
                            onClick={onClose}
                        >
                            Cancel
                        </PrimaryButton>
                    </div>
                </form>
            </ChakraAlertDialog>
        </>
    );
};
