import { HeaderComponent } from "pages/landingPage/components";
import { AuthVerifyContainer } from "pages/auth/components";
import { useEffect } from "react";
import { useAuth } from "store/auth";
import { useNavigate } from "react-router";

export const BusinessContainer = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  useEffect(() => {
    if (user?.role === "member") {
      navigate("/businesses");
    }
  }, [navigate, user?.role]);
  return (
    <div className="dashboard-container">
      {/*========== HEADER =========== */}
      <HeaderComponent role="business" />
      {/*========== END OF HEADER =========== */}

      <section className="bg-grey-50" style={{ minHeight: "100vh" }}>
        <div className="h-100 d-flex align-items-center">
          {/* <SideBarComponent /> */}
          <div
            className="dashboard-container pt-[90px] pb-14 bg-grey-50"
            style={{ minHeight: "100vh" }}
          >
            <div
              className="p-md-4 p-2 pb-0 bg-grey-50 h-100"
              // style={{ minHeight: "100vh" }}
            >
              <div className="pt-2 h-100">
                <AuthVerifyContainer />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
