import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../utilities/axiosQuery/axiosBaseQuery";
import { baseUrl } from "../../utilities/requests";
// import { IResponse, User } from "../auth/interface";
import {
  AddComment,
  CreateCommentPayload,
  CreateReplyPayload,
  AddReply,
  AllCommentsResponse,
  SingleCommentResponse,
  CreateBusinessCommentPayload,
  GetAllCommentsPayload,
  DislikeCommentPayload,
  CommentStatsResponse,
} from "./interface";
import { IResponse } from "store/auth";
import { convertObjectToURLParams } from "utilities/index";
// import { convertObjectToURLParams } from "utilities";

export const commentApi = createApi({
  reducerPath: "commentApi",
  baseQuery: axiosBaseQuery({ baseUrl: `${baseUrl}/api/comments` }),
  endpoints: (builder) => ({
    getAllComments: builder.query<AllCommentsResponse, number | undefined>({
      query: (payload) => ({
        url: `/${payload}/comments-and-replies`,
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "skip-browser-warning",
        },
      }),
    }),
    getComment: builder.query<SingleCommentResponse, number | undefined>({
      query: (payload) => ({
        url: `/${payload}`,
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "skip-browser-warning",
        },
      }),
    }),
    postCommentOnReview: builder.mutation<
      IResponse,
      CreateBusinessCommentPayload
    >({
      query: (payload) => ({
        url: ``,
        method: "POST",
        headers: {
          "ngrok-skip-browser-warning": "skip-browser-warning",
        },
        body: payload,
      }),
    }),
    getCommentsByReviewId: builder.query<
      AllCommentsResponse,
      GetAllCommentsPayload
    >({
      query: (payload) => ({
        url: `/review/${payload.id}?${convertObjectToURLParams(payload)}`,
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "skip-browser-warning",
        },
      }),
    }),
    getCommentsByUserId: builder.query<
      AllCommentsResponse,
      GetAllCommentsPayload
    >({
      query: (payload) => ({
        url: `/user/${payload.id}?${convertObjectToURLParams(payload)}`,
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "skip-browser-warning",
        },
      }),
    }),
    unlikeComment: builder.query<IResponse, number>({
      query: (payload) => ({
        url: `/${payload}/dislike`,
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "skip-browser-warning",
        },
      }),
    }),
    likeComment: builder.query<IResponse, number>({
      query: (payload) => ({
        url: `/${payload}/like`,
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "skip-browser-warning",
        },
      }),
    }),
    postCommentReply: builder.mutation<any, CreateCommentPayload>({
      query: (payload) => ({
        url: ``,
        method: "POST",
        headers: {
          "ngrok-skip-browser-warning": "skip-browser-warning",
        },
        body: payload,
      }),
    }),
    getCommentReplies: builder.query<
      AllCommentsResponse,
      GetAllCommentsPayload
    >({
      query: (payload) => ({
        url: `/${payload.id}/replies?${convertObjectToURLParams(payload)}`,
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "skip-browser-warning",
        },
      }),
    }),
    getCommentStats: builder.query<CommentStatsResponse, number>({
      query: (payload) => ({
        url: `/${payload}/stats`,
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "skip-browser-warning",
        },
      }),
    }),
  }),
});

export const {
  useGetCommentQuery,
  useGetAllCommentsQuery,
  usePostCommentReplyMutation,
  useUnlikeCommentQuery,
  useGetCommentsByReviewIdQuery,
  useGetCommentsByUserIdQuery,
  useLikeCommentQuery,
  usePostCommentOnReviewMutation,
  useGetCommentRepliesQuery,
  useGetCommentStatsQuery,
} = commentApi;
