import { PrimaryButton } from "components/buttons";
import {
    AuthHeaderText,
    CustomPasswordInput,
    PrimaryCheckBox,
    PrimaryInput,
    // PrimarySelect,
} from "components";
// import { AuthHeaderText, CustomPasswordInput } from "components/custom";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RegisterValidationSchema } from "validations/auth";
import { SocialAuthButton } from "./components";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
// import { useDispatch } from "react-redux";
import {
    RegisterPayload,
    setCredential,
    useAuth,
    // setCredential,
    // useRegisterMutation,
} from "store/auth";
// import { resolveApiError } from "utilities/errorHandling";
import { ReactComponent as Logo } from "assets/svg/tradetracker-logo.svg";
import {
    createUserWithEmailAndPassword,
    // getIdToken,
    // onAuthStateChanged,
    sendEmailVerification,
    updateProfile,
} from "firebase/auth";
import { auth } from "firebaseConfig/config";
import { useDispatch } from "react-redux";
import { useGetProfileInformationWithTokenQuery } from "store/profile";
import { mapAuthCodeToMessage } from "utilities/general";

export const SignupPage = () => {
    const { user: storedUser } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState<string | undefined | any>("");
    const {
        data: profileInfo,
        isLoading: loading,
        error,
    } = useGetProfileInformationWithTokenQuery(token);
    // const [request] = useRegisterMutation();
    const { initNotification } = usePageNotificationProvider();
    const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
        useFormik({
            initialValues: {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                // confirmPassword: "",
            },
            validationSchema: RegisterValidationSchema,
            onSubmit: (e) => initRequest(e),
        });

    const initRequest = async (payload?: RegisterPayload) => {
        try {
            if (payload) {
                setIsLoading(true);
                createUserWithEmailAndPassword(
                    auth,
                    payload?.email,
                    payload?.password
                )
                    .then((userCredential) => {
                        // setIsLoading(false);
                        const user = userCredential.user;
                        let token = user.getIdToken();

                        dispatch(
                            setCredential({
                                user: storedUser,
                                access_token: token,
                            })
                        );
                        updateProfile(user, {
                            displayName: `${payload.firstName} ${payload.lastName}`,
                        });
                        sendEmailVerification(user, {
                            url: `${process.env.REACT_APP_BASE_URL}/verify-email?email=${user.email}`,
                            handleCodeInApp: true,
                        })
                            .then(() => {
                                setIsLoading(false);
                                navigate(`/verify-email?email=${user.email}`);
                            })
                            .catch((error) => {
                                setIsLoading(false);
                            });
                    })
                    .catch((err) => {
                        setIsLoading(false);
                        initNotification({
                            message: mapAuthCodeToMessage(err.code),
                            scheme: "error",
                        });
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        } catch (error: any) {
            setIsLoading(false);
        }
    };

    const mapAuthCodeToMessage = (authCode: string) => {
        switch (authCode) {
            case "auth/invalid-password":
                return "Current password provided is not correct.";

            case "auth/email-already-in-use":
                return "Email is already in use.";

            case "auth/weak-password":
                return "Password is too weak, please try again.";

            case "auth/user-not-found":
                return "User not found.";

            case "auth/invalid-email":
                return "Invalid email provided.";

            case "auth/network-request-failed":
                return "Network request failed, please try again later.";

            case "auth/wrong-password":
                return "Current password provided is not correct.";

            case "auth/too-many-requests":
                return "Too many tries, please try again later.";

            default:
                return "Something went wrong, please try again later.";
        }
    };

    // useEffect(() => {
    //   if (!loading) {
    //     if (profileInfo?.data || token) {
    //       console.log(token);
    //       dispatch(
    //         setCredential({
    //           user: profileInfo?.data?.user
    //             ? profileInfo?.data?.user
    //             : profileInfo?.data,
    //           access_token: token,
    //         })
    //       );

    //       if (profileInfo?.data?.role) {
    //         if (profileInfo?.data?.role === "member") {
    //           navigate(`/businesses`);
    //         } else if (profileInfo?.data?.role === "businessOwner") {
    //           navigate(`/business/dashboard`);
    //         }
    //       }
    //       return;
    //     }
    //     console.log("done", auth?.currentUser, profileInfo);
    //     if (
    //       (error as any)?.data?.status === 500 &&
    //       auth?.currentUser?.emailVerified
    //     ) {
    //       console.log("doneeeeedddd");
    //       initNotification({
    //         message: "An error occured, please try again later.",
    //         scheme: "error",
    //       });
    //       return;
    //     }
    //     // if (
    //     //   ((error as any)?.data?.status === 400 ||
    //     //     (error as any)?.data?.status === 401) &&
    //     //   auth?.currentUser?.emailVerified
    //     // ) {
    //     //   console.log("doneaaaayyyy");
    //     //   navigate(`/account-type`);
    //     //   return;
    //     // }

    //     console.log("doneuuuuuuuwwww");
    //   }
    // }, [
    //   dispatch,
    //   loading,
    //   auth,
    //   navigate,
    //   profileInfo,
    //   token,
    //   initNotification,
    //   error,
    // ]);

    return (
        <div className="flex flex-col bg-[#F0F0F0] justify-center items-center gap-8 auth min-h-[100vh] py-10">
            <Link to="/" className="font-extrabold text-2xl text-gray-500">
                <Logo />
            </Link>
            <div className="auth-form-wrapper bg-white py-10 px-8 rounded-2xl max-w-[450px] w-[90%] mx-auto">
                <AuthHeaderText
                    title="Sign up"
                    headingClassName="text-black text-3xl font-medium text-center mb-10"
                />
                <form onSubmit={handleSubmit} className="">
                    <div className="mt-3 d-flex flex-column w-100 gap-4">
                        <div className="my-5">
                            <PrimaryInput
                                type="text"
                                label="First name"
                                name="firstName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.firstName}
                                error={Boolean(
                                    errors?.firstName && touched?.firstName
                                )}
                                bottomText={errors?.firstName || ""}
                            />
                        </div>
                        <div className="my-5">
                            <PrimaryInput
                                type="text"
                                label="Last name"
                                name="lastName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.lastName}
                                error={Boolean(
                                    errors?.lastName && touched?.lastName
                                )}
                                bottomText={errors?.lastName || ""}
                            />
                        </div>
                        <div className="my-5">
                            <PrimaryInput
                                type="email"
                                label="Email Address"
                                name="email"
                                placeholder="example@mail.com"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                error={Boolean(errors?.email && touched?.email)}
                                bottomText={errors?.email || ""}
                            />
                        </div>
                        <div className="">
                            <CustomPasswordInput
                                label="Password"
                                name="password"
                                placeholder="********"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                error={Boolean(
                                    touched.password && errors?.password
                                )}
                                bottomText={errors?.password}
                            />
                        </div>
                    </div>
                    <div className="flex items-center justify-between mt-4">
                        <PrimaryCheckBox
                            size={"lg"}
                            className=""
                            rightAddon={
                                <label className="bg-transparent border-0 parent-format max-w-sm">
                                    I agree with tradetracka’s{" "}
                                    <Link
                                        className="text-primary-500"
                                        to={"/terms-and-conditions"}
                                    >
                                        terms and conditions
                                    </Link>{" "}
                                    and{" "}
                                    <Link
                                        className="text-primary-500"
                                        to={"/privacy"}
                                    >
                                        privacy policy
                                    </Link>
                                </label>
                            }
                            rightAddonProps={{
                                className:
                                    "bg-transparent border-0 text-wrap max-w-[440px]",
                            }}
                        />
                    </div>
                    <PrimaryButton
                        type="submit"
                        className="btn-lg primary-btn w-full mt-8 align-self-baseline"
                        isLoading={isLoading}
                    >
                        Create account
                    </PrimaryButton>
                </form>
                <h4 className="divider">Or continue with</h4>
                <div className="flex items-center  justify-center gap-5">
                    <SocialAuthButton provider="google" />
                </div>
            </div>
            <p className="text-center text-[#383838]">
                Already have an account?{" "}
                <Link
                    to="/login"
                    className="text-[#001C89] text-base font-medium fw-700 text-decoration-none"
                >
                    Log in
                </Link>
            </p>
        </div>
    );
};
