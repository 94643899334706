import moment from "moment";
import { parse } from "tldts";
import { variables } from "./variables";

export const filterWordCount = (name: string, count: number = 2) => {
  let splitNames = name.split(" ").filter((text) => text.length);
  return splitNames.length > count
    ? splitNames.slice(0, count).join(" ")
    : name;
};

export const Greeting = () => {
  let presentTime = new Date();
  let hrs = presentTime.getHours();
  if (hrs < 12) {
    return "Good morning";
  } else if (hrs >= 12 && hrs < 17) {
    return "Good afternoon";
  } else return "Good evening";
};

export function sterilizeNumber(text: string): string {
  let value = parseInt(text.replace(/[^0-9]/g, ""));
  return value ? value.toString() : "";
}

export const heatCheck = (dob: string) => {
  const today = moment().format("YYYY-MM-DD");
  const maturityDate = moment(dob).add(6, "months").format("YYYY-MM-DD");
  return {
    mature: today > maturityDate,
    maturityDate: maturityDate,
    formattedDate: moment(maturityDate).format("dddd, Do MMM YYYY"),
  };
};

export const randomString = (
  length: number = 16,
  chars: string = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
) => {
  var result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

export const timeReference = (
  prefix?: string,
  suffix?: string,
  separator: string = "_"
) => {
  const prefixString = Boolean(prefix) ? prefix + separator : "";
  const suffixString = Boolean(suffix) ? separator + suffix : "";

  return prefixString + new Date().getTime().toString() + suffixString;
};

export const maxItems = (data: any[] = [], count?: number) => {
  if (count && count <= data.length) return data.slice(0, count);
  return data;
};

export const getSubDomain = (defaultDomain?: string) => {
  const subdomain = parse(window.location.href).subdomain;
  let selSubDomain =
    subdomain && subdomain.length > 1 ? subdomain : defaultDomain;
  if (selSubDomain && variables.invalidSubDomains.includes(selSubDomain))
    return undefined;
  return selSubDomain;
};

export const redirectTo = (address: string) => {
  window.location.href = address;
  return;
};

export const convertObjectToURLParams = (data: any) => {
  const params = Object.keys(data)
    .map((key) => {
      const rc = data[key];
      if (rc !== undefined && rc !== null) {
        return `${key}=${encodeURIComponent(rc)}`;
      }
      return "";
    })
    .join("&");
  // console.log(params)
  return params;
};

export const rangeOfNumbers = (start: number = 0, end: number = 0) => {
  return Array.from({ length: end + 1 - start }, (v, k) => k + start);
};

export const getStatusColorScheme = (status: string) => {
  let style;
  switch (status) {
    case "pending":
      style = { backgroundColor: "#FEF4E1", color: "#FBA110" };
      break;
    case "rejected":
      style = { backgroundColor: "#FFEAED", color: "#DB1D1A" };
      break;
    case "approved":
      style = { backgroundColor: "#DFF2EE", color: "#00A788" };
      break;
    case "successful":
      style = { backgroundColor: "#DFF2EE", color: "#00A788" };
      break;
    case "draft":
      style = { backgroundColor: "#ECF0F5", color: "#14171A" };
      break;
  }
  return style;
};

export const allMonths = () => {
  return [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
};

export const abbrString = (string: string, swap = [0, 1]) => {
  const split = string.split(" ");
  if (split.length == 1) return string;

  return `${split[swap[0]]}.${split[swap[1]].charAt(0)}`;
};

export const getDaysLeft = (date: moment.MomentInput) => {
  const specificDate = moment(date);

  const currentDate = moment();

  const daysLeft = specificDate.diff(currentDate, "days");
  if (daysLeft === 0) {
    return `Expires Today`;
  } else if (daysLeft < 0) {
    return "Expired";
  } else {
    return `${daysLeft} Days Left`;
  }
  // return daysLeft === 0 ? 0 : daysLeft;
};

export const formatDate = (date: moment.MomentInput) => {
  const formattedDate = moment(date).format("MMM. D, YYYY");
  return formattedDate;
};

export const getPercentage = (total: number, currentVal: number) => {
  const percentage = (currentVal * 100) / total;
  return percentage;
};

export const extractFileNameFromUrl = (url: string | URL) => {
  if (url) {
    const urlObj = new URL(url);
    const pathname = urlObj.pathname;
    const pathSegments = pathname.split("/");
    const filenameWithParams = pathSegments[pathSegments.length - 1];
    const filenameSegments = filenameWithParams.split("?");
    const filename = filenameSegments[0];
    return filename;
  }
};

export const getTimeDifference = (date: moment.MomentInput) => {
  const specificDate = moment(date);

  const currentDate = moment();

  const timeDifference = currentDate.diff(specificDate, "seconds");
  return timeDifference;
};

export const mapAuthCodeToMessage = (authCode: string) => {
  switch (authCode) {
    case "auth/invalid-password":
      return "Current password provided is not correct.";

    case "auth/wrong-password":
      return "Current password provided is not correct.";

    case "auth/too-many-requests":
      return "Too many tries, please try again later.";

    case "auth/user-not-found":
      return "User not found, please try again later.";

    case "auth/email-already-in-use":
      return "Email already in use, please try again later.";

    case "auth/weak-password":
      return "Password is too weak, please try again later.";

    case "auth/network-request-failed":
      return "Network request failed, please check your internet connection.";

    case "auth/invalid-email":
      return "Invalid email, please try again later.";

    case "auth/user-disabled":
      return "User is disabled, please try again later.";

    case "auth/operation-not-allowed":
      return "Operation not allowed, please try again later.";

    case "auth/account-exists-with-different-credential":
      return "Account already exists with different credential, please try again later.";

    case "auth/requires-recent-login":
      return "Requires recent login, please try again";

    case "auth/id-token-expired":
      return "Id token expired, please try again later.";

    case "auth/expired-action-code":
      return "Action code expired, please try again later.";

    case "auth/invalid-action-code":
      return "Invalid action code, please try again later.";

    case "auth/invalid-credential":
      return "Invalid credential, please try again later.";

    case "auth/id-token-revoked":
      return "Id token revoked, please try again later.";

    case "auth/argument-error":
      return "Argument error, please try again";

    case "auth/popup-closed-by-user":
      return "Popup closed by user, please try again";

    case "auth/cancelled-popup-request":
      return "Cancelled popup request, please try again";

    case "auth/unauthorized-domain":
      return "Unauthorized domain, please try again";

    case "auth/operation-not-supported-in-this-environment":
      return "Operation not supported in this environment, please try again";

    case "auth/invalid-api-key":
      return "Invalid api key";

    case "auth/app-deleted":
      return "App deleted";

    case "INVALID_PASSWORD":
      return "Current password provided is not correct.";

    case "INVALID_LOGIN_CREDENTIALS":
      return "Login credentials are invalid.";

    case "EMAIL_NOT_FOUND":
      return "Email not found.";

    default:
      return "Something went wrong, please try again later.";
  }
};

export const getNameInitials = (name: string) => {
  const nameArray = name.split(" ");
  const initials = nameArray.map((name) => name[0]?.toUpperCase());
  return initials.join("");
};
