import { PrimaryButton } from "components/buttons";
import {
  AuthHeaderText,
  CustomPasswordInput,
  PrimaryCheckBox,
  PrimaryInput,
  PrimaryPhone,
  // PrimarySelect,
} from "components";
// import { AuthHeaderText, CustomPasswordInput } from "components/custom";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  CreateProfileValidationSchema,
  RegisterValidationSchema,
} from "validations/auth";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
// import { useDispatch } from "react-redux";
import {
  RegisterPayload,
  setCredential,
  useAuth,
  useCreateUserMutation,
  // setCredential,
  // useRegisterMutation,
} from "store/auth";
// import { resolveApiError } from "utilities/errorHandling";
import { ReactComponent as Logo } from "assets/svg/tradetracker-logo.svg";
import {
 
} from "firebase/auth";
import { auth } from "firebaseConfig/config";
import { useDispatch } from "react-redux";
import { resolveApiError } from "utilities/errorHandling";

export const CreateProfilePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, user } = useAuth();

  const [request, { isLoading }] = useCreateUserMutation();
  const { initNotification } = usePageNotificationProvider();
  const {
    values,
    errors,
    touched,
    setValues,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      dateOfBirth: "",
    },
    validationSchema: CreateProfileValidationSchema,
    onSubmit: (e) => initRequest(e),
  });

  useEffect(() => {
    setValues((prev) => {
      return {
        ...prev,
        firstName: user?.displayName?.split(" ")[0] as string,
        lastName: user?.displayName?.split(" ")[1] as string,
        email: user?.email as string,
      };
    });
  }, [setValues]);
  console.log(auth?.currentUser, user);

  const initRequest = async (payload?: any) => {
    console.log(payload);

    if (payload) {
      // setIsLoading(true);
      request(payload ?? values)
        .unwrap()
        .then((res: any) => {
          if (res.error) {
            initNotification({
              message: res.message,
              scheme: "error",
            });
            return;
          }

          initNotification({
            message: "You have successfully created a profile.",
          });

          console.log(res);
          dispatch(
            setCredential({
              user: res.data,
              access_token: token,
            })
          );
          navigate(`/businesses`);
        })
        .catch((error) => {
          console.log(error);
          initNotification({
            message: resolveApiError(error),
            scheme: "error",
          });
        });
    }
  };

  let futureDate = new Date();
  futureDate.setFullYear(futureDate.getFullYear() - 200);

  return (
    <div className="flex flex-col bg-[#F0F0F0] justify-center items-center gap-8 auth min-h-[100vh] py-10">
      <Link to="/" className="font-extrabold text-2xl text-gray-500">
        <Logo />
      </Link>
      <div className="auth-form-wrapper bg-white py-10 px-8 rounded-2xl max-w-[450px] w-[90%] mx-auto">
        <AuthHeaderText
          title="Create your profile"
          headingClassName="text-black text-3xl font-medium text-center mb-10"
        />
        <form onSubmit={handleSubmit} className="">
          <div className="mt-3 d-flex flex-column w-100 gap-4">
            <div className="my-5">
              <PrimaryInput
                type="firstName"
                label="First name*"
                name="firstName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                error={Boolean(errors?.firstName && touched?.firstName)}
                bottomText={errors?.firstName || ""}
              />
            </div>
            <div className="my-5">
              <PrimaryInput
                type="lastName"
                label="Last name*"
                name="lastName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                error={Boolean(errors?.lastName && touched?.lastName)}
                bottomText={errors?.lastName || ""}
              />
            </div>
            <div className="my-5">
              <PrimaryInput
                type="email"
                label="Email Address*"
                name="email"
                placeholder="example@mail.com"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={Boolean(errors?.email && touched?.email)}
                bottomText={errors?.email || ""}
              />
            </div>
            <div className="my-5">
              <PrimaryPhone
                label="Phone Number"
                placeholder=""
                name="phone"
                invalid={String(Boolean(errors?.phone))}
                onChange={(v) =>
                  setValues((prev) => {
                    return { ...prev, phone: v as string };
                  })
                }
                onBlur={handleBlur}
                value={values.phone}
                error={Boolean(errors?.phone && touched?.phone)}
                bottomText={errors?.phone || ""}
                // bg={windowWidth <= 768 ? "white" : ""}
                // rightComponent={
                //   <div
                //     onClick={() => {
                //       initModal((prev) => ({
                //         ...prev,
                //         verifyPhoneModal: {
                //           payload: {
                //             phone: values.phone,
                //           },
                //         },
                //       }));
                //     }}
                //   >
                //     Verify
                //   </div>
                // }
                // rightComponentProps={{ className: "h-100 fs-15 me-3 text-primary-700 cursor-pointer" }}
              />
            </div>
            <div className="my-5">
              <PrimaryInput
                type="date"
                label="Date of Birth*"
                name="dateOfBirth"
                placeholder="Enter Your date of birth"
                onChange={handleChange}
                onBlur={handleBlur}
                min={futureDate.toISOString().split("T")[0]}
                // max={eighteenYearsAbove.toISOString().split("T")[0]}
                value={values.dateOfBirth.substring(0, 10)}
                error={Boolean(errors?.dateOfBirth && touched?.dateOfBirth)}
                bottomText={errors?.dateOfBirth || ""}
                // bg={windowWidth <= 768 ? "white" : ""}
              />
            </div>
          </div>
          <PrimaryButton
            type="submit"
            className="btn-lg primary-btn w-full mt-8 align-self-baseline"
            isLoading={isLoading}
          >
            Create Profile
          </PrimaryButton>
        </form>
      </div>
    </div>
  );
};
