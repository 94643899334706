/* eslint-disable react-hooks/exhaustive-deps */
import { HeaderComponent, FooterComponent } from "pages/landingPage";
import { ReactComponent as SearchIcon } from "assets/svg/search.svg";
import { ReactComponent as QuestionIcon } from "assets/svg/question-mark-blue-bg.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import RedefineYourShopping from "../trackaForBusiness/components/redefine-your-shopping";
import SupportPageRoute from "./components/routes";
import { useLayoutEffect, useState } from "react";

export const Support = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [faqList, setFaqList] = useState([
        { name: "FAQ for shoppers", link: "faq-shoppers", isActive: false },
        { name: "How it works", link: "how-it-works", isActive: false },
        { name: "FAQ for business", link: "faq-business", isActive: false },
        {
            name: "How it works for business",
            link: "how-it-works-business",
            isActive: false,
        },
        { name: "Contact Support", link: "contact-support", isActive: false },
    ]);

    useLayoutEffect(() => {
        setFaqList((prevFaqList) =>
            prevFaqList.map((faq) => ({
                ...faq,
                isActive: faq.link === location.pathname.split("/").pop(),
            }))
        );

        if (location.pathname === "/support") {
            navigate("/support/contact-support");
        }
    }, [location]);

    return (
        <div className="bg-white min-h-screen">
            {/*========== HEADER =========== */}
            <HeaderComponent role="general" />
            <section className="pt-20">
                <div className="bg-primary-500 text-white flex items-center justify-center flex-col gap-8 py-28 px-6">
                    <h1 className="text-4xl md:text-5xl font-medium text-center">
                        Support
                    </h1>
                    {/* <div className="flex items-center bg-white rounded gap-4 px-4 w-full max-w-lg">
            <SearchIcon className="left-4 top-1/2 bottom-1/2" />
            <input
              type="text"
              className="border-none outline-none w-full p-3 text-grey-800 transition-all"
            />
          </div> */}
                </div>
            </section>

            <section className="container !mx-0 flex justify-between gap-10 flex-col md:flex-row">
                <div className="md:min-w-80 max-w-[90%] md:translate-x-0 -translate-x-[2.5%]">
                    <ul className="flex flex-col">
                        {faqList.map((faq) => (
                            <li>
                                <NavLink
                                    to={faq.link}
                                    className={`bg-grey-50 flex items-center p-2 gap-6 pr-4 border-b border-grey-200 ${
                                        faq.isActive &&
                                        "border-r-4 border-r-primary-500"
                                    }`}
                                >
                                    <QuestionIcon />
                                    <p className="text-nowrap text-lg font-normal text-grey-500">
                                        {faq.name}
                                    </p>
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* INSERT MAIN BODIES */}
                <div className="w-full">
                    <SupportPageRoute />
                </div>
            </section>

            <div className="container">
                <RedefineYourShopping />
            </div>
            <FooterComponent />
        </div>
    );
};
