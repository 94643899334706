/* eslint-disable react-hooks/exhaustive-deps */

import { useFormik } from "formik";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { useDispatch } from "react-redux";
import { useRegisterMutation } from "store/auth";
import { AuthHeaderText } from "components/custom";
import { PrimaryButton } from "components/index";
import { PrimaryInput } from "components/inputs";
import { Link } from "react-router-dom";
import { object, string } from "yup";
import { useAppSelector } from "store/hooks";
import { useEffect, useState } from "react";
import { reducerFunction } from "store/business-false";
import { getBusinessNames } from "store/business-false/api";
import { useGetBusinessByNameQuery } from "store/business";
import {
    IBusinessInterface,
    IBusinessUserInterface,
} from "./business-Interface";
import { reactLocalStorage } from "reactjs-localstorage";

interface Props {
    nextPage: (num: number) => void;
}

interface IBusinessName {
    businessName: string;
}

export const BusinessNameSchema = () => {
    return object({
        businessName: string().required("Business name is required"),
    });
};

export const BusinessName = ({ nextPage }: Props) => {
    const dispatch = useDispatch();
    const [request, { isLoading }] = useRegisterMutation();
    const { initNotification } = usePageNotificationProvider();
    const [business, setBusiness] = useState<IBusinessInterface[]>([]);
    const previousValues: IBusinessUserInterface = JSON.parse(
        reactLocalStorage.get("@business_info_payload") || "{}"
    );

    const {
        values,
        setValues,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useFormik({
        initialValues: {
            businessName: "",
        },
        validationSchema: BusinessNameSchema,
        onSubmit: (e) => initRequest(e),
    });

    const { data: businessRes } = useGetBusinessByNameQuery(
        values.businessName
    );

    const initRequest = (payload: IBusinessName) => {
        const updatedValues = {
            ...previousValues,
            business: {
                ...previousValues.business,
                businessName: payload.businessName,
            },
        };

        reactLocalStorage.set(
            "@business_info_payload",
            JSON.stringify(updatedValues)
        );

        nextPage && nextPage(1);
    };

    useEffect(() => {
        if (businessRes && businessRes.data.length) {
            setBusiness(businessRes.data);
        }
    }, [businessRes]);

    useEffect(() => {
        if (previousValues) {
            setValues((prev) => {
                return {
                    ...prev,
                    businessName: previousValues?.business?.businessName,
                };
            });
        }
    }, []);

    const updateValue = (business: any) => {
        setValues((prev) => {
            return {
                ...prev,
                businessName: business.businessName,
            };
        });
        setBusiness([]);
    };

    return (
        <>
            <AuthHeaderText
                title="Start with your business name"
                subTitle="Add or search for your business name to get started."
                headingClassName="text-black text-3xl font-medium mb-5"
            />
            <form onSubmit={handleSubmit} className="">
                <div className="mt-3 d-flex flex-column w-100 gap-4">
                    <div className="my-5">
                        <PrimaryInput
                            type="text"
                            label="Business name"
                            name="businessName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.businessName}
                            error={Boolean(
                                errors?.businessName && touched?.businessName
                            )}
                            bottomText={errors?.businessName || ""}
                        />
                    </div>
                    <div className="flex flex-col gap-3">
                        {business?.length > 0 &&
                            business.map((business) => (
                                <div
                                    key={business.businessName}
                                    onClick={() => updateValue(business)}
                                    className="bg-grey-100 p-4 font-medium border border-primary-200 cursor-pointer rounded hover:bg-grey-200"
                                >
                                    {business.businessName}
                                </div>
                            ))}
                    </div>
                </div>
                <PrimaryButton
                    type="submit"
                    className="btn-lg primary-btn mt-2 align-self-baseline"
                    isLoading={isLoading}
                >
                    Continue
                </PrimaryButton>
            </form>
            {/* <p className="text-[#383838] mt-10">
                Business name not found?{" "}
                <Link
                    to="#"
                    className="text-primary-500 text-base font-medium fw-700 text-decoration-none"
                >
                    Add Business
                </Link>
            </p> */}
        </>
    );
};
