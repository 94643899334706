import { RiEditBoxLine } from "react-icons/ri";
import Map from "assets/svg/mapPlaceholder.svg";
import { FiPlus } from "react-icons/fi";
import { FC, useEffect, useState } from "react";
import { IoOpenOutline } from "react-icons/io5";
import { BsTelephonePlus } from "react-icons/bs";
import { MdOutlineDirections } from "react-icons/md";
import { PrimaryButton } from "components";
import { AverageRatingsComponent, ReadReview } from "pages";
import { IBusinessInterface } from "pages/auth/business/business-Interface";
import { useNavigate } from "react-router";
import { useModalProvider } from "providers";
// import Rating from "react-rating";

interface Business {
  business: IBusinessInterface;
  reviewsAndRatings: any;
  highlight: boolean;
  isSameBusiness: boolean;
}

export const OverviewTab: FC<Business> = ({
  business,
  reviewsAndRatings,
  highlight,
  isSameBusiness,
}) => {
  const navigate = useNavigate();
  const { initModal } = useModalProvider();
  const [showFullText, setShowFullText] = useState(false);

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };

  const truncatedText = business?.about?.slice(0, 258);
  const visibleText = showFullText ? business?.about : truncatedText + "...";

  useEffect(() => {
    setShowFullText(business?.about.length < 258);
  }, [business?.about.length]);

  return (
    <>
      <div className="container">
        <div className=" grid grid-cols-8 lg:gap-14">
          <div className="col-span-5 flex justify-between items-center mb-7">
            <h4 className="text-[#282828] lg:block hidden text-2xl font-medium">
              Verified reviews and ratings
            </h4>
            <h4 className="text-[#282828] lg:hidden block text-2xl font-medium">
              {business?.businessName}
            </h4>
            {/* <PrimaryButton
              onClick={() => navigate(`/review?business=${business?.id}`)}
              className="btn-lg primary-btn !font-normal"
            >
              Rate this business
            </PrimaryButton> */}
          </div>
        </div>
        <div className=" grid grid-cols-8 lg:gap-14">
          <div className="lg:col-span-5 lg:block hidden col-span-8">
            <AverageRatingsComponent
              ratings={reviewsAndRatings?.averages}
              reviews={reviewsAndRatings?.reviews}
            />

            <ReadReview canReply={false} />

            {/* <div className="pt-4 pb-14 mt-9 border-y border-[#C2C2C2]">
              <h1 className="text-[28px] text-[#0B0B0B] font-medium">
                Ask the community a question
              </h1>

              <PrimaryButton
                onClick={() =>
                  initModal((prev) => ({
                    ...prev,
                    askTheCommunity: true,
                  }))
                }
                className="text-lg px-0 text-primary-500 !font-semibold bg-transparent border-0 "
              >
                <FiPlus className="h-5 w-5 me-1" />
                Ask a question
              </PrimaryButton>
              <p className="text-[#0B0B0B] font-medium mt-3">
                No questions yet, be the first to ask a question.
              </p>
            </div> */}
          </div>

          <div className="lg:col-span-3 col-span-8">
            <div className="border border-[#DADADA] md:pt-7 md:pb-12 pt-6 pb-6 md:px-11 px-5 rounded-lg">
              <div className="mb-5">
                <h3 className="text-[#919191] text-base font-medium mb-1">
                  Visit website
                </h3>
                {business?.website ? (
                  <a
                    href={business?.website}
                    rel="noreferrer"
                    target="_blank"
                    className="flex justify-between w-full text-[#001C89] font-medium"
                  >
                    {business?.website.split("//")[1]}
                    <IoOpenOutline className="h-5 w-5" />
                  </a>
                ) : (
                  "--"
                )}
              </div>

              <div className="mb-5">
                <h3 className="text-[#919191] text-base font-medium mb-1">
                  Phone number
                </h3>
                {business?.phone ? (
                  <div className="flex justify-between font-medium">
                    <p>{business?.phone}</p>
                    <BsTelephonePlus className="h-5 w-5" />
                  </div>
                ) : (
                  "--"
                )}
              </div>

              <div>
                <h3 className="text-[#919191] text-base font-medium mb-1">
                  Get directions
                </h3>
                {business?.address ? (
                  <div className="flex justify-between font-medium">
                    <p className="max-w-72">{business?.address}</p>
                    <MdOutlineDirections className="h-5 w-5" />
                  </div>
                ) : (
                  "--"
                )}
              </div>

              {/* <PrimaryButton className="btn-sm secondary-btn py-5 mt-7 !font-normal w-full">
                <RiEditBoxLine className="h-5 w-5 me-2" />
                Suggest an Edit
              </PrimaryButton> */}
            </div>

            <div className="border-y border-[#DADADA] py-4 mt-9">
              <div className="flex justify-between items-center mb-7">
                <h4 className="text-[#0B0B0B] text-2xl font-medium">
                  Location and Hours
                </h4>
                {business?.address && (
                  <PrimaryButton className="text-lg px-0 text-primary-500 !font-semibold bg-transparent border-0 ">
                    <RiEditBoxLine className="h-5 w-5 me-2" />
                    Suggest an edit
                  </PrimaryButton>
                )}
              </div>

              {business?.address && (
                <div className="">
                  <img alt="" src={Map} className="w-full" />
                  <div className="flex justify-between mt-4">
                    <p className="max-w-72 text-[#606060]">
                      {business?.address}
                    </p>
                    <PrimaryButton className="secondary-btn btn-sm">
                      See directions
                    </PrimaryButton>
                  </div>
                </div>
              )}

              {business?.businessHours.length ? (
                <div
                  id="hours"
                  className={`flex flex-col gap-4 mt-7 scroll-mt-[145px] transition-all duration-300 rounded-md ${
                    highlight ? "flash-animation" : ""
                  }`}
                  // style={{
                  //   backgroundColor: highlight
                  //     ? "rgb(179 192 244)"
                  //     : "transparent",
                  // }}
                >
                  {business?.businessHours.map((time: any, index: any) => (
                    <div className="flex gap-8">
                      {/* <div className="text-[#606060] flex flex-col gap-4 font-medium"> */}
                      <p className="text-[#606060] text-nowrap w-[9%] font-medium">
                        {time.dayOfWeek.substring(0, 3)}
                      </p>

                      {/* </div> */}
                      {/* <div className="text-[#606060] flex flex-col gap-4 font-medium"> */}
                      <p className="text-[#606060] font-medium">
                        {time?.openingTime} - {time?.closingTime}
                      </p>

                      {/* </div> */}
                      {time.closed ? (
                        ""
                        // <p className="text-sm text-primary-500">Open now</p>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                "--"
              )}
            </div>

            <div className="border-b border-[#DADADA] pt-4 pb-8 mt-3">
              <div className="flex justify-between items-center mb-5">
                <h4 className="text-[#0B0B0B] text-2xl font-medium">
                  Products & Services
                </h4>
                {/* {business?.services && (
                  <PrimaryButton className="text-lg px-0 text-primary-500 !font-semibold bg-transparent border-0 ">
                    <RiEditBoxLine className="h-5 w-5 me-2" />
                    Suggest an edit
                  </PrimaryButton>
                )} */}
              </div>

              {business?.services ? (
                <div className=" flex gap-3 flex-wrap">
                  <span className="font-medium bg-[#F0F0F0] py-2 px-4 rounded text-base">
                    Restaurant
                  </span>
                </div>
              ) : (
                "--"
              )}
            </div>

            <div className="border-b border-[#DADADA] pt-6 pb-8 mt-3">
              <div className="">
                <h4 className="text-[#0B0B0B] text-[28px] font-medium mb-4">
                  About business
                </h4>

                {business?.about ? (
                  <>
                    <p className="mb-5 text-[#4D4D4D] text-base leading-7 font-medium text-justify">
                      {visibleText}
                    </p>
                    {business?.about.length > 258 && (
                      <PrimaryButton
                        onClick={toggleShowFullText}
                        className="secondary-btn btn-md"
                      >
                        Read {showFullText ? "Less" : "More"}
                      </PrimaryButton>
                    )}
                  </>
                ) : (
                  "--"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
