import React, { useEffect, useState } from "react";
import { Redefine } from "pages/landingPage/components/redefine";
import { FooterComponent, HeaderComponent } from "pages/landingPage/components";
import {
  useGetAllBusinessesQuery,
  useGetAllOpenBusinessesQuery,
} from "store/business";
import {
  SearchBusinessCard,
  OverFlowPages,
  SearchComponent,
} from "../components";
import { PrimaryButton, PrimaryLoader } from "components";
import style from "../../styles/searchComponent.module.scss";
import { useNavigate } from "react-router";
import queryString from "query-string";

export const BusinessSearchPage = () => {
  const navigate = useNavigate();
  const { q } = queryString.parse(window.location.search) as {
    q: string;
    location: string;
  };
  const [searchValue, setSearchValue] = useState(q || "");

  const { data: businesses, isLoading } = useGetAllOpenBusinessesQuery({
    search: q,
  });

  const allBusinesses = businesses?.data;

  console.log(q, searchValue);

  if (isLoading) {
    return <PrimaryLoader height={"90vh"} />;
  }

  // console.log(businesses);
  return (
    <div className="pt-[80px]">
      {/*========== HEADER =========== */}
      <HeaderComponent role="general" />
      {/*========== END OF HEADER =========== */}
      <div className="container">
        <div className="max-w-[700px] w-full lg:pt-9">
          <h1 className="text-[28px] font-medium mb-1">
            Search for a business to review
          </h1>
          <p className="text-sm text-[#606060]">
            Search for businesses, from your favourite amala joint to your
            photography plug
          </p>
          <SearchComponent
            onSearch={(val) => setSearchValue(val)}
            businesses={businesses?.data}
          />

          <section>
            {allBusinesses?.length ? (
              allBusinesses?.map((business: any, index: any) => (
                <SearchBusinessCard business={business} />
              ))
            ) : (
              <div className={style.errorDiv}>
                {/* <div className={style.closeButton}>
              <button className="flex border-none">
                <CloseButton
                  colorScheme="#001C89"
                  size="sm"
                  style={{
                    width: "16px",
                    height: "16px",
                    border: "none",
                    marginTop: "5px",
                  }}
                />
                <span className=""> close </span>
              </button>
            </div> */}

                <h1>Oopsiee... Can’t find the business you want to review?</h1>

                <PrimaryButton
                  onClick={() => navigate("/create-thread")}
                  className="btn-lg primary-btn me-3 mt-5"
                >
                  Create a Thread
                </PrimaryButton>
              </div>
            )}

            {/* <OverFlowPages /> */}
          </section>
        </div>

        <Redefine />
      </div>
      {/*========== FOOTER =========== */}
      <FooterComponent />
      {/*========== END OF FOOTER =========== */}
    </div>
  );
};
