// import { AuthBoxedContainer, DashboardBaseViewContainer } from 'containers';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as Pages from "pages";
import ScrollToTop from "./ScrollToTop";
import { CommentSection } from "pages/forum/CommentSection";
import { GeneralAppProvider } from "providers";

export const RootNavigator = () => {
    return (
        <BrowserRouter>
            <GeneralAppProvider>
                <ScrollToTop>
                    <Routes>
                        <Route index path="/" element={<Pages.HomePage />} />
                        <Route path="company" element={<Pages.Company />} />
                        <Route
                            path="tracker-for-business"
                            element={<Pages.TrackaForBusiness />}
                        />
                        <Route path="support/*" element={<Pages.Support />} />
                        <Route path="review" element={<Pages.WriteReview />} />
                        <Route path="forum" element={<Pages.Forum />} />
                        <Route
                            path="ask-community"
                            element={<Pages.AskCommunity />}
                        />
                        <Route
                            path="create-thread"
                            element={<Pages.CreateThread />}
                        />

                        <Route path="comment" element={<CommentSection />} />

                        <Route
                            path="businesses"
                            element={<Pages.BusinessSearchPage />}
                        />
                        <Route
                            path="businesses/:id"
                            element={<Pages.BusinessPage />}
                        />
                        <Route
                            path="businesses/notfound"
                            element={<Pages.NoBusinessFound />}
                        />

                        {/* Auth Routes  */}
                        <Route path="/">
                            <Route
                                path="sign-up/business"
                                element={<Pages.BusinessAuth />}
                            />
                            <Route path="login" element={<Pages.LoginPage />} />
                            <Route
                                path="sign-up/individual"
                                element={<Pages.SignupPage />}
                            />
                            <Route
                                path="account-type"
                                element={<Pages.ProfileType />}
                            />
                            <Route
                                path="forgot-password"
                                element={<Pages.ForgotPassword />}
                            />
                            <Route
                                path="terms-and-conditions"
                                element={<Pages.TermsConditions />}
                            />
                            <Route
                                path="privacy"
                                element={<Pages.PrivacyPolicy />}
                            />
                            <Route
                                path="reset-password"
                                element={<Pages.ResetPassword />}
                            />
                            <Route
                                path="verify-email"
                                element={<Pages.VerifyEmailPage />}
                            />

                            <Route
                                path="verify-email/status"
                                element={<Pages.VerifyEmailStatusPage />}
                            />
                            <Route
                                path="create-profile"
                                element={<Pages.CreateProfilePage />}
                            />
                        </Route>
                        <Route
                            path="auth-redirect"
                            element={<Pages.AuthRedirect />}
                        />
                        {/* End of Auth Routes  */}

                        {/* Shopper Routes  */}
                        <Route
                            path="/shopper"
                            element={<Pages.ShopperContainer />}
                        >
                            <Route
                                path="profile"
                                element={<Pages.MyProfile />}
                            />

                            <Route
                                path="change-password"
                                element={<Pages.ChangePassword />}
                            />
                            <Route
                                path="account-settings"
                                element={<Pages.AccountSetting />}
                            />
                            <Route
                                path="review-history"
                                element={<Pages.ReviewHistory />}
                            />
                        </Route>

                        {/* End of Shopper Routes  */}

                        {/* Business Routes  */}

                        <Route
                            path="/business"
                            element={<Pages.BusinessContainer />}
                        >
                            <Route
                                path="dashboard"
                                element={<Pages.BusinessDashboardPage />}
                            />
                            <Route
                                path="read-reviews"
                                element={<Pages.ReadReview />}
                            />
                            <Route
                                path="business-info"
                                element={<Pages.BusinessInfo />}
                            />
                            <Route
                                path="edit-suggestions"
                                element={<Pages.EditSuggestion />}
                            />

                            <Route
                                path="add-photo"
                                element={<Pages.AddPhoto />}
                            />
                            <Route
                                path="questions"
                                element={<Pages.QuestionsPage />}
                            />
                            <Route
                                path="account-settings"
                                element={<Pages.BusinessAccountSettings />}
                            />
                            <Route
                                path="change-password"
                                element={<Pages.ChangePassword />}
                            />
                        </Route>

                        {/* End of Business Routes  */}
                        <Route path="/forum" element={<Pages.Forum />} />
                        <Route
                            path="create-thread"
                            element={<Pages.CreateThread />}
                        />

                        <Route path="comment" element={<CommentSection />} />
                    </Routes>
                </ScrollToTop>
            </GeneralAppProvider>
        </BrowserRouter>
    );
};
