import React, { useEffect } from "react";
import style from "./../../styles/dashboard.module.scss";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router";
import {
    IBusinessImages,
    useGetMyBusinessQuery,
    usePostBusinessUploadMutation,
    useUpdateBusinessMutation,
} from "store/business";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { resolveApiError } from "utilities/errorHandling";
import { DropzoneFile, PrimaryDropzone } from "components/inputs";
import { PrimaryButton } from "components/index";
import { ReactComponent as ImgIcon } from "assets/svg/img-placeholder-blue.svg";
import { useAuth } from "store/auth";

export const AddPhoto = () => {
    const initialValues = {
        address: "",
    };
    const navigate = useNavigate();
    const { user } = useAuth();
    const { initNotification } = usePageNotificationProvider();
    const [postBusinessUpload, { upLoading }] = usePostBusinessUploadMutation();
    const [uploadedPhoto, setUploadedPhoto] = React.useState<IBusinessImages[]>(
        []
    );
    const { data: businessInfo, refetch } = useGetMyBusinessQuery(
        user?.id || ""
    );
    const [updateBusiness] = useUpdateBusinessMutation();
    const { businessImages = [] }: { businessImages: IBusinessImages[] } =
        businessInfo?.data?.[0] || {};

    const handleFileUpload = (file: DropzoneFile[]) => {
        const formData = new FormData();
        formData.append("file", file[0].data);
        formData.append("type", "photo");
        formData.append("photo", file[0]?.data);

        postBusinessUpload(formData)
            .unwrap()
            .then(
                (res: {
                    status: any;
                    error: any;
                    message: any;
                    data: IBusinessImages;
                }) => {
                    setUploadedPhoto((prev: any) => [...prev, res.data]);
                    console.log(res.data);
                    updateBusiness({
                        businessData: {
                            businessImageIDs: [uploadedPhoto[0].id],
                        },
                        id: businessInfo?.data[0]?.id,
                    })
                        .unwrap()
                        .then((res: any) => {
                            initNotification({
                                scheme: "success",
                                message: res.message,
                            });
                            refetch();
                        })
                        .catch((err: any) =>
                            initNotification({
                                scheme: "error",
                                message: err?.data?.message || err.error,
                            })
                        );
                }
            )
            .catch((error: any) => {
                console.log(error);
                if (
                    resolveApiError(error) ===
                    "Authorization header is required"
                ) {
                    initNotification({
                        message: "Please login to continue",
                        scheme: "error",
                    });
                    navigate("/login");
                    return;
                }
                initNotification({
                    message: resolveApiError(error),
                    scheme: "error",
                });
            });
    };

    useEffect(() => {
        console.log(businessInfo?.data[0]);
    }, []);

    return (
        <>
            <div className={`container ${style.photos_section}`}>
                <span
                    className={`${style.back_btn} flex items-center mb-3`}
                    onClick={() => navigate(-1)}
                >
                    <BiChevronLeft /> back
                </span>
                {businessImages.length ? (
                    <PhotoGallery
                        handleFileUpload={handleFileUpload}
                        businessImages={businessImages}
                    />
                ) : (
                    <div className={style.photos_section_cont}>
                        <h1 className={style.section_title}>Photos</h1>
                        <div className={style.photos_container}>
                            <p className={style.no_photos_text}>
                                You do not have images yet
                            </p>

                            <PrimaryDropzone
                                type="logo"
                                onChange={(file) => handleFileUpload(file)}
                            />

                            <div className="flex overflow-x-auto scrollbar-hide space-x-3 p-2">
                                {!!uploadedPhoto.length &&
                                    uploadedPhoto.map((image, index) => (
                                        <img
                                            key={index}
                                            src={image?.imageUrl}
                                            alt={`placeholder ${index}`}
                                            className="flex-none w-36 h-36 object-cover"
                                        />
                                    ))}
                            </div>
                            {/* 
                            <PrimaryButton
                                disabled={files.length === 0}
                                onClick={initRequest}
                                className="primary-btn py-6 px-10"
                            >
                                Upload Photos
                            </PrimaryButton> */}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

function PhotoGallery({
    businessImages,
    handleFileUpload,
}: {
    businessImages: IBusinessImages[];
    handleFileUpload: (file: DropzoneFile[]) => void;
}) {
    return (
        <div className="bg-white py-8 px-6 rounded">
            <h2 className="text-3xl font-semibold">Photos</h2>
            <br />
            <div className="grid lg:grid-col-5 md:grid-cols-4 grid-cols-2 gap-6">
                <div className="max-h-72 bg-slate-50 rounded min-h-48">
                    <label
                        htmlFor="photos"
                        className="w-full h-full border-double border-2 flex flex-col items-center justify-center text-center cursor-pointer font-semibold"
                    >
                        <ImgIcon className="mb-5 w-7 h-7" />
                        Add Photo
                    </label>
                    <input
                        type="file"
                        onChange={(e) => {
                            if (e.target.files) {
                                handleFileUpload([
                                    {
                                        data: e.target.files[0],
                                        name: e.target?.files[0]?.name,
                                    },
                                ]);
                            }
                        }}
                        hidden
                        name="photos"
                        id="photos"
                    />
                </div>

                {businessImages.map((image) => (
                    <div className="max-h-72 bg-slate-50 rounded min-h-48">
                        <img
                            src={image.imageUrl}
                            className="w-full h-full object-cover"
                            alt="man in factory"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}
