import * as Yup from "yup";

export const ReviewValidationSchema = Yup.object().shape({
  business: Yup.string().required("Business is required"),
  overallRating: Yup.number()
    .min(1, "Must be at least 1")
    .max(5, "Must be at most 5")
    .required("Overall rating is required"),
  customerServiceRating: Yup.number()
    .min(1, "Must be at least 1")
    .max(5, "Must be at most 5")
    .required("Customer service rating is required"),
  productQualityRating: Yup.number()
    .min(1, "Must be at least 1")
    .max(5, "Must be at most 5")
    .required("Product quality rating is required"),
  valueForMoneyRating: Yup.number()
    .min(1, "Must be at least 1")
    .max(5, "Must be at most 5")
    .required("Value for money rating is required"),
  deliveryRating: Yup.number()
    .min(1, "Must be at least 1")
    .max(5, "Must be at most 5")
    .required("Delivery rating is required"),
  comment: Yup.string().required("Comment is required"),
  experience: Yup.string().required("Experience is required"),
  dateOfExperience: Yup.string().required("Date of experience is required"),
  files: Yup.array().of(Yup.mixed()).required("At least one file is required"),
});
