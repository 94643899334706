import { AccordionComponent } from "components/accordion/accordion";

const shopperFaq = [
    {
        title: "What is TradeTracka?",
        content: (
            <div>
                TradeTracka is a platform designed to enhance trust between
                consumers and businesses in Nigeria through verified customer
                reviews and ratings.
            </div>
        ),
    },

    {
        title: "How do I register on TradeTracka?",
        content: (
            <div>
                You can register by visiting our website or downloading our
                mobile app and filling in the required details in the
                registration form.
            </div>
        ),
    },

    {
        title: "Is TradeTracka free to use?",
        content: (
            <div>
                Yes, accessing TradeTracka and submitting reviews is free for
                consumers. Businesses may access basic features for free and opt
                for paid subscriptions for advanced features.
            </div>
        ),
    },

    {
        title: "Can I delete my review?",
        content: (
            <div>
                Yes, you can delete your review at any time. Simply log in to
                your account, find the review and select the option to delete.
            </div>
        ),
    },

    {
        title: "How does TradeTracka use my data?",
        content: (
            <div>
                We use your data to manage your account, provide customer
                service, and improve our services. For detailed information,
                please refer to our Privacy Policy.
            </div>
        ),
    },

    {
        title: "What if I forget my password?",
        content: (
            <div>
                You can reset your password by clicking on the "Forgot Password"
                link on the login page and following the instructions to set a
                new password.
            </div>
        ),
    },

    {
        title: "How can I contact TradeTracka?",
        content: (
            <div>
                You can contact us via email at{" "}
                <a href="mailto:info@tradetracka.io">info@tradetracka.io</a> or
                through our website's contact form.
            </div>
        ),
    },
];

const FaqShoppers = () => {
    return (
        <div className="flex items-center justify-center md:pt-24 pt-10 flex-col pb-20 w-full">
            <h3 className="text-center text-4xl mb-10 font-semibold">
                Frequently asked questions shoppers
            </h3>

            <div className="w-full max-w-4xl">
                <AccordionComponent items={shopperFaq} />
            </div>
        </div>
    );
};

export default FaqShoppers;
