/* eslint-disable no-lone-blocks */

import { MdOutlineInsertComment } from "react-icons/md";
import { FooterComponent, HeaderComponent } from "./components";
import style from "../styles/homePage.module.scss";
import SmilingWoman from "assets/svg/smiling-woman-on-phone.svg";
import BlackManWtBags from "assets/svg/black-man-with-bags.svg";
import ThinkingGears from "assets/svg/thinking-gears.svg";
import TradeIcon from "assets/svg/trade-icon.svg";
import TrustIcon from "assets/svg/trust-icon.svg";
import DatabaseIcon from "assets/svg/database-icon.svg";
import LabOfTomorrow from "assets/svg/lot-logo.svg";
import Seedstars from "assets/svg/seedstars-logo.svg";
import Giz from "assets/svg/giz-logo.svg";
import Food from "assets/svg/food.svg";
import Rating from "assets/svg/starRating.svg";
import { ReviewsComponent } from "./components/reviewsComponent";
import { Redefine } from "./components/redefine";
import { CiLocationOn, CiSearch } from "react-icons/ci";
import { PrimaryButton } from "components/buttons";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SearchComponent } from "pages/shopper";

export const HomePage = () => {
  return (
    <div style={{ paddingTop: "84px" }}>
      {/*========== HEADER =========== */}
      <HeaderComponent role="general" />
      {/*========== END OF HEADER =========== */}

      <section className="bg-white">
        <HomeHero />
        <WhatWeDo />
        <ReviewsComponent />
        <WhyTradeTracka />
        <Partners />
        <NewCustomer />
        <Redefine />
      </section>

      {/*========== FOOTER =========== */}
      <FooterComponent />
      {/*========== END OF FOOTER =========== */}
    </div>
  );
};

export default HomePage;

//!! ============== HOME HERO COMPONENT ?? //
export function HomeHero() {
  // const navigate = useNavigate();
  return (
    <div className="container">
      <div className={style.imageContainer}>
        <div className={style.bgdtext}>
          <h2>Make Every Purchase Count</h2>
          <p>
            Our AI-powered platform is your assurance of transparency,
            credibility, and unwavering confidence in every online purchase.
          </p>

          <SearchComponent heroSearch={true} />

          {/* <div className={style.search}>
            <div className={style.inputDiv}>
              <input type="search" placeholder="Search for businesses" />
            </div>

            <div className={style.inputDiv}>
              <div className="flex items-center w-full h-full">
                <CiLocationOn
                  style={{
                    marginTop: "10px",
                    marginRight: "5px",
                    marginBottom: "10px",
                  }}
                />
                <input type="search" placeholder="Location e.g. Oniru, Lagos" />
              </div>
            </div>

            <PrimaryButton
              className="btn-xlg primary-btnPrimary flex"
              style={{ border: "2px solid #001C89" }}
            >
              {" "}
              <CiSearch /> <span>Search</span>{" "}
            </PrimaryButton>
          </div> */}
        </div>
      </div>
    </div>
  );
}

//!! ============== WHAT WE DO COMPONENT ?? //
export function WhatWeDo() {
  const navigate = useNavigate();
  return (
    <>
      <div className={style.featureArea}>
        <div className="container">
          <div className={style.featureGroup}>
            <div className={style.featuredOne}>
              <div className={style.header}>
                <h2>WHAT WE DO</h2>
              </div>
              <div className={style.headerBig}>
                <h2>We help you shop smarter</h2>
              </div>

              <div className={style.features}>
                <div className={style.featuredPart1}>
                  <div className={style.featuredIcon}>
                    <img alt="" src={DatabaseIcon} />
                  </div>
                  <div className={style.featuredPartHeader}>
                    <h2>Data Aggregation</h2>
                    <p>
                      We gather customer reviews and ratings from various online
                      sources to give you comprehensive information.
                    </p>
                  </div>
                </div>

                <div className={style.featuredPart2}>
                  <div className={style.featuredIcon}>
                    <img alt="" src={ThinkingGears} />
                  </div>
                  <div className={style.featuredPartHeader}>
                    <h2>AI Analysis</h2>
                    <p>
                      Our AI algorithms scrutinizes every review and rating,
                      identifying patterns, and flagging suspicious activity.
                    </p>
                  </div>
                </div>
              </div>

              <div className={style.bButton}>
                <PrimaryButton className="btn-lg primary-btn me-3">
                  <Link
                    to={"/sign-up/individual"}
                    className="text-lg leading-none"
                  >
                    Get Started{" "}
                  </Link>
                </PrimaryButton>
              </div>
            </div>

            {/* IMAGE PART */}
            <div className={style.featuredTwo}>
              <img alt="" src={SmilingWoman} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

//!! ============== WHY TRADE TRACKA COMPONENT ?? //
export function WhyTradeTracka() {
  const navigate = useNavigate();
  return (
    <>
      <div className={style.whyTradeTracka}>
        <div className="container">
          <div className={style.sizeFeature}>
            {/* IMAGE PART */}
            <div className={style.imagePart}>
              <img alt="" src={BlackManWtBags} />
            </div>
            <div>
              <div className={style.textPart}>
                <div className={style.textPartHeader}>
                  <h4>WHY TRADE TRACKA</h4>
                </div>
                <h1> We prioritize your trust</h1>

                <div className={style.features}>
                  <div className={style.featuredPart1}>
                    <div className={style.featuredIcon}>
                      <img alt="" src={TradeIcon} />
                    </div>
                    <div className={style.featuredPartHeader}>
                      <h2>Reliable insight</h2>
                      <p>
                        We provide clear and concise insights, ensuring that you
                        have reliable data to help you shop smarter.
                      </p>
                    </div>
                  </div>

                  <div className={style.featuredPart2}>
                    <div className={style.featuredIcon}>
                      <img alt="" src={TrustIcon} />
                    </div>
                    <div className={style.featuredPartHeader}>
                      <h2>Verified Trust</h2>
                      <p>
                        Whether you're looking for the perfect product, a new
                        restaurant to try, or a service provider to trust, we’ve
                        got your back
                      </p>
                    </div>
                  </div>
                </div>

                <div className={style.bButton}>
                  <PrimaryButton className="btn-lg primary-btn">
                    <Link
                      className="text-lg leading-none"
                      to={"/sign-up/individual"}
                    >
                      Join Us
                    </Link>
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const Partners = () => {
  return (
    <div className="lg:py-24 py-10">
      <div className="container">
        <div className="text-center">
          <h2 className="mb-10 text-4xl font-medium">
            We are in good company of partners
          </h2>
          <div className="flex items-center justify-center flex-wrap gap-9">
            <img src={LabOfTomorrow} alt=" Lab Of Tomorrow" />
            <img src={Seedstars} alt="Seedstars" />
            <img src={Giz} alt="Giz" />
          </div>
        </div>
      </div>
    </div>
  );
};

//!! ============== GET NEW CUSTOMERS COMPONENT ?? //
export function NewCustomer() {
  const navigate = useNavigate();
  return (
    <>
      <div className="container">
        <div className={style.getNewCustomers}>
          <div className={style.gNCtext}>
            <div className={style.divGNCText}>
              <h1>Get new customers</h1>
              <p>
                List your business on Trade tracka and get seen by more people
              </p>
            </div>
            <div className={style.bButton}>
              <button
                className={style.blueButton}
                onClick={() => navigate("/")}
              >
                {" "}
                Learn More{" "}
              </button>
            </div>
          </div>

          {/* <div className="w-full overflow-hidden overflow-x-auto">
            <div className="my-6 w-fit flex items-center gap-4 flex-nowrap">
              <div className={style.cards}>
                <img alt="" src={Food} />
                <div className={style.nameAndRatings}>
                  <div className={style.name}>
                    <p> Canada Rice Shop</p>
                  </div>
                  <div className={style.rating}>
                    <img alt="" src={Rating} />
                    <p> 5.0 </p>{" "}
                  </div>
                </div>
                <div className={style.commentsNo}>
                  {" "}
                  <MdOutlineInsertComment
                    style={{
                      color: "white",
                      fontSize: "25px",
                    }}
                  />{" "}
                  <p> (20) </p>{" "}
                </div>
              </div>
              <div className={style.cards}>
                <img alt="" src={Food} />

                <div className={style.nameAndRatings}>
                  <div className={style.name}>
                    <p> Canada Rice Shop</p>
                  </div>
                  <div className={style.rating}>
                    <img alt="" src={Rating} />
                    <p> 5.0 </p>{" "}
                  </div>
                </div>
                <div className={style.commentsNo}>
                  {" "}
                  <MdOutlineInsertComment
                    style={{
                      color: "white",
                      fontSize: "25px",
                    }}
                  />{" "}
                  <p> (20) </p>{" "}
                </div>
              </div>
              <div className={style.cards}>
                <img alt="" src={Food} />

                <div className={style.nameAndRatings}>
                  <div className={style.name}>
                    <p> Canada Rice Shop</p>
                  </div>
                  <div className={style.rating}>
                    <img alt="" src={Rating} />
                    <p> 5.0 </p>
                  </div>
                </div>
                <div className={style.commentsNo}>
                  {" "}
                  <MdOutlineInsertComment
                    style={{
                      color: "white",
                      fontSize: "25px",
                    }}
                  />{" "}
                  <p> (20) </p>{" "}
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
