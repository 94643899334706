import { HeaderComponent } from "pages/landingPage";
import { FormikErrors, useFormik } from "formik";
import style from "./../styles/writereview.module.scss";
import {
    DropzoneFile,
    PrimaryDropzone,
    PrimaryInput,
    PrimaryTextarea,
} from "components/inputs";
import { useEffect, useState } from "react";
import { MdOutlineStarBorder } from "react-icons/md";
import {
    useGetAllBusinessesQuery,
    useGetAllOpenBusinessesQuery,
} from "store/business";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as CloseIcon } from "assets/svg/close.svg";
import verifiedIcon from "assets/png/verified-blue-icon.png";
import { IBusinessReview, ReviewFormData } from "store/review/interface";
import { ReviewValidationSchema } from "validations/review";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { useDispatch } from "react-redux";
import { resolveApiError } from "utilities/errorHandling";
import {
    useEditBusinessReviewMutation,
    usePostBusinessReviewMutation,
    useUploadBusinessReviewPhotoMutation,
} from "store/review";
import { PrimaryButton } from "components/index";
import queryString from "query-string";
import { useAuth } from "store/auth";
import { IBusinessInterface } from "pages/auth/business/business-Interface";
import Select from "react-dropdown-select";

const ratings = ["Very bad", "Fair", "Average", "Very good", "Perfect"];

export const WriteReview = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const storedValues = JSON.parse(localStorage.getItem("reviewFormValues")!);

    const initialValues = {
        business: "",
        rating: 0,
        customerService: 0,
        quality: 0,
        valueForMoney: 0,
        deliveryRating: 0,
        comment: "",
        dateOfExperience: "",
        BusinessReviewImages: [] as number[],
    };

    const { business, name } = queryString.parse(window.location.search) as {
        business: string;
        name: string;
    };
    const { data: businessesList, isLoading: loadingBusinesses } =
        useGetAllOpenBusinessesQuery({
            search: searchTerm,
        });
    const [businesses, setBusinesses] = useState<{
        data: IBusinessInterface[];
    } | null>(null);
    const { user } = useAuth();
    const [request, { isLoading }] = usePostBusinessReviewMutation();
    const [editBusinessReview, { isLoading: editLoading }] =
        useEditBusinessReviewMutation();
    const { initNotification } = usePageNotificationProvider();
    const navigate = useNavigate();
    const [businessId, setBusinessId] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [editState, setEditState] = useState<{
        state: string;
        review: IBusinessReview;
    }>(JSON.parse(localStorage.getItem("review-state")!) || "{}");
    const [files, setFiles] = useState<any>(
        JSON.parse(localStorage.getItem("reviewFiles")!) || []
    );

    // const allBusinesses: IBusinessInterface[] = businesses?.data;

    const {
        values,
        errors,
        touched,
        setValues,
        handleChange,
        handleReset,
        setFieldValue,
        handleSubmit,
    } = useFormik<ReviewFormData>({
        initialValues: storedValues ?? {
            ...initialValues,
            business: business || "",
        },
        validationSchema: ReviewValidationSchema,
        onSubmit: async (values) => {},
    });

    useEffect(() => {
        setBusinesses(businessesList);
    }, [businessesList]);

    useEffect(() => {
        // first check that the id doesnt already exist in the businesses.data list
        for (let i = 0; i < businessesList?.data.length; i++) {
            if (Number(businessesList?.data[i].id) === Number(business)) {
                console.log(businessesList?.data[i].id);
                return;
            }
        }
        if (businessesList?.data) {
            const reviewingBusiness: IBusinessInterface = {
                id: Number(business),
                businessName: name,
            } as IBusinessInterface;
            setBusinesses({
                data: [...businessesList?.data, reviewingBusiness],
            });
        }
    }, [businessesList, business, name, businesses?.data]);

    const handleFormSubmit = (e: any) => {
        e.preventDefault();

        if (!user) {
            initNotification({
                message: "Please login to continue",
                scheme: "error",
            });
            localStorage.setItem("reviewFormValues", JSON.stringify(values));
            localStorage.setItem("reviewFiles", JSON.stringify(files));
            localStorage.setItem("returnTo", "/review");
            navigate("/login");
            return;
        }

        // submit the edit else submit the regular
        if (editState.state === "edit") {
            handleEditReviewSubmit();
            return;
        }

        request({
            ...values,
            BusinessReviewImages: files.map((file: { id: number }) => file.id),
        })
            .unwrap()
            .then((res: any) => {
                if (res.error) {
                    initNotification({
                        message: res.message,
                        scheme: "error",
                    });
                    return;
                }

                localStorage.removeItem("reviewFormValues");
                localStorage.removeItem("returnTo");
                initNotification({
                    message: "You have successfully posted a review.",
                });
                navigate("/review");
                setValues(initialValues);
                setFiles([]);
                setBusinessId("");
                setSubmitted(true);
            })
            .catch((error: any) => {
                initNotification({
                    message: resolveApiError(error),
                    scheme: "error",
                });
            });
    };

    const handleEditReviewSubmit = () => {
        editBusinessReview({
            review: { ...values },
            reviewid: editState.review.id,
        })
            .unwrap()
            .then((res: any) => {
                if (res.error) {
                    initNotification({
                        message: res.message,
                        scheme: "error",
                    });
                    return;
                }

                localStorage.removeItem("reviewFormValues");
                localStorage.removeItem("returnTo");
                initNotification({
                    message: "You have successfully edited a review.",
                });
                navigate("/review");
                setValues(initialValues);
                setFiles([]);
                setBusinessId("");
                setSubmitted(true);
            })
            .catch((error: any) => {
                initNotification({
                    message: resolveApiError(error),
                    scheme: "error",
                });
            });
    };

    const ratingCategory: {
        label: string;
        value: number;
        type: keyof ReviewFormData;
    }[] = [
        {
            label: "Overall rating",
            value: values.rating,
            type: "rating",
        },
        {
            label: "Customer service",
            value: values.customerService,
            type: "customerService",
        },
        {
            label: "Product quality",
            value: values.quality,
            type: "quality",
        },
        {
            label: "Value for money",
            value: values.valueForMoney,
            type: "valueForMoney",
        },
        {
            label: "Delivery",
            value: values.deliveryRating,
            type: "deliveryRating",
        },
    ];

    // const handleChange = (
    //   e: React.ChangeEvent<
    //     HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    //   >
    // ) => {
    //   const { name, value } = e.target;

    //   if (name.startsWith("reviewData.")) {
    //     const fieldName = name.replace(
    //       "reviewData.",
    //       ""
    //     ) as keyof typeof values;
    //     setFieldValue(`reviewData.${fieldName}`, value);
    //   } else {
    //     setFieldValue(name, value);
    //   }

    //   // if (name === "reviewData.business") {
    //   //   setBusinessId(value);
    //   // }
    // };

    const [uploadBusinessReviewFiles, { isLoading: loading }] =
        useUploadBusinessReviewPhotoMutation();

    const handleFileUpload = (file: DropzoneFile[]) => {
        // if (errorMsg) return;
        const formData = new FormData();
        formData.append("files", file[0]?.data);

        uploadBusinessReviewFiles(formData)
            .unwrap()
            .then(
                (res: {
                    error: any;
                    message: any;
                    data: { id: number; image: string }[];
                }) => {
                    if (res.error) {
                        if (
                            res.message === "Authorization header is required"
                        ) {
                            initNotification({
                                message: "Please login to continue",
                                scheme: "error",
                            });
                            localStorage.setItem(
                                "reviewFormValues",
                                JSON.stringify(values)
                            );
                            localStorage.setItem(
                                "reviewFiles",
                                JSON.stringify(files)
                            );
                            localStorage.setItem("returnTo", "/review");
                            navigate("/login");
                            return;
                        }
                        initNotification({
                            message: res.message,
                            scheme: "error",
                        });
                        localStorage.setItem(
                            "reviewFormValues",
                            JSON.stringify(values)
                        );
                        localStorage.setItem(
                            "reviewFiles",
                            JSON.stringify(files)
                        );
                        localStorage.setItem("returnTo", "/review");
                        navigate("/login");
                        return;
                    }
                    setFiles((prev: any) => [...prev, res.data[0]]);
                }
            )
            .catch((error: any) => {
                console.log(error);
                if (
                    resolveApiError(error) ===
                    "Authorization header is required"
                ) {
                    initNotification({
                        message: "Please login to continue",
                        scheme: "error",
                    });
                    localStorage.setItem(
                        "reviewFormValues",
                        JSON.stringify(values)
                    );
                    localStorage.setItem("reviewFiles", JSON.stringify(files));
                    localStorage.setItem("returnTo", "/review");
                    navigate("/login");
                    return;
                }
                initNotification({
                    message: resolveApiError(error),
                    scheme: "error",
                });
            });
        // if (refetch) {
        //   setTimeout(() => {
        //     refetch();
        //   }, 2000);
        // }
    };

    // const handleChange = (
    //   e: React.ChangeEvent<
    //     HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    //   >
    // ) => {
    //   const { name, value } = e.target;

    //   if (name.startsWith("reviewData.")) {
    //     const fieldName = name.replace(
    //       "reviewData.",
    //       ""
    //     ) as keyof typeof values;
    //     setFieldValue(`reviewData.${fieldName}`, value);
    //   } else {
    //     setFieldValue(name, value);
    //   }

    //   // if (name === "reviewData.business") {
    //   //   setBusinessId(value);
    //   // }
    // };

    const handleRating = (ratingType: keyof ReviewFormData, value: number) => {
        setValues((prevValues) => ({
            ...prevValues,
            [ratingType]: value,
        }));
    };

    useEffect(() => {
        if (editState.state === "edit") {
            setValues({
                business: editState.review.business.id,
                rating: editState.review.rating,
                customerService: editState.review.customerService,
                quality: editState.review.quality,
                valueForMoney: editState.review.valueForMoney,
                deliveryRating: editState.review.deliveryRating,
                comment: editState.review.comment,
                dateOfExperience: editState.review.updatedAt,
            });
        }

        window.addEventListener("unload", () =>
            localStorage.removeItem("review-state")
        );

        return () => {
            localStorage.removeItem("review-state");
        };
    }, []);

    return (
        <>
            {submitted && <SuccessPopUp onClose={() => setSubmitted(false)} />}
            <HeaderComponent role="general" />
            <div className={style.write_review}>
                <h2>Write a review</h2>
                <div>
                    <form
                        onSubmit={handleFormSubmit}
                        className="w-full max-w-xxl px-8 pt-6 pb-8 mb-4"
                    >
                        <div className={`${style.ratings_cont} mb-4`}>
                            <div className={style.form_group}>
                                <label>
                                    Select the business you want to review
                                </label>
                                <div>
                                    <Select
                                        options={businesses?.data as any}
                                        onChange={(value: any) =>
                                            setFieldValue(
                                                "business",
                                                value[0]?.id
                                            )
                                        }
                                        loading={loadingBusinesses}
                                        // name="businessName"
                                        searchable={true}
                                        valueField="businessName"
                                        labelField="businessName"
                                        values={
                                            businesses?.data.filter(
                                                (b: { id: number }) =>
                                                    b.id === Number(business)
                                            ) || []
                                        }
                                    />
                                </div>
                                {/* <select
                  name="business"
                  value={values.business}
                  // defaultValue={business}
                  onChange={handleChange}
                  // required
                >
                  <option value="">Select business</option>
                  {isLoading ? (
                    <option disabled>Loading...</option>
                  ) : (
                    businesses?.data?.map((business: any) => (
                      <option key={business?.id} value={business?.id}>
                        {business?.businessName}
                      </option>
                    ))
                  )}
                </select> */}
                                {touched?.business && errors?.business && (
                                    <div className="error">
                                        {errors.business}
                                    </div>
                                )}
                            </div>
                            <div className={style.form_group}>
                                <label htmlFor="rating">
                                    Select your rating
                                </label>
                                <div className={style.ratings_group}>
                                    <div className="p-1">
                                        {ratingCategory.map((rating) => (
                                            <div
                                                className={`${style.rating} flex justify-between items-center mb-1`}
                                                key={rating.label}
                                            >
                                                <div
                                                    className={
                                                        style.rating_label
                                                    }
                                                >
                                                    {rating.label}
                                                </div>
                                                <div
                                                    className={style.star_group}
                                                >
                                                    {ratings.map(
                                                        (
                                                            ratingLabel,
                                                            index
                                                        ) => (
                                                            <div
                                                                key={`${rating.type}-${index}`}
                                                                className={
                                                                    style.star_box
                                                                }
                                                                onClick={() =>
                                                                    handleRating(
                                                                        rating.type,
                                                                        index +
                                                                            1
                                                                    )
                                                                }
                                                            >
                                                                <MdOutlineStarBorder
                                                                    cursor="pointer"
                                                                    className={
                                                                        values[
                                                                            rating
                                                                                .type
                                                                        ] ==
                                                                        index +
                                                                            1
                                                                            ? `${style.active}`
                                                                            : `${style.star}`
                                                                    }
                                                                />
                                                                <p>
                                                                    {
                                                                        ratingLabel
                                                                    }
                                                                </p>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <div className={style.form_group}>
                                <label htmlFor="comment">
                                    Write about your experience here
                                </label>
                                <PrimaryTextarea
                                    id="comment"
                                    name="comment"
                                    value={values.comment}
                                    onChange={handleChange}
                                    placeholder="Write about your experience here"
                                    className={style.text_area}
                                />
                                {touched?.comment && errors?.comment && (
                                    <div className="error">
                                        {errors.comment}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="mb-4">
                            <div className={style.form_group}>
                                <label htmlFor="dateOfExperience">
                                    Date of your experience
                                </label>
                                <PrimaryInput
                                    type="date"
                                    id="dateOfExperience"
                                    name="dateOfExperience"
                                    value={values.dateOfExperience}
                                    onChange={handleChange}
                                    placeholder="Write about your experience here"
                                    className={`${style.review_input} py-2`}
                                />
                                {touched?.dateOfExperience &&
                                    errors?.dateOfExperience && (
                                        <div className="error">
                                            {errors.dateOfExperience}
                                        </div>
                                    )}
                            </div>
                        </div>
                        <div className="mb-4">
                            <div className={style.form_group}>
                                <label htmlFor="photos">Add Photos</label>
                                <PrimaryDropzone
                                    fileClass=""
                                    // setFile={setFiles}
                                    onChange={(file) => handleFileUpload(file)}
                                />
                                {/* {touched.files && errors.files && (
                  <div className="error">{errors.files}</div>
                )} */}
                            </div>
                        </div>

                        <div className="mt-4 mb-4">
                            {files.length > 0 && (
                                <div className={style.image_preview_container}>
                                    {files.map((file: any, index: string) => (
                                        <div
                                            key={index}
                                            className={style.image_preview}
                                        >
                                            <img
                                                src={file.image}
                                                alt={`Preview ${index}`}
                                                className="object-cover rounded"
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div className="flex items-center justify-center sm:justify-start gap-10 mt-4">
                            <PrimaryButton
                                type="submit"
                                className="btn-lg primary-btn mt-8 align-self-baseline"
                                isLoading={isLoading}
                            >
                                Post Review
                            </PrimaryButton>
                            <PrimaryButton
                                className="btn-lg secondary-btn mt-8 align-self-baseline"
                                type="button"
                                onClick={handleReset}
                            >
                                Cancel
                            </PrimaryButton>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

interface SuccessPopUpProps {
    onClose: () => void;
}

const SuccessPopUp: React.FC<SuccessPopUpProps> = ({ onClose }) => {
    return (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-3xl shadow-lg p-6 w-full max-w-md mx-4 relative">
                <div
                    className="flex items-center justify-end w-full py-4 px-6"
                    onClick={onClose}
                >
                    <CloseIcon className="text-3xl w-8 h-8" />
                </div>
                <div className="p-6 flex items-center flex-col justify-center gap-8 h-full max-h-96 w-full max-w-96 my-auto">
                    <img src={verifiedIcon} alt="verifiedIcon" />
                    <p className="font-semibold text-2xl text-primary-500 uppercase">
                        Yay!!
                    </p>
                    <p className="text-xl font-semibold text-grey-500 text-center">
                        You have sucessfully shared your review
                    </p>
                    <br />
                </div>
            </div>
        </div>
    );
};
